import { FunctionComponent } from "react";

import {ACard, Skeleton, ProgressCard} from "@atiautomacao/ati-ui-library";
import {Box, Grid, useMediaQuery, useTheme} from "@mui/material";
import { RootState } from "../../../../Config/Store";
import { useAppSelector } from "../../../../Config/Hooks";
import {Container} from "../../../../Shared/Components/AlarmTotalizers/AlarmTotalizers.theme";

const GenerationPlant: FunctionComponent = () => {

    const totalizers = useAppSelector((state: RootState) => state.totalizerPlant.totalizers);
    const isLoading = useAppSelector((state : RootState) => state.totalizerPlant.isLoading);

    const formattedEnergyDaily = totalizers?.dailyGeneratedEnergy?.toFixed(2) ?? "-";
    const formattedExpectedEnergyDaily = totalizers?.dailyExpectedGeneratedEnergy?.toFixed(2) ?? "-";
    const formattedEnergyMonthly = totalizers?.monthlyGeneratedEnergy?.toFixed(2) ?? "-";
    const formattedExpectedEnergyMonthly = totalizers?.monthlyExpectedGeneratedEnergy?.toFixed(2) ?? "-";
    const formattedEnergyYearly = totalizers?.yearlyGeneratedEnergy?.toFixed(2) ?? "-";
    const formattedExpectedEnergyYearly = totalizers?.yearlyExpectedGeneratedEnergy?.toFixed(2) ?? "-";
    const formattedTotalEnergy = totalizers?.totalGeneratedEnergy?.toFixed(2) ?? "-";
    const formattedTotalExpectedEnergy = totalizers?.totalExpectedGeneratedEnergy?.toFixed(2) ?? "-";

    const theme = useTheme();
    const isLg = useMediaQuery(theme.breakpoints.only('lg'));

    if (isLoading) {
        return (
            <Box style={{paddingBottom: 10}}>
                <ACard
                    title={"Energia Gerada"}
                >
                    <Container data-testid="loading-skeleton">
                        <Grid container spacing={2} columnSpacing={-2}>
                                {Array.from({ length: 4 }).map((_, index) => (
                                     <Grid key={index.valueOf()} item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={12} sm={3} md={3} lg={12} xl={12}>
                                        <Skeleton key={index.valueOf()} animation="wave" height={100} variant="rectangular" width={220} />
                                    </Grid>
                                ))}
                            </Grid>
                    </Container>
                </ACard>
            </Box>
        );
    }

    return (
        <Box style={{paddingBottom: 10}}>
            <ACard
                title={"Energia Gerada"}
                // headerActions={
                //     <FloatMenuButton
                //         icon={<MoreVertIcon/>}
                //         tooltip={"Floating Menu"}
                //     >
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faGear} fontSize={20}/>}
                //             text="Settings"
                //             disable={true}
                //             link={"/"}
                //         />
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faExpand} fontSize={20}/>}
                //             text="Expand"
                //             disable={true}
                //         />
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faPrint} fontSize={20}/>}
                //             text="Print"
                //             disable={true}
                //         />
                //     </FloatMenuButton>
                // }
            >
                <Grid container spacing={2} columnSpacing={-2}>
                    <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={12} sm={3} md={3} lg={12} xl={12}>
                        <ProgressCard
                            value={formattedEnergyDaily} 
                            maxValue={formattedExpectedEnergyDaily}
                            fontSize={16}
                            valueFontSize={15}
                            showProgressBar={true}
                            label="Diária"
                            unit={"MWh"}
                            background="#F9F9F9"
                            textColor="#000000"
                            width={220}
                            boldLabel={true}
                            stackedLayout={isLg}
                            intervals={{min: {value: 0, color:"#a4f657"}, mid: {value: 0, color:"#a4f657"}, max: {value: 0, color:"#a4f657"}}}
                        />
                    </Grid>
                    <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={12} sm={3} md={3} lg={12} xl={12}>
                        <ProgressCard
                            value={formattedEnergyMonthly}
                            maxValue={formattedExpectedEnergyMonthly}
                            fontSize={16}
                            valueFontSize={15}
                            showProgressBar={true}
                            label="Mensal"
                            unit={"MWh"}
                            background="#F9F9F9"
                            textColor="#000000"
                            width={220}
                            boldLabel={true}
                            stackedLayout={isLg}
                            intervals={{min: {value: 0, color:"#a4f657"}, mid: {value: 0, color:"#a4f657"}, max: {value: 0, color:"#a4f657"}}}
                        />
                    </Grid>
                    <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={12} sm={3} md={3} lg={12} xl={12}>
                        <ProgressCard
                            value={formattedEnergyYearly}
                            maxValue={formattedExpectedEnergyYearly}
                            fontSize={16}
                            valueFontSize={15}
                            unit={"MWh"}
                            showProgressBar={true}
                            label="Anual"
                            background="#f9f9f9"
                            textColor="#000000"
                            width={220}
                            boldLabel={true}
                            stackedLayout={isLg}
                            intervals={{min: {value: 0, color:"#a4f657"}, mid: {value: 0, color:"#a4f657"}, max: {value: 0, color:"#a4f657"}}}
                        />
                    </Grid>
                    <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={12} sm={3} md={3} lg={12} xl={12}>
                        <ProgressCard
                            value={formattedTotalEnergy}
                            maxValue={formattedTotalExpectedEnergy}
                            fontSize={16}
                            valueFontSize={15}
                            showProgressBar={true}
                            unit={"MWh"}
                            label="Total"
                            background="#F9F9F9"
                            textColor="#000000"
                            width={220}
                            boldLabel={true}
                            stackedLayout={isLg}
                            intervals={{min: {value: 0, color:"#a4f657"}, mid: {value: 0, color:"#a4f657"}, max: {value: 0, color:"#a4f657"}}}
                        />
                    </Grid>
                </Grid>
            </ACard>
        </Box>
    )

}

export default GenerationPlant;