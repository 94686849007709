import {ADataGrid, ADataGridFilter, PagedSearchParams} from "@atiautomacao/ati-ui-library";
import React, {useCallback, useEffect, useState} from "react";
import {extractFiltersFromColumns} from "../../../../Utils/DataUitils";
import {
    FindAllTeleObjectsByPowerStationIdReactQuery,
    FindAllTeleObjectsConfigsByEquipmentId
} from "../PowerStationReactQuery";
import IconButton from "@mui/material/IconButton";
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FilledTextFieldProps,
    OutlinedTextFieldProps,
    StandardTextFieldProps,
    TextField,
    TextFieldVariants,
    Tooltip
} from "@mui/material";
import {Delete, Edit} from "@mui/icons-material";
import {FindAllEquipmentsByPowerStationIdReactQuery} from "../../Equipment/EquipmentReactQuery";
import {
    Equipment,
    TeleObjectConfig
} from "../../NotificationGroup/NotificationGroupFormPage";
import Box from "@mui/material/Box";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ConfirmDialog from "../../../../Shared/Components/ConfirmDialog";
import axios from "axios";
import {useSnackbar} from "notistack";
import CancelOutlined from "@mui/icons-material/CancelOutlined";
import {JSX} from "react/jsx-runtime";
import {initColumnsTeleObjectGrid} from './ColumnsGridData'


interface Props {
    powerStationId: number | null
    powerStationName: string | null
    onSaveTeleObject?: any;
}

export interface RowTeleObjectData {
    teleObjectId?: number | null;
    teleObjectConfigId?: number;
    equipmentEts?: number;
    equipmentName?: string;
    teleObjectConfigPoint?: number;
    teleObjectConfigName?: string;
    equipmentClassName?: string;
    powerStationName?: string;
    equipmentId?: number;
    teleObjectSpecificationTypeId?: number;
    actionsConfig?: any
    index?: number | null;
}


export const PowerStationFormEditTeleObjectPage = ({powerStationId, powerStationName, onSaveTeleObject}: Props) => {
    const {enqueueSnackbar} = useSnackbar();
    const [rows, setRows] = useState<Array<RowTeleObjectData>>([]);
    const [totalOfRecords, setTotalOfRecords] = useState<number>(0);
    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(initColumnsTeleObjectGrid), 0, 100));
    const [selectedEquipmentId, setSelectedEquipmentId] = useState<number | undefined>(undefined);
    const {data, isSuccess} = FindAllTeleObjectsByPowerStationIdReactQuery(powerStationId, pagedSearchParams);
    const {data: equipmentsData} = FindAllEquipmentsByPowerStationIdReactQuery(powerStationId);
    const {data: teleObjectsConfigData} = FindAllTeleObjectsConfigsByEquipmentId(selectedEquipmentId);
    const [open, setOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false); // Adiciona estado para verificar se está editando ou criando
    const [selectedTeleObject, setSelectedTeleObject] = useState<RowTeleObjectData | null>(null);
    const [nextIndex, setNextIndex] = useState(0);
    const [selectedToDelete, setSelectedToDelete] = useState<any>(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [tempRow, setTempRow] = useState<RowTeleObjectData | null>(null);
    const [checkedEquipments, setCheckedEquipments] = useState<Equipment[]>([]);
    const [checkedTeleObjectsConfig, setCheckedTeleObjectsConfig] = useState<TeleObjectConfig[]>([]);
    const [checkedEquipmentEts, setCheckedEquipmentEts] = useState<Equipment[]>([]);
    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        let newPageParams: PagedSearchParams = new PagedSearchParams(dataGridFilters, page, pageSize);
        setPagedSearchParams(
            newPageParams
        );
    }, []);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);


    const isDisabledEdition = () => {
        const isComplete = selectedTeleObject?.equipmentEts && selectedTeleObject?.equipmentName && selectedTeleObject?.teleObjectConfigName;
        setIsButtonDisabled(!isComplete); // Define o estado como true se não estiver completo, ou false se estiver completo
    };

    useEffect(() => {
        isDisabledEdition(); // Chama a função sempre que selectedTeleObject mudar
    }, [selectedTeleObject]);

// Função para verificar se todos os campos obrigatórios foram preenchidos
    const checkIfFieldsAreComplete = () => {
        const isComplete =
            !!selectedTeleObject?.equipmentEts &&
            !!selectedTeleObject?.equipmentName &&
            !!selectedTeleObject?.teleObjectConfigName;

        console.log("isComplete: ", isComplete)
        setIsButtonDisabled(!isComplete);
    };


    useEffect(() => {
        if (equipmentsData?.data && Array.isArray(equipmentsData.data)) {
            const initializedEquipments = equipmentsData?.data.map((equipment: Equipment) => ({
                id: equipment.id,
                name: equipment.name,
                etsStatus: equipment.etsStatus,
                ets: equipment?.ets ?? null,
                equipmentType: equipment.equipmentType,
                equipmentClass: equipment.equipmentClass,
                powerStation: equipment.powerStation,
                skid: equipment.skid,
                image: equipment.image,
                isHidden: equipment.isHidden,
                selected: false,
            }));
            setCheckedEquipments(initializedEquipments);
        } else {
            setCheckedEquipments([]);
        }
    }, [equipmentsData]);

    useEffect(() => {
        if (equipmentsData?.data && Array.isArray(equipmentsData.data)) {
            const initializedEquipmentsEts = equipmentsData?.data.map((equipment: Equipment) => ({
                ...equipment,
                selected: false,
            }));
            setCheckedEquipmentEts(initializedEquipmentsEts);
        } else {
            setCheckedEquipmentEts([]);
        }
    }, [equipmentsData]);


    useEffect(() => {
        if (teleObjectsConfigData && Array.isArray(teleObjectsConfigData)) {
            const initializedTeleObjectsConfig = teleObjectsConfigData?.map((teleObjectConfig: TeleObjectConfig) => {
                return {
                    ...teleObjectConfig,
                    name: teleObjectConfig.name,
                    selected: false
                }
            });
            setCheckedTeleObjectsConfig(initializedTeleObjectsConfig);
        }
    }, [teleObjectsConfigData]);


    const handleChangeEquipmentsEtsCheckbox = (ets: number | undefined, index: number) => {
        let updatedEquipments = checkedEquipmentEts.map((equipment, i) => ({
            ...equipment,
            selected: i === index // Marca apenas o item clicado, desmarcando os demais
        }));

        let uptatedSelected = {
            ...selectedTeleObject,
            equipmentEts: ets
        }
        setSelectedTeleObject(uptatedSelected);
        setCheckedEquipmentEts(updatedEquipments);

    }



    const handleChangeEquipmentsCheckbox = (equipmentName: string, index: number) => {
        let updatedEquipments = checkedEquipments.map((equipment, i) => ({
            ...equipment,
            selected: i === index
        }));

        let uptatedEquipmentId = checkedEquipments[index].id;

        let uptatedSelected = {
            ...selectedTeleObject,
            equipmentId: uptatedEquipmentId,
            equipmentName: equipmentName
        }
        setSelectedTeleObject(uptatedSelected);
        setSelectedEquipmentId(checkedEquipments[index].id);
        setCheckedEquipments(updatedEquipments);
    }

    const handleChangeTeleObjectsConfigCheckbox = (teleObjectConfigName: string, index: number) => {
        let updatedTeleObjects = checkedTeleObjectsConfig.map((teleObject, i) => ({
            ...teleObject,
            selected: i === index
        }));

        let uptadedTeleObjectPoint = checkedTeleObjectsConfig[index].point;
        let uptatedEquipmentClassName = checkedTeleObjectsConfig[index].equipmentClass.name;
        let uptatedTeleObjectSpecificationTypeId = checkedTeleObjectsConfig[index]?.teleObjectSpecificationType?.id;
        let uptatedTeleObjectConfigId = checkedTeleObjectsConfig[index].id;

        let uptadedSelected = {
            ...selectedTeleObject,
            teleObjectConfigId: uptatedTeleObjectConfigId,
            teleObjectConfigName: teleObjectConfigName,
            teleObjectConfigPoint: uptadedTeleObjectPoint,
            equipmentClassName: uptatedEquipmentClassName,
            teleObjectSpecificationTypeId: uptatedTeleObjectSpecificationTypeId
        }
        setSelectedTeleObject(uptadedSelected);

        setCheckedTeleObjectsConfig(updatedTeleObjects);
    }


    useEffect(() => {
        if (isSuccess) {
            setTotalOfRecords(data?.totalOfRecords);
            if (data?.data) {
                let rowsData = data.data.map((item: RowTeleObjectData) => {
                    return {
                        teleObjectId: item?.teleObjectId,
                        equipmentEts: item?.equipmentEts,
                        equipmentId: item?.equipmentId,
                        teleObjectConfigId: item?.teleObjectConfigId,
                        teleObjectSpecificationTypeId: item?.teleObjectSpecificationTypeId,
                        equipmentName: item?.equipmentName,
                        teleObjectConfigPoint: item?.teleObjectConfigPoint,
                        teleObjectConfigName: item?.teleObjectConfigName,
                        equipmentClassName: item?.equipmentClassName,
                        powerStationName: item?.powerStationName,
                        actionsConfig: [
                            {actionName: "edit", disabled: false},
                            {actionName: "delete", disabled: false},
                        ],
                    };
                });
                setRows(rowsData)
            }
        }
    }, [data?.data]);

    useEffect(() => {
        if (!isEditing) {
            setTempRow({
                teleObjectId: null,
                teleObjectConfigId: selectedTeleObject?.teleObjectConfigId,
                equipmentEts: selectedTeleObject?.equipmentEts,
                equipmentId: selectedTeleObject?.equipmentId,
                equipmentName: selectedTeleObject?.equipmentName,
                teleObjectConfigPoint: selectedTeleObject?.teleObjectConfigPoint,
                teleObjectSpecificationTypeId: selectedTeleObject?.teleObjectSpecificationTypeId,
                teleObjectConfigName: selectedTeleObject?.teleObjectConfigName,
                equipmentClassName: selectedTeleObject?.equipmentClassName,
                index: nextIndex,
                actionsConfig: [
                    {actionName: "edit", disabled: false},
                    {actionName: "delete", disabled: false},
                ],
            });
            // Incrementa o próximo índice
            setNextIndex(prevIndex => prevIndex + 1);
        }
    }, [selectedTeleObject]);

    const handleAddItem = (tempRow: any) => {
        if (tempRow) {
            setRows(prevRows => {
                const updatedRows = [...prevRows, tempRow];
                handleSave(updatedRows);
                return updatedRows; // Retorna o novo estado atualizado
            });
            setTempRow(null);
            setOpen(false);
        }
    }


    const handleEditItem = (data: any) => {
        if (data.teleObjectId != null) {
            const newRows = rows.filter(row => row.teleObjectId !== data.teleObjectId)
            const newItem = [{
                teleObjectId: data.teleObjectId ? data.teleObjectId : null,
                equipmentId: data?.equipmentId,
                teleObjectConfigId: data?.teleObjectConfigId,
                teleObjectSpecificationTypeId: data?.teleObjectSpecificationTypeId,
                equipmentEts: data?.equipmentEts,
                equipmentName: data?.equipmentName,
                teleObjectConfigPoint: data?.teleObjectConfigPoint,
                teleObjectConfigName: data?.teleObjectConfigName,
                equipmentClassName: data?.equipmentClassName,
                powerStationName: data?.powerStationName,
                actionsConfig: [
                    {actionName: "edit", disabled: false},
                    {actionName: "delete", disabled: false},
                ],
                index: null
            }]
            setRows(prevRows => {
                const updatedRows = [...newRows, ...newItem];
                handleSave(updatedRows); // Chama handleSave após atualizar rows
                return updatedRows;
            });
        } else {
            const newRows = rows.filter(row => row.index !== data.index)
            const newItem = [{
                teleObjectId: null,
                equipmentEts: data.equipmentEts,
                equipmentName: data.equipmentName,
                equipmentId: data?.equipmentId,
                teleObjectConfigId: data?.teleObjectConfigId,
                teleObjectSpecificationTypeId: data?.teleObjectSpecificationTypeId,
                teleObjectConfigPoint: data.teleObjectConfigPoint,
                teleObjectConfigName: data?.teleObjectConfigName,
                equipmentClassName: data?.equipmentClassName,
                powerStationName: data?.powerStationName,
                actionsConfig: [
                    {actionName: "edit", disabled: false},
                    {actionName: "delete", disabled: false},
                ],
                index: data.index
            }]
            setRows(prevRows => {
                const updatedRows = [...newRows, ...newItem];
                handleSave(updatedRows); // Chama handleSave após atualizar rows
                return updatedRows;
            });
        }
        setIsEditing(false)
        setOpen(false)
    }

    const handleSave = (updatedRows: any) => {

        const teleObjectsDataList = updatedRows.map((row: RowTeleObjectData) => ({
            id: row.teleObjectId ?? null,
            status: "10",
            equipment: {
                id: row.equipmentId ?? null,
                ets: row.equipmentEts ?? null
            },
            teleObjectConfig: {
                id: row.teleObjectConfigId ?? null
            },
            teleObjectPosition: row.teleObjectConfigPoint ?? null,
            teleObjectSpecificationType: {
                id: row.teleObjectSpecificationTypeId ?? null
            }
        }));

        onSaveTeleObject(teleObjectsDataList);

    }


    function handleOnEditScreen(value: any) {
        setIsEditing(true);
        setSelectedTeleObject(value);
        setOpen(true);
        setCheckedEquipments(checkedEquipments.map(equipment => ({...equipment, selected: false})));
        setCheckedEquipmentEts(checkedEquipmentEts.map(equipment => ({...equipment, selected: false})));
        setCheckedTeleObjectsConfig(checkedTeleObjectsConfig.map(teleObjectConfig => ({
            ...teleObjectConfig,
            selected: false
        })));
        setSelectedEquipmentId(value.equipmentId);
    }

    const handleClose = () => {
        setOpen(false);
        setIsEditing(false)
        setSelectedTeleObject(null);
        setCheckedEquipments(checkedEquipments.map(equipment => ({...equipment, selected: false})));
        setCheckedEquipmentEts(checkedEquipmentEts.map(equipment => ({...equipment, selected: false})));
        setCheckedTeleObjectsConfig(checkedTeleObjectsConfig.map(teleObjectConfig => ({
            ...teleObjectConfig,
            selected: false
        })));
    };

    function handleAddNew(): void {
        setIsEditing(false);
        setSelectedTeleObject(null);
        setOpen(true);
        setCheckedEquipments(checkedEquipments.map(equipment => ({...equipment, selected: false})));
        setCheckedEquipmentEts(checkedEquipmentEts.map(equipment => ({...equipment, selected: false})));
        setCheckedTeleObjectsConfig([]);
    }

    console.log("selectedTeleObject", selectedTeleObject)


    const handleDialogToggle = () => {
        setOpenDialog(!openDialog);
    }

    const handleDialogClose = () => {
        handleDialogToggle();
    }

    const handleOnDelete = useCallback((data: any) => {
        setSelectedToDelete(data);
        setOpenDialog(true);
    }, []);


    const handleDialogConfirm = async () => {
        if (selectedToDelete?.teleObjectId) {
            try {
                await deleteTeleObject(selectedToDelete.teleObjectId);
                setRows(prevRows => prevRows.filter(row => row.teleObjectId !== selectedToDelete.teleObjectId));

                enqueueSnackbar("TeleObjeto excluído com sucesso.", {variant: 'success'});
            } catch (error) {
                enqueueSnackbar("Erro ao excluir TeleObjeto. Tente novamente.", {variant: 'error'});
            }
        } else {
            setRows(prevRows => prevRows.filter(row => row.index !== selectedToDelete.index));
        }
        setSelectedToDelete(null);
        handleDialogToggle();
    }

    const deleteTeleObject = async (teleObjectId: number) => {
        try {
            const response = await axios.delete(`/api/teleobject/${teleObjectId}`);
            return response.data;
        } catch (error) {
            console.log("Erro ao excluir o t.o: ", error)
            throw error;
        }
    };

    const checkedEquipmentsSorted = checkedEquipments.sort((a, b) => a.name.localeCompare(b.name));
    const checkedTeleObjectsConfigSorted = checkedTeleObjectsConfig.sort((a, b) => a.name.localeCompare(b.name));


    const selectedEquipment = checkedEquipmentsSorted.find(
        (equipment) => equipment.name === selectedTeleObject?.equipmentName
    );
    console.log("Equipamento selecionado:", selectedEquipment);

    return (
        <>
            {
                rows && rows.length > 0 ? (
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                        {/* Botão adicionado à esquerda acima do grid */}
                        <Button
                            variant="contained"
                            endIcon={<AddCircleIcon/>}
                            color="primary"
                            onClick={() => handleAddNew()} // Função que será chamada ao clicar no botão
                        >
                            Inserir
                        </Button>
                    </Box>
                ) : (
                    <br/>
                )
            }

            <ADataGrid
                hideSelection={true}
                columns={initColumnsTeleObjectGrid}
                rows={rows}
                page={pagedSearchParams.page}
                rowsPerPage={pagedSearchParams.size}
                loading={false}
                totalOfRecords={totalOfRecords}
                onFiltersChange={handleOnFiltersChange}
                disableActionsHeaderIcons={true}
                actions={
                    <>
                        <IconButton name="edit" size={"small"} aria-label="Edit"
                                    onClick={(value) => handleOnEditScreen(value)}>
                            <Tooltip title="Editar">
                                <Edit/>
                            </Tooltip>
                        </IconButton>
                        <IconButton name="delete" size={"small"} aria-label="Delete"
                                    onClick={(value) => handleOnDelete(value)}>
                            <Tooltip title="Excluir">
                                <Delete/>
                            </Tooltip>
                        </IconButton>
                    </>
                }
            />
            <ConfirmDialog
                title={'Deletar'}
                description={'Deseja deletar mesmo esse Tele Objeto?'}
                open={openDialog}
                handleConfirm={handleDialogConfirm}
                handleClose={handleDialogClose}
            />
            {/* Modal de Edição */}
            <Dialog open={open}
                    onClose={handleClose}
            >
                <DialogTitle>{isEditing ? selectedTeleObject?.teleObjectConfigName : "Criar Novo Tele Objeto"}</DialogTitle>
                <DialogContent sx={{minWidth: '400px' }}>
                    {/* Campo "Usina" desabilitado */}
                    <TextField
                        label="Usina"
                        value={selectedTeleObject?.powerStationName ?? powerStationName}
                        fullWidth
                        margin="normal"
                        disabled
                        sx={{'.MuiOutlinedInput-notchedOutline': { border: 0 }}}
                    />

                    {/* Select "ETS" */}
                    <Autocomplete
                        fullWidth
                        sx={{'.MuiOutlinedInput-notchedOutline': { border: 0 }}}
                        id="autocomplete-ets"
                        options={checkedEquipmentEts} // Array com os itens de ETS
                        getOptionLabel={(option) =>String(option.ets ?? '')} // Definindo qual campo exibir como label
                        value={checkedEquipmentEts.find((item) => item.ets === selectedTeleObject?.equipmentEts) || null} // Definindo o valor selecionado
                        onChange={(event, newValue) => {
                            if (newValue) {
                                handleChangeEquipmentsEtsCheckbox(newValue.ets, checkedEquipmentEts.indexOf(newValue)); // Chamando a função de mudança
                                setSelectedTeleObject(prev => ({ ...prev, equipmentEts: newValue.ets }));
                                checkIfFieldsAreComplete();
                            }
                        }}
                        renderInput={(params: JSX.IntrinsicAttributes & { variant?: TextFieldVariants ; } & Omit<OutlinedTextFieldProps | FilledTextFieldProps | StandardTextFieldProps, "variant">) => (
                            <TextField
                                {...params}
                                label="ETS"
                                variant="outlined"
                                placeholder="Selecione o ETS"
                            />
                        )}
                    />

                    <Autocomplete
                        fullWidth
                        id="autocomplete-equipment"
                        sx={{marginTop: 2, '.MuiOutlinedInput-notchedOutline': { border: 0 }}}
                        options={checkedEquipmentsSorted} // Passa a lista de equipamentos como opções
                        getOptionLabel={(option) => option.name || ""} // Define o campo "name" como o rótulo de exibição
                        value={
                            selectedTeleObject
                                ? checkedEquipmentsSorted.find(equipment =>
                                equipment.name.startsWith(selectedTeleObject.equipmentName ?? "")
                            ) || null
                                : null // Definindo o campo para vazio quando `selectedTeleObject` é `null`
                        }
                        onChange={(event, newValue) => {
                            // Atualiza o valor selecionado quando um item é escolhido
                            if (newValue) {
                                handleChangeEquipmentsCheckbox(newValue.name, checkedEquipmentsSorted.indexOf(newValue));
                                setSelectedTeleObject(prev => ({ ...prev, equipmentName: newValue.name }));
                                checkIfFieldsAreComplete();
                            }
                        }}
                        renderInput={(params: JSX.IntrinsicAttributes & { variant?: TextFieldVariants ; } & Omit<OutlinedTextFieldProps | FilledTextFieldProps | StandardTextFieldProps, "variant">) => (
                            <TextField
                                {...params}
                                label="Equipamento"
                                variant="outlined"
                                placeholder="Selecione o Equipamento"
                            />
                        )}
                    />

                    <Autocomplete
                        fullWidth
                        sx={{marginTop: 2, '.MuiOutlinedInput-notchedOutline': { border: 0 }}}
                        id="autocomplete-teleObjectConfig"
                        options={checkedTeleObjectsConfigSorted}
                        getOptionLabel={(option) =>String(option.name ?? '')}
                        value={checkedTeleObjectsConfigSorted.find((item) => item.name === selectedTeleObject?.teleObjectConfigName) || null} // Definindo o valor selecionado
                        onChange={(event, newValue) => {
                            if (newValue) {
                                handleChangeTeleObjectsConfigCheckbox(newValue.name, checkedTeleObjectsConfig.indexOf(newValue));
                                setSelectedTeleObject(prev => ({ ...prev, teleObjectConfigName: newValue.name }));
                                checkIfFieldsAreComplete();
                            }
                        }}
                        renderInput={(params: JSX.IntrinsicAttributes & { variant?: TextFieldVariants ; } & Omit<OutlinedTextFieldProps | FilledTextFieldProps | StandardTextFieldProps, "variant">) => (
                            <TextField
                                {...params}
                                label="Descrição do TO"
                                variant="outlined"
                                placeholder="Selecione o TO"
                            />
                        )}
                    />

                    <TextField
                        sx={{'.MuiOutlinedInput-notchedOutline': { border: 0 }}}
                        label="Endereço do TO"
                        value={selectedTeleObject?.teleObjectConfigPoint ?? ''}
                        fullWidth
                        margin="normal"
                        disabled
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}
                            color="primary"
                            variant="outlined"
                            size={"small"}
                            endIcon={<CancelOutlined />}
                    >
                        Cancelar
                    </Button>
                    <Button onClick={() => {
                        isEditing ? handleEditItem(selectedTeleObject) : handleAddItem(tempRow)
                    }}
                            color="primary"
                            variant="contained"
                            size={"small"}
                            endIcon={ isEditing ? <Edit/> : <AddCircleIcon />}
                            disabled={isButtonDisabled} // Botão desabilitado até que todos os campos sejam preenchidos
                    >

                        {isEditing ? "Editar" : "Inserir"}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}