import {useQuery} from "react-query";
import axios from "axios";
import {useSnackbar} from "notistack";
import {LocalDateTimeFormatISO} from "../../../../Utils/DateFormatPatternUtils";

const apiUrl = "api/generation-energy/expected-data";
const apiPowerStationInfoUrl = "api/power-station/description";

const fetchExpectedDataByPowerStation = async (dateTime: any, powerStationId: number) => {
    try {
        const response = await axios.get(`${apiUrl}`,
            {
                params: {
                    dateTime: LocalDateTimeFormatISO(dateTime),
                    powerStationId: powerStationId,
                }
            })

        return {
            data: response.data.data,
            status: response.status
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        throw new Error("Error fetching data");
    }
};

const fetchPowerStationInformation = async (powerStationId: number) => {
    try {
        const response = await axios.get(`${apiPowerStationInfoUrl}/${powerStationId}`);

        return {
            data: response.data.data,
            status: response.status
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        throw new Error("Error fetching data");
    }
};

export const FindExpectedDataReactQuery = (dateTime:any, powerStationId:number) => {
    const { enqueueSnackbar } = useSnackbar();
    return useQuery({
        queryKey: ['expected-data',dateTime, powerStationId],
        queryFn: () => fetchExpectedDataByPowerStation(dateTime, powerStationId),
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao carregar dados de geração de energia: ${error.message}`, { variant: "error" });
        }
    })
};


export const FindPowerStationInformationReactQuery = (powerStationId:number) => {
    const { enqueueSnackbar } = useSnackbar();
    return useQuery({
        queryKey: ['power-station-information', powerStationId],
        queryFn: () => fetchPowerStationInformation(powerStationId),
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao carregar dados de geração de energia: ${error.message}`, { variant: "error" });
        },
        enabled: powerStationId !== undefined
    })
};