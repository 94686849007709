import {FunctionComponent, useEffect, useState} from "react";
import {ACard, Skeleton, ProgressCard} from "@atiautomacao/ati-ui-library";
import {FindExpectedDataReactQuery, FindPowerStationInformationReactQuery} from "./PowerStationInformationReactQuery";
import {
    Box,
    Grid,
    Stack,
    TextField
} from "@mui/material";
import {endOfDay, isWithinInterval, startOfDay} from "date-fns";
import {LocalDateTimeFormatISO} from "../../../../Utils/DateFormatPatternUtils";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {truncateNumber} from "../../../../Utils/NumberUtil";
import { useAppSelector } from "../../../../Config/Hooks";
import { useSnackbar } from "notistack";
import {Container} from "../../../../Shared/Components/AlarmTotalizers/AlarmTotalizers.theme";

interface RowInfo {
    name: string;
    value: any;
    unit?: string;
}

interface RowExpectedData {
    name: string;
    value: string | number;
    unit?: string;
    flexWrap?:boolean;
}

const PowerStationInformation: FunctionComponent<{powerStation: any}> = ({ powerStation }) => {

    const [dateTime, setDateTime] = useState<Date>(startOfDay(new Date()))
    const {data:expectedData, isLoading: expectedDataIsLoading} = FindExpectedDataReactQuery(dateTime, powerStation.id);
    const {data:dataInfo, isLoading: dataInfoIsLoading} = FindPowerStationInformationReactQuery(powerStation.id);
    const [rowInfo, setRowInfo] = useState<RowInfo[]>([])
    const [rowExpectedData, setRowExpectedData] = useState<RowExpectedData[]>([])
    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);
    const { enqueueSnackbar } = useSnackbar();
    
    const hasNoContent = (response:any) => {
        return response?.status === 204
    }

    const isOk = (response:any) => {
        return response?.status === 200
    }

    const dataIsValid = (data:any) => {
        return isOk(data) || hasNoContent(data)
    }



    const getDataInfo = (dataInfo: any) => {
        if(!dataIsValid(dataInfo)){
            return [   
                { name: 'Capacidade Instalada', value: '-', unit: 'MWp' },
                { name: 'Quantidade de Skid', value: '-'},
                { name: 'Início de operação', value: '-'},
                { name: 'Capacidade máxima AC', value: '-' },
            ]
        }

        let date
        if(dataInfo?.data.operationStartedAt !== undefined){
            date = new Date(dataInfo?.data.operationStartedAt)
        }
        const dataInfoList: RowInfo[] = [   
            { name: 'Capacidade Instalada', value: !hasNoContent(dataInfo) && dataInfo.data.powerCapacityNominal ? dataInfo.data.powerCapacityNominal : '-', unit: 'kWp' },
            { name: 'Capacidade máxima AC', value: !hasNoContent(dataInfo) && dataInfo.data.maxPower ? dataInfo.data.maxPower : '-', unit: 'kWp' },
            { name: 'Quantidade de Skid', value: !hasNoContent(dataInfo) && dataInfo.data.numberOfSkid ? dataInfo.data.numberOfSkid : '-'},
            { name: 'Início de operação', value: !hasNoContent(dataInfo) && date !== undefined ? date.toLocaleDateString('pt-BR', { month: '2-digit', year: 'numeric' }) : '-'},
            
        ];
        return dataInfoList
    }

    const getExpectedData = (expectedData: any) => {
        if(!dataIsValid(expectedData)){return [
            { name: 'Energia Esperada', value: '-', unit: 'MWh', flexWrap: true},
            { name: 'Irradiação Esperada', value: '-', unit: 'Wh/m²', flexWrap: true },
            { name: 'Prod. Esperada', value: '-', unit: 'kWh/kWp', flexWrap: true },
            { name: 'PR Esperado', value: '-', unit: '%', flexWrap: false }
        ]}

        const expectedDataList: RowExpectedData[] = [
            { name: 'Energia Esperada', value: !hasNoContent(expectedData) && expectedData.data.expectedEnergy ? truncateNumber(expectedData.data.expectedEnergy,2) : '-', unit: 'MWh', flexWrap: true},
            { name: 'Irradiação Esperada', value: !hasNoContent(expectedData) && expectedData.data.expectedIrradiance ? truncateNumber(expectedData.data.expectedIrradiance,2) : '-', unit: 'Wh/m²', flexWrap: true },
            { name: 'Prod. Esperada', value: !hasNoContent(expectedData) && expectedData.data.expectedYield ? truncateNumber(expectedData.data.expectedYield,2): '-', unit: 'kWh/kWp', flexWrap: true },
            { name: 'PR Esperado', value: !hasNoContent(expectedData) && expectedData.data.expectedPerformanceRatio ? truncateNumber(expectedData.data.expectedPerformanceRatio * 100,2) : '-', unit: '%', flexWrap: false }
        ];
        return expectedDataList

    }

    useEffect(() => {
        setRowInfo(getDataInfo(null))
        setRowExpectedData(getExpectedData(null))
    }, []);

    useEffect(() => {
        if(dataIsValid(dataInfo)){
            const dataInfoList: RowInfo[] = getDataInfo(dataInfo);
            setRowInfo(dataInfoList)
            if(hasNoContent(dataInfo)){
                enqueueSnackbar(`As informações da usina não foram encontradas! `, { variant: "warning", autoHideDuration: 1000 });
            }
        }else{
            setRowInfo(getDataInfo(null))
        }
    }, [dataInfo]);

    useEffect(() => {
        if(dataIsValid(expectedData)){
            const expectedDataList: RowExpectedData[] = getExpectedData(expectedData);
            setRowExpectedData(expectedDataList)
            if(hasNoContent(expectedData)){
                enqueueSnackbar(`Os dados esperados da usina não foram encontrados! `, { variant: "warning", autoHideDuration: 1000 });
            }
        }else{
            setRowExpectedData(getExpectedData(null))
        }
    }, [expectedData]);


    if (dataInfoIsLoading || expectedDataIsLoading) {
        return (
            <Box style={{paddingBottom: 10}}>
                <ACard
                    title={"Usina"}
                >
                    <Container data-testid="loading-skeleton">
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, ml: {xl: 1, lg: openSubMenu ? 4 : 10}, mt: {xl: 2, lg: 0}, mr: openSubMenu ? 3 : 0 }}>
                            <Grid container spacing={{ xs: 1, md: 1 }} columnGap={0} columns={{ xs: 3, sm: 12, md: 12, xl: 12, lg: 12 }}>
                                {Array.from({ length: 8 }).map((_, index) => (
                                    <Grid item xs={2} sm={3} md={openSubMenu ? 6 : 3} xl={3} lg={3} key={index.valueOf()}>
                                        <Skeleton key={index.valueOf()} animation="wave" height={100} variant="rectangular" width={220} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Container>
                </ACard>
            </Box>
        );
    }


    return (
        <ACard
            title={"Usina: " + powerStation.name}
            headerControls={
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack direction={"row"} spacing={0} style={{ marginRight: 15}}>
                        <DatePicker
                            label="Selecione o dia"
                            renderInput={(params) => (
                                <TextField
                                    variant="standard"
                                    {...params}
                                    sx={{width: '150px'}}
                                />
                            )}
                            value={dateTime}
                            inputFormat={"DD/MM/YYYY"}
                            onChange={(newValue) => {
                                if (newValue && isWithinInterval(new Date(newValue), { start: new Date('1980-01-01'), end: endOfDay(new Date()) })) {
                                    setDateTime(startOfDay(new Date(LocalDateTimeFormatISO(newValue))));
                                }
                            }}
                        />
                    </Stack>
                </LocalizationProvider>
            }>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, ml: {xl: 1, lg: openSubMenu ? 4 : 10}, mt: {xl: 2, lg: 0}, mr: openSubMenu ? 3 : 0 }}>
                <Grid container spacing={{ xs: 1, md: 1 }} columnGap={0} columns={{ xs: 3, sm: 12, md: 12, xl: 12, lg: 12 }}>
                    {
                        rowInfo.map((row,index) => {
                            return (
                                <Grid item xs={2} sm={3} md={openSubMenu ? 6 : 3} lg={3} xl={3} key={index.valueOf()}>
                                    <ProgressCard

                                        value={row.value}
                                        unit={row.unit ? row.unit : ''}
                                        label={row.name}
                                        fontSize={16}
                                        valueFontSize={15}
                                        showProgressBar={false}
                                        background="#F9F9F9"
                                        textColor="#000000"
                                        width={220}
                                        boldLabel={true}
                                        flexWrap={openSubMenu}
                                    />
                                </Grid>
                            )
                        })
                    }
                    {
                        rowExpectedData.map((row,index) => {
                            return (
                                <Grid item xs={2} sm={3} md={openSubMenu ? 6 : 3} xl={3} lg={3} key={index.valueOf()}>
                                    <ProgressCard

                                        value={row.value}
                                        label={row.name}
                                        unit={row.unit ? row.unit : ''}
                                        fontSize={16}
                                        valueFontSize={15}
                                        maxWidthFont={openSubMenu ? {xs: 40, sm: 80,lg:85} : {xs: 80, sm: 100,lg:100}}
                                        showProgressBar={false}
                                        background="#F9F9F9"
                                        textColor="#000000"
                                        width={220}
                                        boldLabel={true}
                                        flexWrap={openSubMenu}
                                    />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Box>

        </ACard>)
}
export default PowerStationInformation;