import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import {RootState} from "../../../../../Config/Store";
import {useAppDispatch, useAppSelector} from "../../../../../Config/Hooks";
import {getEntity, reset, TeleObject, TeleObjectType} from "../../../Skid/EquipmentDetailsSkid/EquipmentDetailsSkidReducer";
import {Button, Grid} from "@mui/material";
import {ArrowForward} from "@mui/icons-material";
import {truncateNumber} from "../../../../../Utils/NumberUtil";
import {TeleObjectDTO, EquipmentDetailsReactQuery} from "./EquipmentDeatilsQuery";
import TeleObjectChart from "./TeleObjectChart";
import DataNotFound from "../../../../../Shared/Components/DataNotFoundMessage";
import {HeaderMenu} from "./HeaderMenu";
import {format} from "date-fns";
import {ACard, Period, Skeleton, ADataGrid, ADataGridColors} from "@atiautomacao/ati-ui-library";
import {LocalDateTimeFormatISO} from "../../../../../Utils/DateFormatPatternUtils";
import Tooltip from "@mui/material/Tooltip";
import {telecommandColumns, telemetryColumns, telesinalColumns} from "./ColumnsCustom";
import {hasPermission} from "../../../../../Shared/Auth/AuthenticationUtil";
import {AUTHORITIES} from "../../../../../Config/Constants";
import {CommandDTO, ModalForSendingTelecommand} from "../../../../../Shared/Components/ModalForSendingTelecommand";


export interface DetailsRow {
    id: number;
    selected?: boolean;
    teleObjectName: string;
    status?: string;
    telemetryStatus?: string;
    value?: number | string;
    userId?: number;
    teleObjectSpecType?: number;
    point?: number;
    etsCode?: number;
    brokerId?: number;
    actionsConfig?: any;
}

export interface TeleObjectData {
    name: string;
    data: number[],
    time: string[],
    teleObjectType: string;
    unit?: string;
    periodType?: string;
}

const initialPeriod: Period = {
    groupBy: "week",
    fromDateTime: new Date(new Date().setDate(new Date().getDate() - 3)),
    toDateTime: new Date()
};

interface TeleObjectInfo {
    id: number;
    name: string;
}

const EquipmentGrid: FunctionComponent<{ equipmentId: number }> = ({equipmentId}) => {
    const dispatch = useAppDispatch();
    const account = useAppSelector(state => state.authentication.account);
    const isAuthorizedToSendCommand = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SEND_POWER_STATION_EQUIPMENT_DETAIL_COMMAND, AUTHORITIES.SEND_SKID_EQUIPMENT_DETAIL_COMMAND
    ]);
    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);
    const loading = useAppSelector((state: RootState) => state.equipmentDetailsSkid.loading);
    const [teleObjectDTO, setTeleObjectDTO] = useState<TeleObjectDTO | null>()
    const [entity, setEntity] = useState<TeleObjectType | null>(null)
    const {data, isSuccess, isError, error} = EquipmentDetailsReactQuery(teleObjectDTO);
    const [telemetryRows, setTelemetryRows] = useState<Array<DetailsRow>>([]);
    const [telecommandRows, setTelecommandRows] = useState<Array<DetailsRow>>([]);
    const [telesinalRows, setTelesinalRows] = useState<Array<DetailsRow>>([]);
    const [teleObjectDataList, setTeleObjectDataList] = useState<TeleObjectData[]>([])
    const [telemetryIDs, setTelemetryIDs] = useState<TeleObjectInfo[]>([]);
    const [telecommandIDs, setTelecommandIDs] = useState<TeleObjectInfo[]>([]);
    const [telesinalIDs, setTelesinalIDs] = useState<TeleObjectInfo[]>([]);
    const [teleObjectIDs, setTeleObjectIDs] = useState<TeleObjectInfo[]>([]);
    const [period, setPeriod] = useState<Period>(initialPeriod);
    const [actualIndex, setActualIndex] = useState<number>(-1)
    const [generateChart, setGenerateChart] = useState<boolean>(false)
    const [loadingStates, setLoadingStates] = useState<boolean[]>([])
    const [erroStates, setErroStates] = useState<boolean[]>([])
    const [isClear, setIsClear] = useState(false);
    const [open, setOpen] = useState(false);
    const [content, setContent] = useState<JSX.Element[]>();
    const [auxData, setAuxData] = useState<TeleObjectData[]>([])
    const [selectedCommandRow, setSelectedCommandRow] = useState<DetailsRow | null>(null);
    const [commandDTO, setCommandDTO] = useState<CommandDTO>()
    const refChart = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setEntity(null)
        setTeleObjectDataList([])
        setAuxData([])
        setGenerateChart(false)
        setTelecommandIDs([])
        setTelesinalIDs([])
        setTelemetryIDs([])
        setTeleObjectDTO(null)
        setIsClear(true)
        dispatch(reset());
        if (equipmentId != -1) {
            dispatch(getEntity(equipmentId))
                .then((response) => {
                    if (response?.payload?.data?.data) {
                        setEntity(response.payload.data.data);
                    } else {
                        setEntity(null)
                    }
                }).catch((error) => {
                console.log(error)
            })
        }
    }, [equipmentId])

    const ajustDate = (dateList: string[]) => {
        return dateList.map((date) => {
            return format(new Date(date), 'yyyy-MM-dd HH:mm:ss.S')
        })

    }

    const validateValue = (value: number, unit: string) => {
        const decimalPlaces = 2;

        if (value !== null && unit) {
            return `${truncateNumber(value, decimalPlaces)} ${unit}`;
        } else if (value !== null) {
            return truncateNumber(value, decimalPlaces).toString();
        } else if (unit) {
            return '-';
        } else {
            return '-';
        }
    };

    const structureMetryRow = (entitiesValues: Array<TeleObject>) => {
        let details: Array<DetailsRow> = []
        entitiesValues.forEach(detail => {
            details.push({
                id: detail.id,
                selected: false,
                teleObjectName: detail.name,
                value: validateValue(detail.value, detail.unit),
                telemetryStatus: detail.status
            });
        });
        return details;
    }

    const structureCommandRow = (entitiesValues: Array<TeleObject>) => {
        let details: Array<DetailsRow> = []
        entitiesValues.forEach(detail => {
            details.push({
                id: detail.id,
                selected: false,
                teleObjectName: detail.name,
                userId: detail.userId,
                teleObjectSpecType: detail.teleObjectTypeId,
                point: detail.point,
                etsCode: detail.etsCode,
                brokerId: detail.brokerId,
                value: validateValue(detail.value, detail.unit),
                status: detail.status,
                actionsConfig: [
                    {actionName: "send", disabled: !isAuthorizedToSendCommand},
                ]
            });
        });
        return details;
    }
    const structureTelesinalRow = (entitiesValues: Array<TeleObject>) => {
        let details: Array<DetailsRow> = []
        entitiesValues.forEach(detail => {
            details.push({
                id: detail.id,
                selected: false,
                teleObjectName: detail.name,
                status: detail.status
            });
        });
        return details;
    }

    useEffect(() => {
        if (entity) {
            setIsClear(false)
            if (entity.telemetryList) {
                setTelemetryRows(structureMetryRow(entity.telemetryList));
            }
            if (entity.telecommandList) {
                setTelecommandRows(structureCommandRow(entity.telecommandList));
            }
            if (entity.telesinalList) {
                setTelesinalRows(structureTelesinalRow(entity.telesinalList));
            }

        }else{
            setTelecommandRows([])
            setTelesinalRows([])
            setTelemetryRows([])
        }
    }, [entity]);

    const handlePeriodChange = (newPeriod: Period) => {
        setPeriod(newPeriod);
    };
    useEffect(() => {
        setTeleObjectIDs([...telemetryIDs, ...telesinalIDs, ...telecommandIDs])
    }, [telemetryIDs, telesinalIDs, telecommandIDs]);

    const insertCharts = () => {
        if(period != null && teleObjectIDs.length > 0) {
            setLoadingStates(teleObjectIDs.map(() => true))
            setErroStates(teleObjectIDs.map(() => false))
            setGenerateChart(true)
            setActualIndex(0)
        }
    }
    useEffect(() => {
        if(generateChart && actualIndex >= 0 && actualIndex < teleObjectIDs.length){
            if(period.toDateTime != null && period.fromDateTime != null){
                setTeleObjectDTO({
                    startDateTime: LocalDateTimeFormatISO(period.fromDateTime),
                    stopDateTime: LocalDateTimeFormatISO(period.toDateTime),
                    equipmentId: equipmentId,
                    teleObjectList: [teleObjectIDs[actualIndex].id]
                })
            }
        }
        if(refChart?.current != null) {
            refChart.current.scrollIntoView({ behavior: 'smooth' });
        }
        if(actualIndex >= teleObjectIDs.length){
            setGenerateChart(false)
            setAuxData([])
            setTeleObjectDTO(null)
        }
    }, [actualIndex]);

    useEffect(() => {
        if(teleObjectDataList.length === teleObjectIDs.length){
            setActualIndex(-1)
        }
    }, [teleObjectDataList]);

    useEffect(() => {
        if(isSuccess){
            let teleData: TeleObjectData[] = []
            setActualIndex(prev => prev + 1)
            if(data?.data?.telecommandList != null && data?.data?.telecommandList.length > 0){
                data?.data.telecommandList.forEach((item) => {
                    teleData.push({
                        name: item.name,
                        data: item.value.map(value => truncateNumber(value, 2)),
                        time: ajustDate(item.time),
                        teleObjectType: 'TELECOMANDO',
                        unit: item.unit,
                        periodType: period ? period.groupBy : ""
                    })
                })
            }
            if(data?.data?.telemetryList != null && data?.data?.telemetryList.length > 0){
                data?.data.telemetryList.forEach((item) => {
                    teleData.push({
                        name: item.name,
                        data: item.value.map(value => truncateNumber(value, 2)),
                        time: ajustDate(item.time),
                        teleObjectType: 'TELEMEDIDA',
                        unit: item.unit,
                        periodType: period ? period.groupBy : ""
                    })
                })
            }
            if(data?.data?.telesinalList != null && data?.data?.telesinalList.length > 0){
                data?.data.telesinalList.forEach((item) => {
                    teleData.push({
                        name: item.name,
                        data: item.value.map(value => truncateNumber(value, 2)),
                        time: ajustDate(item.time),
                        teleObjectType: 'TELESINAL',
                        periodType: period ? period.groupBy : ""
                    })
                })
            }
            if(auxData.length <= teleObjectIDs.length){
                setAuxData([...teleData, ...auxData])
            }
            setLoadingStates(prevState => prevState.map((loading,index) => {
                if(index === actualIndex){
                    return false;
                }
                return loading
            }))

        }
    }, [data]);

    useEffect(() => {
        if(auxData.length > 0){
            setTeleObjectDataList(auxData)
        }
    }, [auxData]);
    useEffect(() => {
        if(error){
            setLoadingStates(prevState => prevState.map((loading,index) => {
                if(index === actualIndex){
                    return false;
                }
                return loading
            }))
            setErroStates(prevState => prevState.map((erro,index) => {
                if(index === actualIndex){
                    return true;
                }
                return erro
            }))
            setActualIndex(prev => prev + 1)
        }
    }, [error]);

    const handleColor = (data: any): ADataGridColors => {
        const dataGridColor:ADataGridColors = {
            color: '',
            backgroundColor: ''
        }
        if(data.telemetryStatus != null){
            if(data.telemetryStatus.toLowerCase().includes('alarmado')){
                return {
                    color: '',
                    backgroundColor: '#F85F4E'
                }
            }
        }

        if(data.status != null){
            if(data.status.toLowerCase().includes('alarmado')){
                return {
                    color: '',
                    backgroundColor: '#F85F4E'
                }
            }
        }

        return dataGridColor
    }

    const handleOnTelecommandRow = (rows:any) => {
        const telecommandInfo:TeleObjectInfo[] = rows.filter((row:any) => row.selected === true).map((row:any) => {
            return {
                id: row.id,
                name: row.name
            }
        })
        setTelecommandIDs(telecommandInfo)
    }

    useEffect(() => {
        if(selectedCommandRow != null){
            if(selectedCommandRow.userId != null &&
                selectedCommandRow.point != null &&
                selectedCommandRow.teleObjectSpecType != null &&
                selectedCommandRow.etsCode &&
                selectedCommandRow.brokerId){
                setCommandDTO({
                    id: selectedCommandRow.id,
                    teleObjectName: selectedCommandRow.teleObjectName,
                    userId: selectedCommandRow.userId,
                    point: selectedCommandRow.point,
                    teleObjectSpecType: selectedCommandRow.teleObjectSpecType,
                    etsCode: selectedCommandRow.etsCode,
                    brokerId: selectedCommandRow.brokerId
                })
            }

        }
    }, [selectedCommandRow]);

    const handleOnTelesinalRow = (rows:any) => {
        const telesinalsInfo:TeleObjectInfo[] = rows.filter((row:any) => row.selected === true).map((row:any) => {
            return {
                id: row.id,
                name: row.name
            }
        })
        setTelesinalIDs(telesinalsInfo)
    }

    const handleOnTelemetryRow = (rows:any) => {
        const telemetryInfo:TeleObjectInfo[] = rows.filter((row:any) => row.selected === true).map((row:any) => {
            return {
                id: row.id,
                name: row.name
            }
        })
        setTelemetryIDs(telemetryInfo)
    }

    const handleCommandRow = (row: any) => {
        setSelectedCommandRow(row)
        setOpen(true);
    }

    const handleCloseModal = () => {
        setOpen(false);
        setSelectedCommandRow(null);
    };
    useEffect(() => {
        console.log("IsError", isError)
    }, [isError]);


    useEffect(() => {
        if (teleObjectDataList != null && teleObjectDataList.length > 0 && erroStates.length > 0 && loadingStates.length > 0) {
            let validDataIndex = 0;
            setContent(loadingStates.map((loading, index) => {
                    if (erroStates[index]) {
                        return (
                            <Grid
                                key={`chart-equipment-details-chart-${openSubMenu}-${index.valueOf()}`}
                                ref={refChart}
                                item xs={12} sm={12} md={12}>
                                <ACard title={teleObjectIDs[index] ? teleObjectIDs[index].name : ""}>
                                    <DataNotFound boxStyle={{height: 289, width: '100%'}}/>
                                </ACard>
                            </Grid>
                        );
                    } else if (loading) {
                        return (
                            <Grid
                                key={`chart-equipment-details-chart-${openSubMenu}-${index.valueOf()}`}
                                ref={refChart}
                                item xs={12} sm={12} md={12}>
                                <ACard>
                                    <Skeleton animation="wave" height={289} variant="rounded" width="100%" />
                                </ACard>
                            </Grid>
                        );
                    } else {
                        const dataConfig = teleObjectDataList[validDataIndex % teleObjectDataList.length];
                        validDataIndex++;
                        return (
                           
                            <Grid
                                key={`chart-equipment-details-chart-${openSubMenu}-${index.valueOf()}`}
                                ref={refChart}
                                item xs={12} sm={12} md={12}
                            >
                                <TeleObjectChart teleObjectData={dataConfig}/>
                            </Grid>
                        );
                    }
                })
            )
        }
    }, [teleObjectDataList, erroStates, loadingStates]);

    return (
        <>
            <HeaderMenu onClick={insertCharts} onPeriod={handlePeriodChange}/>
            <Grid
                key={`chart-equipment-details-${openSubMenu}`}
                container spacing={3} style={{ marginTop: '20px' }}
            >
                <Grid item xs={12} lg={6} xl={4}>
                    <ACard
                        title={'Estados'}>
                        {
                            loading ? (<Skeleton animation="wave" height={289} variant="rounded" width="100%" />)
                            : (telesinalRows != null && telesinalRows.length > 0 &&
                                    <ADataGrid
                                    hideFilters={true}
                                    showFilterOptions={false}
                                    disablePagination={true}
                                    hideSelection={false}
                                    size={"small"}
                                    columns={telesinalColumns}
                                    rows={telesinalRows}
                                    onRowSelect={handleOnTelesinalRow}
                                    loading={false}
                                    totalOfRecords={telesinalRows.length ?? 0}
                                    rowsPerPage={telesinalRows.length ?? 0}
                                    onClearSelectedTempRows={isClear}
                                    onColorSelect={handleColor}
                                    maxHeight={400}
                                    /> || (
                                                <DataNotFound boxStyle={{height: 289, width: '100%'}}/>
                                        )
                               )
                        }

                    </ACard>
                </Grid>
                <Grid item xs={12} lg={6} xl={4}>
                    <ACard title={'Medidas'}>
                        {
                            loading ? (<Skeleton animation="wave" height={289} variant="rounded" width="100%" />)
                                : ( telemetryRows != null && telemetryRows.length > 0 &&
                                    <ADataGrid
                                        hideFilters={true}
                                        showFilterOptions={false}
                                        disablePagination={true}
                                        hideSelection={false}
                                        size={"small"}
                                        columns={telemetryColumns}
                                        rows={telemetryRows}
                                        onRowSelect={handleOnTelemetryRow}
                                        loading={false}
                                        totalOfRecords={telemetryRows.length ?? 0}
                                        onColorSelect={handleColor}
                                        rowsPerPage={telemetryRows.length ?? 0}
                                        maxHeight={400}
                                        onClearSelectedTempRows={isClear}
                                    /> || (
                                            <DataNotFound boxStyle={{height: 289, width: '100%'}}/>
                                        )
                                )
                        }
                    </ACard>
                </Grid>

                <Grid item xs={12} lg={6} xl={4}>
                    <ACard title={'Comandos'}>
                        {loading ? (<Skeleton animation="wave" height={289} variant="rounded" width="100%" />)
                         : ( telecommandRows != null && telecommandRows.length > 0 &&
                                <ADataGrid
                                    hideFilters={true}
                                    disablePagination={true}
                                    showFilterOptions={false}
                                    hideSelection={false}
                                    size={"small"}
                                    columns={telecommandColumns}
                                    rows={telecommandRows}
                                    onRowSelect={handleOnTelecommandRow}
                                    loading={false}
                                    totalOfRecords={telecommandRows.length ?? 0}
                                    rowsPerPage={telecommandRows.length ?? 0}
                                    actionColumnWidth={50}
                                    onClearSelectedTempRows={isClear}
                                    maxHeight={400}
                                    onColorSelect={handleColor}
                                    actions={
                                        <>
                                            <Button
                                                name="send"
                                                variant="contained"
                                                disabled={!isAuthorizedToSendCommand}
                                                onClick={handleCommandRow}
                                            >
                                                <Tooltip title="Enviar">
                                                    <ArrowForward/>
                                                </Tooltip>
                                            </Button>
                                            <></>
                                        </>
                                    }
                                /> || (
                                        <DataNotFound boxStyle={{height: 289, width: '100%'}}/>
                                    )
                        )
                        }

                    </ACard>
                </Grid>

                {teleObjectDataList != null && teleObjectDataList.length > 0 && erroStates.length > 0
                && loadingStates.length > 0 && content != null?
                    
                    (content.map(item => item))
                     : isError && (
                    <Grid
                        item xs={12} sm={12} md={12}>
                        <ACard>
                            <DataNotFound boxStyle={{height: 289, width: '100%'}}/>
                        </ACard>
                    </Grid>
                    )}
            </Grid>
            {commandDTO != null && <ModalForSendingTelecommand commandDTO={commandDTO} open={open} onCloseModal={handleCloseModal}/>}
        </>
);
}

export default EquipmentGrid;