import {useMutation, useQuery, useQueryClient} from "react-query";
import axios from "axios";
import {PagedSearchParams} from "@atiautomacao/ati-ui-library";
import {useSnackbar} from "notistack";

const apiUrl = "api/power-station/details";
const apiExpectedDataUrl = "api/expected-data";
const apiPowerStationEnabled = "/api/power-station/find-all-enabled";
const apiTeleObjectsForGridForm = "/api/teleobject/grid/find-all";
const apiTeleObjectsByEquipmentId =  "api/teleobject-config/find-all-by-equipment-id"

const fetchAllStates = async () => {
    try {
        return await axios
            .get(`api/state`)
            .then((res) => res.data.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

const fetchAllCitiesByStateId = async (id: number) => {
    try {
        return await axios
            .get(`api/cities?state=${id}`)
            .then((res) => res.data.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

export const FindAllStatesReactQuery = () => {
    return useQuery({
        queryKey: ["data-find-all-states"],
        queryFn: () => {
            return fetchAllStates();
        }
    });
}

export const FindAllCitiesByStateId = () => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation({
        mutationFn: (id: number) => {
            return fetchAllCitiesByStateId(id);
        },
        onError: (error: any) => {
            enqueueSnackbar(`Cidades não encontradas: ${error.message}`, { variant: "error" });
        }
    })
}

const fetchPowerStations = async (pagedSearchParams: PagedSearchParams) => {
    try {
        return await axios
            .get(`${apiUrl}/search`, {
                params: pagedSearchParams ? pagedSearchParams.toURLSearchParams() : null
            })
            .then((res) => res.data.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

const fetchExpectedData = async (pagedSearchParams: PagedSearchParams) => {
    try {
        return await axios
            .get(`${apiExpectedDataUrl}/search`, {
                params: pagedSearchParams ? pagedSearchParams.toURLSearchParams() : null
            })
            .then((res) => res.data.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

export const PowerStationSearchReactQuery = (pagedSearchParams: PagedSearchParams) => {
    return useQuery({
        queryKey: ["page powerStation", pagedSearchParams],
        queryFn: () => {
            return fetchPowerStations(pagedSearchParams);
        }
    });
};

export const ExpectedDataSearchReactQuery = (pagedSearchParams: PagedSearchParams, enable: boolean) => {
    return useQuery({
        queryKey: ["page expectedData", pagedSearchParams],
        queryFn: () => {
            return fetchExpectedData(pagedSearchParams);
        },
        enabled: enable
    });
};

const fetchPowerStationById = async (id: number) => {
    try {
        return await axios.get(`${apiUrl}/${id}`).then((res) => res.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

export const FindPowerStationByIdReactQuery = () => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation({
        mutationFn: (id: number) => {
            return fetchPowerStationById(id);
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao carregar a powerStation: ${error.message}`, { variant: "error" });
        }
    })
};

export const FindNumerOfEquipmentBySkidReactQuery = () => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation({
        mutationFn: (id: number) => {
            return fetchNumberOfEquipmentBySkid(id);
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao carregar a powerStation: ${error.message}`, { variant: "error" });
        }
    })
};

const deletePowerStation = async (id: number) => {
    try {
        return await axios.delete(`api/power-station/${id}`).then((res) => res.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

const fetchNumberOfEquipmentBySkid  = async (id: number) => {
    try {
        return await axios.get(`api/skid/number-of-equipment/${id}`).then((res) => res.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
}

export const DeletePowerStationReactQuery = (pagedSearchParams: PagedSearchParams) => {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: any) => {
            return deletePowerStation(data.id);
        },
        onSuccess: () => {
            enqueueSnackbar("PowerStation excluído com sucesso!", { variant: "success" });
            if (pagedSearchParams) {
                queryClient.invalidateQueries(["data", pagedSearchParams]).then(r => r);
            }
        },
        onError: (error: any) => {
            const status = error.response.data.status
            if(status === 409){
                enqueueSnackbar(`Existem dados vinculados a essa usina, por favor contactar o administrador do sistema.`, { variant: "error" });
            }else{
                enqueueSnackbar(`Erro ao excluir powerStation: ${error.message}`, { variant: "error" });
            }

        }
    })
};

const fetchAllEnabledPowerStations = async () => {
    try {
        return await axios
            .get(apiPowerStationEnabled)
            .then((res) => res.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

export const FindAllEnabledPowerStationsReactQuery = () => {
    return useQuery({
        queryKey: ["data-find-all-enabled-powerStations"],
        queryFn: fetchAllEnabledPowerStations
    });
};

const fetchTeleObjectsForGridForm = async (powerStationId: number, pagedSearchParams: PagedSearchParams) => {
    try {
        const urlParams = new URLSearchParams(pagedSearchParams.toURLSearchParams());
        if(powerStationId !== undefined){
            urlParams.append('powerStationId', powerStationId.toString());
        }
        return await axios
            .get(apiTeleObjectsForGridForm, {
                params: urlParams
            })
            .then((res) => res.data); // Ajuste conforme a estrutura da sua resposta
    } catch (error) {
        console.error("Erro ao buscar os dados:", error);
        throw error;
    }
};

export const FindAllTeleObjectsByPowerStationIdReactQuery = (powerStationId: number | null, pagedSearchParams: PagedSearchParams) => {
    return useQuery({
        queryKey: ["data-find-all-teleObjects-powerStations", powerStationId, pagedSearchParams],
        queryFn: () => {
            if (powerStationId !== null) {
                return fetchTeleObjectsForGridForm(powerStationId, pagedSearchParams);
            }
        },
        enabled: powerStationId !== null, // Desabilita a query se o powerStationId for null
    });
};

const fetchAllTeleObjectsConfigByEquipmentId = async (equipmentId: number) => {
    try {
        const response = await axios.get(`${apiTeleObjectsByEquipmentId}/${equipmentId}`);
        return response.data.data; // Retorna os dados dentro da chave `data`
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error; // Lança o erro para ser tratado onde a função for chamada
    }
};

export const FindAllTeleObjectsConfigsByEquipmentId = (equipmentId: number | undefined) => {
    return useQuery(['teleObjectsConfig', equipmentId],
        () => {
            if (!equipmentId) {
                // Retorna um array vazio ou um valor padrão se equipmentId for null
                return [];
            }
            return fetchAllTeleObjectsConfigByEquipmentId(equipmentId);
        }, {
        enabled: !!equipmentId, // Só executa a query se houver um equipmentId válido
        onError: (error) => {
            console.error('Error fetching tele objects config:', error);
        },
    });
};