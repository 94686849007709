import React, {FunctionComponent, useEffect, useState} from "react";
import {AChartX} from "@atiautomacao/ati-ui-library";
import {useTheme} from "@mui/material";
import {addMinutes, format, isValid, parseISO} from "date-fns";
import {Chart} from "./PerformanceAnalysisReducer";
import {truncateNumber} from "../../../Utils/NumberUtil";

const ActivePowerChart: FunctionComponent<{dataInstantPower: Array<any>, powerStation: string}> = ({dataInstantPower, powerStation}) =>  {
    const [activePowerList, setActivePowerList] = useState<Chart[]>([]);
    const [irradianceList, setIrradianceList] = useState<Chart[]>([]);
    const [series, setSeries] = useState<Array<any>>(Array<any>);

    const theme = useTheme();
    const style = {
        text: {
            fontSize: 16,
        }
    };
    const handleDataSeries = () => {
        let dataSeries: Array<any> = [];
        const equipmentIds = Array.from(new Set(
            dataInstantPower.map(
                item => item.equipmentId
            )
        ));

        let filteredDataInstantPower = dataInstantPower.reduce((acc: any[], current) => {
            const x = acc.find(item => item.dateTime === current.dateTime);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);

        let irradianceUniqueList = filteredDataInstantPower.map(pw => {return {dateTime: pw.dateTime, value: pw.irradiance}});

        let newArray = irradianceUniqueList ? adjustDataPoints(irradianceUniqueList) : [];

        // dataSeries.push(
        //     {
        //         name: 'Irradiância',
        //         type: 'line',
        //         unity: 'W/m²',
        //         itemStyle: {
        //             normal: {
        //                 color: '#FEC60F',
        //                 lineStyle: {
        //                     width: 1
        //                 },
        //                 smooth: true
        //             }
        //         },
        //         yAxisIndex: 1,
        //         data: newArray.map(element => element.value != null ? truncateNumber(element.value, 2) : '-'),
        //         tooltip: {
        //             valueFormatter: (value: any) => `${value}W/m²`,
        //             show: true
        //         },
        //         smooth: true,
        //         areaStyle: {
        //             color: 'rgb(241,226,157)'
        //         }
        //     }
        // );

        equipmentIds.forEach(element => {
            let filter = dataInstantPower.filter(data => data.equipmentId === element);
            let newData = adjustDataPoints(filter);
            if(filter.length > 0) {
                dataSeries.push(
                    {
                        name: filter[0].equipmentName + " - " + filter[0].skidName,
                        data: handleSeriesData(newData),
                        type: 'line',
                        unity: 'kW',
                        smooth: true
                    }
                )
            }
        });

        setSeries(dataSeries);
    }

    const handleSeriesData = (data:any[]) => {
        return data.map(element => element.activePower != null && truncateNumber(element.activePower, 2));
    }

    const adjustDataPoints = (dataPoints: any[]): any[] => {
        if (dataPoints.length === 0) {
            return dataPoints;
        }

        const firstDateTime = parseISO(dataPoints[0].dateTime);
        const lastDateTime = parseISO(dataPoints[dataPoints.length - 1].dateTime);
        const skidName = dataPoints[0].skidName
        const equipmentId = dataPoints[0].equipmentId
        const equipmentName = dataPoints[0].equipmentName

        const startTime = new Date(firstDateTime);
        startTime.setHours(4, 0, 0, 0); // 4:00
        const endTime = new Date(lastDateTime);
        endTime.setHours(20, 0, 0, 0); // 20:00

        let newData = [...dataPoints]
        while (firstDateTime > startTime) {
            const newDateTime = addMinutes(firstDateTime, -5);
            newData.unshift({
                dateTime: format(newDateTime, 'yyyy-MM-dd HH:mm'),
                activePower: null,
                equipmentId: equipmentId,
                equipmentName: equipmentName,
                value: null,
                skidName: skidName,
            });
            firstDateTime.setTime(newDateTime.getTime());
        }

        while (lastDateTime < endTime) {
            const newDateTime = addMinutes(lastDateTime, 5);
            newData.push({
                dateTime: format(newDateTime, 'yyyy-MM-dd HH:mm'),
                activePower: null,
                equipmentId: equipmentId,
                equipmentName: equipmentName,
                value: null,
                skidName: skidName,
            });
            lastDateTime.setTime(newDateTime.getTime());
        }

        return newData;
    }

    useEffect(() => {
        if(dataInstantPower.length > 0) {
            // let activePowerCompletedList = dataInstantPower.map(pw => {return {dateTime: pw.dateTime, value: pw.activePower}});
            // let activePowerListUnique = activePowerCompletedList.reduce((acc: any[], current) => {
            //     const x = acc.find(item => item.dateTime === current.dateTime);
            //     if (!x) {
            //         return acc.concat([current]);
            //     } else {
            //         return acc;
            //     }
            // }, []);
            // let irradianceListUnique = dataInstantPower.map(pw => {return {dateTime: pw.dateTime, value: pw.irradiance}});
            // let irradianceListUniqueList = irradianceListUnique.reduce((acc: any[], current) => {
            //     const x = acc.find(item => item.dateTime === current.dateTime);
            //     if (!x) {
            //         return acc.concat([current]);
            //     } else {
            //         return acc;
            //     }
            // }, []);
            setActivePowerList(
                dataInstantPower
                    .filter(pw => pw.dateTime.split(" ")[1] > "04:10:00")
                    .map(pw => {return {dateTime: pw.dateTime, value: pw.activePower}}));
            setIrradianceList(
                dataInstantPower
                    .filter(pw => pw.dateTime.split(" ")[1] > "04:10:00")
                    .map(pw => {return {dateTime: pw.dateTime, value: pw.irradiance}}));
            // handleDataSeries();
        }
    }, []);

    const chartOption: any = {
        yAxis: [
            {
                name: '',
                min: 0,
                max: Math.max(...activePowerList.map(item => item.value ? parseInt(item.value.toString()) : item.value)),
                interval: Math.max(...activePowerList.map(item => item.value ? parseInt(item.value.toString()) : item.value)) / 2,
                axisLabel: {
                    show: false
                },
                splitLine: {
                    show: false
                }
            },
            {
                name: '',
                min: 0,
                max: Math.max(...irradianceList.map(item => item.value ? parseInt(item.value.toString()) : item.value)),
                interval: Math.max(...irradianceList.map(item => item.value ? parseInt(item.value.toString()) : item.value)) / 2,
                axisLabel: {
                    show: false
                },
                splitLine: {
                    show: false
                }
            }
        ],
        xAxis: {
            type: 'category',
            boundaryGap: true,
            name: '',
            nameLocation: 'center',
            nameGap: 20,
            data: activePowerList.map(item => {
                const date = parseISO(item.dateTime);
                if (isValid(date)) {
                    return format(date, 'dd/MM HH:MM');
                }
                return item.dateTime;
            }),
            offset: 5,
            axisLabel: {
                show: false,
                inside: false,
                showMinLabel: true,
                showMaxLabel: true,
                align: 'center',
                // interval: "auto",
            },
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
        },
        series: [
            {
                name: 'Potência',
                type: 'line',
                smooth: true,
                showSymbol: false,
                data: activePowerList.map(item => item.value ? parseInt(item.value.toString()) : "-"),
                areaStyle: {},
            }
        ],
        legend: {
            show: false,
        },
        grid: {
            containLabel: true,
            top: 0,
            bottom: 10,
        },
        animationDuration: 2000,
        color: ['rgb(25, 118, 210)'],
        tooltip: {
            trigger: 'axis',
            textStyle: {
                fontSize: style.text.fontSize
            },
            formatter: function (params: any) {
                let tooltipText = '';
                if(params.length > 0) {
                    tooltipText += `${params[0].axisValue}<br/>`
                }
                params.forEach((param: any) => {
                    if (param.seriesName === 'Irradiância') {
                        tooltipText += `${param.marker} ${param.seriesName}: <b>${param.value} W/m²</b><br/>`;
                    } else {
                        tooltipText += `${param.marker} ${param.seriesName}: <b>${param.value} kW</b><br/>`;
                    }
                });
                return tooltipText;
            }
        }
    };

    return (
        <>
            {
                dataInstantPower.length > 0 &&
                    <AChartX
                        height={40}
                        option={chartOption}
                        theme={theme.palette.mode}
                        style={{zIndex: 1000}}
                    />
            }
        </>
    )

}

export default ActivePowerChart;