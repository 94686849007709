import React, {FunctionComponent, useEffect, useState} from 'react';

import {Autocomplete, Box, Button} from "@mui/material";
import { SkidProps } from '../InverterPage';
import TextField from "@mui/material/TextField";
import {
    CommandDTO,
    ModalForSendingTelecommand
} from "../../../../../Shared/Components/ModalForSendingTelecommand";
import {findAllCommands} from "../../../../../Utils/CommandService";


const SelectInverter: FunctionComponent<{ onSelectedElement: any, data: Array<SkidProps> }> = ({onSelectedElement, data}) => {
    const options = () => {
        let dataStructure: {firstLetter: string, name: string, id: number }[] = [];
        data.map((option) => {
            let firstLetter = option.name.toUpperCase();
            option.equipmentList.map(equip => {
                dataStructure.push(
                    {
                        firstLetter: firstLetter,
                        id: equip.id,
                        name: equip.name,
                    });
            })

        })

        return dataStructure;
    };

    const [checked, setChecked] = useState<any>(options()[0]);
    const [equipmentId, setEquipmentId] = useState<number>(-1);
    const [teleCommandList, setTeleCommandList] = useState<Array<CommandDTO>>([])
    const [open, setOpen] = useState(false);
    const [commandDTO, setCommandDTO] = useState<CommandDTO | null>();

    useEffect(() => {
        if(data && data.length > 0) {
            let option = options()[0]
            option = {
                ...option,
                // name: option.firstLetter,
            }
            setChecked(option);
        }
    }, [data]);

    const handleChange = (event: any, newValue: any) => {
        if(newValue) {
            setEquipmentId(newValue.id)
            setChecked( {
                ...newValue,
            })
        }else{
            setEquipmentId(-1)
        }
        onSelectedElement(newValue?.id, `${newValue?.firstLetter + " - " + newValue?.name}`);
    };

    useEffect(() => {
        if (equipmentId !== -1) {
            findAllCommands(equipmentId).then((response:any) => {
                if(response.status === 200){
                    setTeleCommandList(response.data.data)
                }else{
                    setTeleCommandList([])
                }
            }).catch((error:any) => {
                console.error("Error fetching tele commands:", error);
            });
        }else{
            setTeleCommandList([]);
        }
    }, [equipmentId]);

    const handleClose = (reason: any) => {
        if(!reason) {
            setChecked( null)
            onSelectedElement();
        }
    };

    const handleCloseModal = () => {
        setOpen(false);
        setCommandDTO(null)
    };

    useEffect(() => {
        if(checked !== null) {
            onSelectedElement(checked?.id, `${checked?.firstLetter + " - " + checked?.name}`);
        }
    }, [checked]);


    const handleButtonClick = (event:any, data:CommandDTO) => {
        setCommandDTO(data)
    }

    const handleSendButtonClick = () => {
        setOpen(true)
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: 2, gap: 2,  alignItems: 'flex-end' }}>
            <Autocomplete
                id="grouped-demo"
                value={checked}
                fullWidth
                // sx={{width: 300}}
                options={options().sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                groupBy={(option) => option.firstLetter}
                getOptionLabel={(option) => option.name}
                inputValue={checked}
                onClose={(event, reason) => handleClose(event)}
                renderInput={(params) => <TextField {...params} label={"Inversores"} variant="standard" />}
                onChange={(event: any, newValue: any) => handleChange(event, newValue)}
            />
            <Autocomplete
                id="grouped-demo"
                value={commandDTO}
                fullWidth
                options={teleCommandList}
                getOptionLabel={(option) => option.teleObjectName}
                inputValue={commandDTO?.teleObjectName ?? ''}
                noOptionsText="Sem opções"
                renderInput={(params) => <TextField {...params} label={"Comandos"} variant="standard" />}
                onChange={(event: any, newValue: any) => handleButtonClick(event,newValue)}
            />
            <Button
                disabled={commandDTO == null}
                onClick={() => handleSendButtonClick()}
                size={"small"}
                variant="contained"
                sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '30px',
                    whiteSpace: 'nowrap',
                    width: {
                        xs: '20%',
                        sm: 'auto',
                    },
                    minWidth: 60,
                    textTransform: 'capitalize'
                }}
            >
                Enviar
            </Button>
            {commandDTO != null && <ModalForSendingTelecommand commandDTO={commandDTO} open={open} onCloseModal={handleCloseModal}/>}
        </Box>

    )
}

export default SelectInverter;
