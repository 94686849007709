import {ADataGridColumn, ADataGridFilter} from "@atiautomacao/ati-ui-library";

const createColumn = (
    name: string,
    label: string,
    align: 'left' | 'right' | 'center',
    minWidth: number,
    filter?: ADataGridFilter
): ADataGridColumn => ({
    name,
    label,
    align,
    visibility: true,
    minWidth,
    breakpoints: {
        xs: true,
        sm: true,
        md: true,
        lg: true,
        xl: true
    },
    ...(filter && { filter })
});

export const initColumnsTeleObjectGrid = [
    createColumn('equipmentEts', 'Remota', 'center', 50, { name: 'equipmentEts', condition: 'equals', value: '', sort: 'desc' }),
    createColumn('equipmentName', 'Equipamento', 'left', 100),
    createColumn('teleObjectConfigPoint', 'Posição', 'left', 100, { name: 'teleObjectConfigPoint', condition: 'equals', value: '', sort: 'desc' }),
    createColumn('teleObjectConfigName', 'Descrição', 'left', 100),
    createColumn('equipmentClassName', 'Classe de Equipamento', 'left', 100)
] as ADataGridColumn[];