import React, {FunctionComponent, useEffect, useState} from "react";
import {useAppSelector} from "../../../../../Config/Hooks";
import {FindPowerVSIrradianceDataByEquipmentIdAndByHour} from "../InverterReactQuery";
import {useTheme} from "@mui/material";
import {ACard, AChartX, Period, PeriodSelector, Skeleton, XAxis, YAxis} from "@atiautomacao/ati-ui-library";
import {addMinutes, format, parseISO} from "date-fns";
import {roundNextHundred, truncateNumber} from "../../../../../Utils/NumberUtil";
import {PowerIrradiance} from "../../DashboardPowerPlantCharts";
import {useSnackbar} from "notistack";
import {isArray} from "lodash";
import DataNotFound from "../../../../../Shared/Components/DataNotFoundMessage";



interface SeriesOption {
    name: string;
    type: string;
    unity: string;
    yAxisIndex?: number ;
    data: any[];
    date?: any;
    showSymbol?: boolean;
}

const PowerIrradianceInverterChart: FunctionComponent<{ powerPlant: any, equipmentId: number, nominalPower: number}> = ({powerPlant,equipmentId, nominalPower}) => {

    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);
    const mutatePowerInverter = FindPowerVSIrradianceDataByEquipmentIdAndByHour();
    const theme = useTheme();
    const [powerIrradiance,setPowerIrradiance]  = useState<PowerIrradiance[]>([]);
    const initialPeriod: Period = {
        groupBy: "day",
        fromDateTime: new Date(),
        toDateTime: new Date()
    };
    const { enqueueSnackbar } = useSnackbar();

    const [period, setPeriod] = useState<Period>(initialPeriod);
    const [xAxis, setXAxis] = useState<XAxis>();
    const [yAxis, setYAxis] = useState<Array<YAxis>>(Array<YAxis>());
    const [series, setSeries] = useState<Array<SeriesOption>>(Array<SeriesOption>());
    const [showGraph, setShowGraph] = useState(false);

    const isDay = (group:string)=>{
        return group === "day"
    }

    const callback = (params:any) => {
        let formatDate = period && period.groupBy.valueOf() === "week" ? 'dd/MM HH:mm' : 'HH:mm'
        let dateStr = format(new Date(params[0].axisValue), formatDate);

        let result = dateStr + '<br/>';
        params.forEach(function (item: any) {
            result += item.marker + " " + item.seriesName + "&nbsp;&nbsp;&nbsp;&nbsp;" + "<strong>" + item.value[1] + " " + (item.seriesName == "Irradiância" ? 'W/m²' : 'kW') + "</strong>" + "<br/>";
        });

        return result;
    }

    const adjustDataPoints = (dataPoints: any[]): any[] => {
        if (dataPoints.length === 0) {
            return dataPoints;
        }

        // Parseia o primeiro e último dateTime para objetos Date
        const firstDateTime = parseISO(dataPoints[0].dateTime);
        const lastDateTime = parseISO(dataPoints[dataPoints.length - 1].dateTime);

        // Horários limite
        const startTime = new Date(firstDateTime);
        startTime.setHours(4, 0, 0, 0); // 4:00
        const endTime = new Date(lastDateTime);
        endTime.setHours(20, 0, 0, 0); // 20:00

        let newData = [...dataPoints]
        while (firstDateTime > startTime) {
            const newDateTime = addMinutes(firstDateTime, -5);
            newData.unshift({
                dateTime: format(newDateTime, 'yyyy-MM-dd HH:mm:ss.S'),
                activePower: null,
                irradiance: null,
            });
            firstDateTime.setTime(newDateTime.getTime());
        }

        while (lastDateTime < endTime) {
            const newDateTime = addMinutes(lastDateTime, 5);
            newData.push({
                dateTime: format(newDateTime, 'yyyy-MM-dd HH:mm:ss.S'),
                activePower: null,
                irradiance: null,
            });
            lastDateTime.setTime(newDateTime.getTime());
        }

        return newData;
    }
    const hoursToMilliseconds = (hours:number): number => {
        return hours * 60 * 60 * 1000
    };

    const minDate = (minDate:string, group:string) : string | undefined => {
        if(!isDay(group)) return undefined;
        const min = new Date(minDate);
        min.setHours(4, 0, 0, 0); // 4:00
        return format(min, 'yyyy-MM-dd HH:mm:ss.S')
    }

    const maxDate = (maxDate:string, group:string) : string | undefined => {
        if(!isDay(group)) return undefined;
        const max = new Date(maxDate);
        max.setHours(20, 0, 0, 0);
        return format(max, 'yyyy-MM-dd HH:mm:ss.S')
    }

    const findPowerDataByPeriod = () => {
        if(period.groupBy){
            const grouping = period.groupBy.valueOf()
            const newData =  isDay(grouping) ? adjustDataPoints(powerIrradiance) : powerIrradiance
            let xAxisName: string= 'Dia';
            if (isDay(grouping)) {
                xAxisName = 'Horário';
            }

            setXAxis({
                name: xAxisName,
                type:'time',
                min: minDate(newData[0]?.dateTime, period.groupBy.valueOf()) ,
                max: maxDate(newData[0]?.dateTime, period.groupBy.valueOf()) ,
                nameLocation: 'middle',
                minInterval: isDay(grouping) ? hoursToMilliseconds(3) : hoursToMilliseconds(24),
                axisLabel: {
                    formatter: isDay(grouping) ? '{HH}:{mm}' : '{dd}/{MM}'
                },
                nameGap: 30
            });

            setShowGraph(powerIrradiance.length > 0);
            const percentage = 0.15;
            const capacity = nominalPower ?? 125.0
            const capacityMaxLeftY = roundNextHundred(percentage * capacity + (capacity));
            const defaultRightY = 1200;
            setYAxis([
                {
                    name: 'kW',
                    max: capacityMaxLeftY,
                    min: 0,
                    interval: roundNextHundred(capacityMaxLeftY / 4),
                    nameLocation: 'middle',
                    nameGap: 60,
                },
                {
                    name: 'W/m²',
                    max: defaultRightY,
                    min: 0,
                    interval: roundNextHundred(defaultRightY / 4),
                    nameLocation: 'middle',
                    nameGap: 60,
                }
            ])
            setSeries([
                    {
                        name: 'Potência AC',
                        data: Array.isArray(newData) ? newData.map((item) => item.activePower != null ? [item.dateTime ? item.dateTime : "", truncateNumber(item.activePower, 2)] : [item.dateTime ? item.dateTime : "", "-"]) : [],
                        type: 'line',
                        unity: 'Kw',
                        showSymbol: false
                    },
                    {
                        name: 'Irradiância',
                        data: Array.isArray(newData) ? newData.map((item) => item.irradiance != null ?  [item.dateTime ? item.dateTime : "", truncateNumber(item.irradiance, 2)]: [item.dateTime ? item.dateTime : "","-"]) : [],
                        type: 'line',
                        unity: 'W/m²',
                        showSymbol: false,
                        yAxisIndex: 1,
                    }
                ]
            );
        }

    }
    useEffect(() => {
        if(equipmentId && powerPlant.id){
            mutatePowerInverter.mutate(
                {
                    startDateTime: period.fromDateTime,
                    endDateTime: period.toDateTime,
                    powerStationId: powerPlant.id,
                    equipmentId: equipmentId
                },
                {
                    onSuccess: (data) => {
                        setPowerIrradiance(data?.data || []);
                    },
                    onError: (error: any) => {
                        enqueueSnackbar(`Erro ao carregar dados de potência x irradiância: ${error.message}`, { variant: "error" });
                    }
                }
            );
        } else {
            setPowerIrradiance([]);
        }

    }, [period, equipmentId, powerPlant.id]);

    useEffect(() => {
        if (isArray(powerIrradiance) && powerIrradiance.length > 0 && period.groupBy){
            findPowerDataByPeriod();
        }else{
            mutatePowerInverter.reset()
            setSeries([])
            setXAxis(undefined)
            setYAxis([])
        }
    }, [powerIrradiance]);


    // @ts-ignore
    const option: AChartXProps['option'] = {
        color: ['rgb(25, 118, 210)', 'rgb(255,198,10)'],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    formatter: function (params: any) {
                        const formatDate = period.groupBy.valueOf() === "week" ? "dd/MM HH:mm" : "HH:mm"

                        if (params.axisDimension === 'x') {
                            return format(new Date(params.value), formatDate);
                        }
                        if (params.axisDimension === 'y') {
                            return truncateNumber(params.value,2);
                        }
                    }
                }
            },
            formatter: callback
        },
        toolbox: {
            right: '15%',
            top: -8,
            itemSize: 15,
            feature: {
                dataZoom: {
                    yAxisIndex: 'none'
                },
                magicType: { show: false, type: ['line', 'bar'] },
                restore: { show: true },
                saveAsImage: { show: false }
            }
        },
        dataZoom: [
            {
                type: 'inside',
                throttle: 50
            },
        ],
        legend: {
            show: true,
            type: 'scroll',
            orient: 'horizontal', // Alterado para orientação horizontal
            bottom: -6, // Posiciona a legenda abaixo do gráfico
            left: 'center', // Centraliza a legenda horizontalmente
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            textStyle: {
                opacity: 1
            },
            itemStyle: {
                opacity: 1
            },
            data: series.map(el => el.name)
        },
        grid: {
            containLabel: true,
            top: 10,
            bottom: 40,
        },
        label: {
            precision: 1,
            position: 'right',
            valueAnimation: true,
            fontFamily: 'monospace'
        },
        animationDuration: 2000,
        xAxis: xAxis,
        yAxis: yAxis,
        series: series,
    };


    let content;
    if (!mutatePowerInverter.isSuccess) {
        content = <Skeleton animation="wave" height={289} variant="rounded" width="100%" />;
    } else if (isArray(powerIrradiance) && powerIrradiance.length > 0) {
        console.log("entrou aki")
        content = (
            <AChartX
                option={option}
                width={'100%'}
                height={289}
                loading={false}
                theme={theme.palette.mode}
            />
        );
    } else {
        content = <DataNotFound boxStyle={{ height: 289, width: '100%' }} />;
    }


    return (
        <ACard
            key={`chart-power-generation-${openSubMenu}-${period.groupBy}`}
            styleProps={{
                cardStyle: { height: 445 },
                contentStyle: { padding: 0, marginTop: 70 }
            }}
            title={"Potência x Irradiância"}
            headerControls={
                <PeriodSelector
                    styleProps={{ base: { flexDirection: "row", display: "flex", alignItems: "center" } }}
                    mode="hybrid"
                    hideDatetime={false}
                    hideGroup={false}
                    inputFormat={period.groupBy === "day" ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'}
                    period={period}
                    periodInterval={{
                        day: {date: period.toDateTime, startTime: "00:00:00", endTime: "23:59:59"},
                        week: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 6}
                    }}
                    groupByOptions={["day", "week"]}
                    onChange={setPeriod}
                />
            }

        >
            {content}
        </ACard>
    );
}

export default PowerIrradianceInverterChart;