import React, {FunctionComponent, useEffect, useState} from "react";
import {
    Button,
    Container,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useTheme
} from "@mui/material";
import {faChartBar, faCircleExclamation, faSun} from "@fortawesome/free-solid-svg-icons";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import './stylesTrackers.css';
import {ACard, AChartX, Skeleton} from "@atiautomacao/ati-ui-library";
import {useDispatch} from "react-redux";
import {clearEntities, clearIds, getEntities, getNcus, NcuEquipment} from "./TrackersReducer";
import {useAppSelector} from "../../../../Config/Hooks";
import {AppDispatch, RootState} from "../../../../Config/Store";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ChartNcu} from "../../../../Shared/Types/ChartNcu";
import {Tracker} from "../../../../Shared/Types/Tracker";
import {ChartSeriesType} from "../../../../Shared/Types/chartSeriesType";
import {useNavigate} from "react-router-dom";
import {setEquipment, setPowerPlant, setSkid} from "../../DashboardReducer";
import {DashboardNavigationMenu} from "../../../../Shared/Components/Layout/menu/types/DashboardNavigationMenu";
import useInterval from "../../../../Shared/Hooks/useInterval";
import Box from "@mui/material/Box";
import SendIcon from "@mui/icons-material/Send";
import {useSnackbar} from "notistack";
import {CommandDTO, SendingTelecommandUnifilar} from "../../../../Shared/Components/SendingTelecommandUnifilar";
import {findAllCommands} from "../../../../Utils/CommandService"

const TrackersPage: FunctionComponent<{powerPlantId: number}> = ({powerPlantId}) => {
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);
    const {entity, idsNcu, loading, periodTrackers} = useAppSelector((state: RootState) => state.trackers);
    const dispatch = useDispatch<AppDispatch>();
    const [ncuList, setNcuList] = useState<NcuEquipment[]>([]);
    const [actualIndex, setActualIndex] = useState(-1);
    const navigate = useNavigate();
    const { entities } = useAppSelector((state: RootState) => state.dashboardNavigationMenu);
    const [commandDTOList, setCommandDTOList] = useState<CommandDTO[]>([])
    const [open, setOpen] = useState(false)
    const [equipmentId, setEquipmentId] = useState(-1)

    // @ts-ignore
    const [powerStationEntity, setPowerStationEntity] = useState<DashboardNavigationMenu>(null);


    useEffect(() => {
        dispatch(clearEntities());
        dispatch(clearIds());
        dispatch(getNcus(powerPlantId))
            .then(() => {
                setActualIndex(0);
                setNcuList([]);
                let psEntity = entities.find(et => et.id === powerPlantId);
                if(psEntity) {
                    setPowerStationEntity(psEntity);
                }
            });
    }, [powerPlantId, periodTrackers]);

    useEffect(() => {
        if(idsNcu && idsNcu.length > 0 && actualIndex < idsNcu.length){
            fetchEntitiesForId(idsNcu[actualIndex]);
        }
    }, [actualIndex]);

    const fetchEntitiesForId = (id: number) => {
        if (id){
            dispatch(getEntities({powerPlantId, id, periodTrackers}))
                .then(() => {
                    setActualIndex(prevIndex => prevIndex + 1);
                })
        }
    };

    useInterval(() => {
       setActualIndex(0)
    }, 60000); //1 minute

    useEffect(() => {
        if(entity){
            if(!ncuList.find(ncu => ncu.id === entity.id) && powerStationEntity){
                let ncuName: string = powerStationEntity.name;
                let entityCopy = {...entity}

                if(entity?.name) {
                    ncuName = ncuName + " - " + entity.name;
                } else {
                    powerStationEntity.equipmentList.forEach(eqp => {
                        if(eqp.id === entity.id) {
                            ncuName = ncuName + " - " + eqp.name;
                        }
                    });
                }

                entityCopy.ncuName = ncuName;
                if(ncuList.length === 0){
                    setNcuList([entityCopy]);
                } else {
                    setNcuList([...ncuList, entityCopy]);
                }
            }
        }
    }, [entity, actualIndex]);

    const handleCloseModal = () => {
        setOpen(false)
        setCommandDTOList([]);
        setEquipmentId(-1)
    };


    useEffect(() => {
        if (equipmentId != -1) {
            findAllCommands(equipmentId).then(
                (response:any) => {
                    if (response.status === 204) {
                        setCommandDTOList([]);
                        enqueueSnackbar("Comandos não encontrados!", {variant: "error", autoHideDuration: 2000});
                    } else {
                        setCommandDTOList(response.data.data);
                    }
                }
            ).catch(
                (error:any) => {
                setCommandDTOList([]);
                    enqueueSnackbar(`Erro ao carregar comandos: ${error.message}`, { variant: "error",autoHideDuration: 2000 });
            })
        }else{
            setCommandDTOList([]);
        }
    }, [equipmentId]);

    const toOpen = (id:number) => {
        setEquipmentId(id)
        setOpen(true)
    }

    function navigateTo(ncu: NcuEquipment){
        let skidEq = null;
        let equipment = null;
        if(powerStationEntity){
            powerStationEntity.skidList.forEach(skid => {
                equipment = skid.equipmentList.find(eq => eq.id === ncu.id)
                if(equipment){
                    skidEq = skid;
                    dispatch(setPowerPlant(entity));
                    dispatch(setSkid(skidEq));
                    dispatch(setEquipment(equipment));
                }
            })
        }

        navigate(`/dashboard/power-plant/skid/equipments/details`);
    }

    const headTitle = ['Tracker', 'Posição Alvo', 'Posição do Tracker'];
    function createOptions(chart: ChartNcu){
        let nameList: string[] = [];
        chart?.series && chart.series.forEach((s: ChartSeriesType) => {
            if (s.name != null) {
                nameList.push(s.name);
            }
        });

        let series = chart?.series ? chart.series : [];
        let windowSize = window.innerWidth;
        // @ts-ignore
        const options: AChartXProps['option'] = {
            legend: {
                type: 'scroll',
                orient: windowSize < 771 ? 'horizontal' : 'vertical',
                right: 0,
                top: 20,
                bottom: 0,
                height: 375,
                data: nameList
            },
            xAxis: chart?.xAxis ? chart?.xAxis[0] : {},
            yAxis: chart?.yAxis ? chart?.yAxis[0] : {},
            series: series,
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross'
                }
            },
            toolbox: {
                right: '6%',
                itemSize: 25,
                top: 0,
                feature: {
                    dataZoom: {
                        yAxisIndex: 'none'
                    },
                    restore: {}
                }
            },
            dataZoom: [
                {
                    type: 'inside',
                    throttle: 50
                },
            ],
        }
        return options;
    }

    function createOptionsSpeed(ncu: any) {
        // @ts-ignore
        const optionSpeed: AChartXProps['option'] = {
            series: [
                {
                    type: 'gauge',
                    min: 0,
                    max: 240,
                    axisLine: {
                        lineStyle: {
                            width: 10,
                            color: [
                                [0.3, '#67e0e3'],
                                [0.7, '#37a2da'],
                                [1, '#fd666d']
                            ]
                        }
                    },
                    pointer: {
                        itemStyle: {
                            color: 'auto'
                        }
                    },
                    axisTick: {
                        distance: -10,
                        length: 8,
                        lineStyle: {
                            color: '#fff',
                            width: 2
                        }
                    },
                    splitLine: {
                        distance: -45,
                        length: 20,
                        lineStyle: {
                            color: '#fff',
                            width: 2
                        }
                    },
                    axisLabel: {
                        color: 'inherit',
                        distance: 40,
                        fontSize: 8
                    },
                    detail: {
                        valueAnimation: true,
                        formatter: '{value} km/h',
                        color: 'inherit',
                        fontSize: 10
                    },
                    data: [
                        {
                            value: ncu.windSpeed
                        }
                    ]
                }
            ]
        };

        return optionSpeed;
    }

    function createOptionDirection(ncu: any) {
        // @ts-ignore
        const optionDirection: AChartXProps['option'] = {
            series: [
                {
                    name: 'Direção do Vento',
                    type: 'gauge',
                    startAngle: 90,
                    endAngle: -270,
                    min: 0,
                    max: 360,
                    detail: {
                        valueAnimation: true,
                        formatter: '{value} °',
                        fontSize: 10
                    },
                    axisLabel: {
                        show: false
                    },
                    axisTick: {
                        distance: 0,
                        length: 5,
                        lineStyle: {
                            color: '#bcbcbc',
                            width: 2
                        }
                    },
                    axisLine: {
                        fontSize: 10
                    },
                    splitLine: {
                        distance: 5,
                        length: 5,
                        lineStyle: {
                            color: 'black',
                            width: 2
                        }
                    },
                    title: {
                        fontSize: 8
                    },
                    pointer: {
                        icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                        length: '15%',
                        width: 10,
                        offsetCenter: [0, '-40%'],
                        itemStyle: {
                            color: 'blue'
                        }
                    },
                    data: [
                        { value: ncu.windDirection }
                    ]
                }
            ]
        };

        return optionDirection;
    }

    function createTable(trackers: Tracker[]){
        let numTabelas = 1;
        let tabelas = [[]];

        if(window.innerWidth > 770 && window.innerWidth < 1400) {
            tabelas.push([]);
            numTabelas = 2;
        }
        if(window.innerWidth > 1400) {
            tabelas.push([]);
            tabelas.push([]);
            numTabelas = 3;
        }

        for (let i = 0; i < trackers?.length; i++) {
            const grupoIndex = i % numTabelas;
            // @ts-ignore
            tabelas[grupoIndex].push(trackers[i]);
        }
  
        return (
            <div className="trackersTable">
                {
                    tabelas && tabelas.length > 0 && tabelas.map((tabela: any, index: number) => {
                        return (
                            <TableContainer key={index.valueOf()}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {headTitle.map((title) => {
                                                return (
                                                    <TableCell
                                                        key={title}
                                                        align={"left"}
                                                        style={{
                                                            backgroundColor: "rgba(4,104,190,0.92)",
                                                            color: "white"
                                                        }}
                                                    >
                                                        {title}
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tabelas && tabelas.length > 0 && tabela.map((row: any, i: number) => (
                                            <TableRow
                                                key={row.tracker}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell
                                                    align="left"
                                                    component="th"
                                                    scope="row"
                                                    sx={{display: 'flex', justifyContent: 'space-between'}}
                                                >
                                                    {row.alarmed ?
                                                        <FontAwesomeIcon icon={faCircleExclamation} style={{
                                                            color: 'red'
                                                        }}/> :
                                                        <FontAwesomeIcon icon={faSun} style={{
                                                            color:'green'
                                                        }}/>
                                                    }
                                                    {row.name}
                                                </TableCell>
                                                <TableCell
                                                    align="left">{row.lastSunPosition}°</TableCell>
                                                <TableCell
                                                    align="left">{row.lastTrackerPosition}°</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )
                    })
                }
            </div>
        )
    }

    let skeletonToRender = <Skeleton animation="wave" variant="rounded" height={800} width="100%" />;
    let tablesToRender = ncuList.map(ncu => {
        return (
            <div key={ncu.ncuName}>
                <ACard
                    key={`chart-power-trackers-${openSubMenu}-${ncu.ncuName}`}
                    title={ncu.ncuName}
                    styleProps={{
                        contentStyle: {display: "flex", flexDirection: "column"}
                    }}
                    headerControlsPosition={"header"}
                    headerControls={
                        <Button
                            variant="contained"
                            size={"small"}
                            onClick={() => {toOpen(ncu.id)}}
                            endIcon={<SendIcon/>}
                            sx={{ marginBottom: "5px", textTransform: 'capitalize', height: '25px'}}  // Espaçamento entre o botão e o seletor
                        >
                            Comandos
                        </Button>
                    }

                    headerActions={
                        <Button variant={"contained"} color={"secondary"}
                                sx={{textTransform: "capitalize"}}
                                startIcon={<FontAwesomeIcon icon={faChartBar}/>}
                                onClick={() => navigateTo(ncu)}>
                            Equipamento
                        </Button>
                    }
                >
                    {
                        ncu.trackers !== null ?
                            <>
                                <Box className="ncuGraphs">
                                    <Box className="windStyle">
                                        <>
                                            <AChartX
                                                option={createOptionsSpeed(ncu)}
                                                height={175}
                                                loading={false}
                                                style={{marginTop: '-2%'}}
                                                theme={theme.palette.mode}
                                            />
                                            <Typography style={{marginLeft: '22%', marginTop: '-5%'}} variant="subtitle1" fontSize={12} color={"#000"}>Velocidade do Vento</Typography>
                                        </>
                                        <>
                                            <AChartX
                                                option={createOptionDirection(ncu)}
                                                height={175}
                                                loading={false}
                                                style={{marginTop: '-2%'}}
                                                theme={theme.palette.mode}
                                            />
                                            <Typography style={{marginLeft: '25%', marginTop: '2%'}} variant="subtitle1" fontSize={12} color={"#000"}>Direção do Vento</Typography>
                                        </>
                                    </Box>
                                    <Box style={{width: "85%"}}>
                                        <AChartX
                                            option={createOptions(ncu.chart)}
                                            width={window.innerWidth < 1050 ? "123%" : "112%"}
                                            height={450}
                                            loading={false}
                                            style={{marginTop: '2%', marginLeft: window.innerWidth < 1050 ? '-6%' : '-9%'}}
                                            theme={theme.palette.mode}
                                        />
                                    </Box>
                                </Box>
                                <Accordion style={{marginTop: 0}}>
                                    <AccordionSummary
                                        expandIcon={<ArrowDropDownIcon/>}
                                        aria-controls="panel2-content"
                                        id="panel2-header"
                                    >
                                        <Typography>Trackers</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {createTable(ncu.trackers)}
                                    </AccordionDetails>
                                </Accordion>
                            </>
                        :
                        <Container fixed>
                            <Box sx={{ bgcolor: '#e1e0e0', height: '500px', width: '100%' ,alignItems: 'center',
                                    justifyContent: 'center', display:'flex'}}>
                                <div style={{ textAlign: 'center' , fontSize: '23px', color: '#646363'}}>
                                    <span>Nenhum dado de tracker encontrado</span>
                                </div>
                            </Box>
                        </Container>
                    }
                </ACard>
                <br/>
            </div>
        )
    })

    // @ts-ignore
    return (
        <>
        <Grid>
            {
                loading && ncuList.length === 0 ?
                    skeletonToRender :
                    tablesToRender !== undefined && tablesToRender.length > 0 && tablesToRender
            }
        </Grid>
        {commandDTOList.length > 0 &&
        <SendingTelecommandUnifilar
            open={open}
            onCloseModal={handleCloseModal}
            commandDTOList={commandDTOList}
        />
        }
        </>
    )
}

export default TrackersPage;