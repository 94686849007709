import {Grid} from "@mui/material";
import {useAppSelector} from "../../../Config/Hooks";
import TotalizerPlant from "./TotalizerPlant/TotalizerPlant";
import GenerationPlant from "./GenerationPlant/GenerationPlant"
import WeatherStationPlant from "./WeatherStationPlant/WeatherStationPlant";
import EnvironmentalDataPlant from "./EnvironmentalData/EnvironmentalDataPlant";
import PowerStationElements from "./PowerStationElements/PowerStationElements";
import MapPowertation from "./MapPowerStation/MapPowerstation";
import UTRDataPlant from "./UTRPlant/UTRDataPlant";
import ErrorBoundaryChart from "../../../Shared/Error/ErrorBoudaryChart";
import React from "react";

export default function DashboardPowerPlantLayoutPage(){
    const {selectedPowerPlant} = useAppSelector((state) => state.dashboard);
    return(
        <>
            {selectedPowerPlant.id ? (
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12} xl={12}>
                        <ErrorBoundaryChart chartName={"Totalizadores"}>
                            <TotalizerPlant powerStationId={selectedPowerPlant.id} />
                        </ErrorBoundaryChart>
                    </Grid>
                    <Grid item container xs={12} spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                            <GenerationPlant/>
                            {/*<EnvironmentalDataPlant powerPlantId={selectedPowerPlant.id} />*/}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                            <PowerStationElements powerStationId={selectedPowerPlant.id} />
                            {/*<EnvironmentalDataPlant powerPlantId={selectedPowerPlant.id} />*/}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                            <WeatherStationPlant powerPlantId={selectedPowerPlant.id} />
                        </Grid>
                    </Grid>
                        {/*<Grid item xs={12} sm={12}>*/}
                        {/*    <UTRDataPlant powerPlantId={selectedPowerPlant.id} />*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12} sm={12} spacing={2}>*/}
                        {/*    <MapPowertation powerStationId={selectedPowerPlant.id} />*/}
                        {/*</Grid>*/}
                    <Grid item xs={12}>
                        <MapPowertation powerStationId={selectedPowerPlant.id} />
                    </Grid>
                </Grid>
            ) : null}
        </>
    );


}
