import {QueryClient, QueryClientProvider} from "react-query";
import * as React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {SystemRoutes} from "../../../Utils/RouteUtils";
import {HeaderMenu} from "../HeaderMenu";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from "@mui/material/Box";
import {ACard} from "@atiautomacao/ati-ui-library";
import {useEffect, useState} from "react";
import {PowerStationFormInfoPage} from "./components/PowerStationFormInfoPage";
import {PowerStationFormParamsPage} from "./components/PowerStationFormParamsPage";
import {PowerStationFormExpectedPage} from "./components/PowerStationFormExpectedPage";
import{PowerStationFormEditTeleObjectPage} from "./components/PowerStationFormEditTeleObjectPage"
import {
    FindPowerStationByIdReactQuery,
    FindNumerOfEquipmentBySkidReactQuery
} from "./PowerStationReactQuery";
import {
    PowerStationData,
    PowerStationType,
    Skid,
    PowerStationParams,
    ExpectedDataProps, City, State
} from "../../../Shared/Types/PowerStation"
import {PowerStationFormSkidPage} from "./components/PowerStationFormSkidPage";
import axios from "axios";
import {useSnackbar} from "notistack";
import {parse} from "date-fns";
import {ptBR} from "date-fns/locale";
import {validateLatitude, validateLongitude} from "../../../Utils/RegexUtils";
import {TeleObjectData} from "../../Dashboard/Skid/EquipmentDetailsSkid/EquipmentDetailsSkid";
import {TeleObject} from "../../Settings/NotificationGroup/NotificationGroupFormPage";

export const sortSkid = (data:Array<Skid>) => {
    return data.sort((a, b) => a.name.localeCompare(b.name))
}
export const PowerStationFormPage = () => {
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const id = location.state?.id as number;
    const [powerStationId, setPowerStationId] = useState<number | null>(null);
    const [powerStationDetailsId, setPowerStationDetailsId] = useState<number | null>(null);
    const navigate = useNavigate();
    const mutationFindById = FindPowerStationByIdReactQuery();
    const mutationFindNumberOfEquipmentBySkid = FindNumerOfEquipmentBySkidReactQuery();
    const [value, setValue] = useState('data');
    const [paramsData, setParamsData] = useState<PowerStationParams>({
        powerCapacityNominal: 0,
        maxPower: 0,
        numberOfUG: 0,
        expectedGenerateEnergy: 0,
        expectedYield: 0,
        expectedPerformanceRatio: 0,
        expectedIrradiance: 0,
        operationStartedAt: null,
        operationMode: "L",
        meterInterval: 0,
        inverterInterval: 0,
        meterConst: 0,
        moduleTempCoef: 0
    })
    const [skidData, setSkidData] = useState<Array<Skid>>([]);
    const [teleObjectData, setTeleObjectData] = useState<any>([])
    const [powerStationData, setPowerStationData] = useState<PowerStationData>({
        id: null,
        name: "",
        shortName: "",
        state:{
            id: -1,
            name: "Selecione o estado"
        },
        city: null,
        zip:"",
        lat: 0,
        long: 0,
        adminName: "",
        adminEmail: "",
        adminPhone: "",
        enabled: true
    })
    const [expectedDataList, setExpectedDataList] = useState<Array<ExpectedDataProps>>([])
    const [expectedDataDeleted, setExpectedDataDeleted] = useState<Array<number>>([])
    const [skidsDeleted, setSkidsDeleted] = useState<Array<number>>([])
    const [numberOfEquipmentBySkid, setNumberOfEquipmentBySkid] = useState<Array<any>>([])
    async function save(data:any, apiUrl: string)  {
        try {
            const response = await axios.post(apiUrl, data);
            return response.data;

        } catch (error) {
            console.log(error)
            throw error;
        }
    }

    async function update(data:any, apiUrl: string)  {
        try {
            const response = await axios.put(apiUrl, data);
            return response.data;

        } catch (error:any) {
            throw error;
        }
    }

    async function getById(id: number, apiUrl: string) {
        try {
            const response = await axios.get(`${apiUrl}/${id}`);
            return response.data;
        } catch (error: any) {
            throw error;
        }
    }

    async function deleteAllById(data:any, apiUrl: string)  {
        await save(data, apiUrl)
    }

    function hasEmptyStringAttributes<T>(data: T): boolean {
        for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                const value = data[key as keyof T];
                if (typeof value === 'string' && value.trim() === '') {
                    return true;
                }
            }
        }
        return false;
    }


    useEffect(() => {
        if(id){
            mutationFindById.mutate(id, {
                onSuccess: (data) => {
                    const result = data?.data as PowerStationType
                    setPowerStationId(result.id)
                    setPowerStationDetailsId(result.powerStationDetails.id)
                    let city : City = {
                        id: result.powerStationDetails.cityEntity.id,
                        name: result.powerStationDetails.cityEntity.name
                    }
                    let state : State = {
                        id: result.powerStationDetails.cityEntity.state.id,
                        name: result.powerStationDetails.cityEntity.state.name
                    }
                    let powerStation:PowerStationData = {
                        id: result.id,
                        name: result.name,
                        shortName: result.shortName,
                        state: state,
                        city: city,
                        zip:result.powerStationDetails.zipCode,
                        lat: result.powerStationDetails.latitude,
                        long: result.powerStationDetails.longitude,
                        adminName: result.powerStationDetails.adminName,
                        adminEmail: result.powerStationDetails.adminEmail,
                        adminPhone: result.powerStationDetails.phone1,
                        enabled: result.enabled
                    };

                    let powerStationParams: PowerStationParams = {
                        powerCapacityNominal: result.powerCapacityNominal,
                        maxPower: result.maxPower,
                        numberOfUG: result.numberOfUG,
                        expectedGenerateEnergy: result.expectedGenerateEnergy,
                        expectedYield: result.expectedYield,
                        expectedPerformanceRatio: result.expectedPerformanceRatio,
                        expectedIrradiance: result.expectedIrradiance,
                        operationStartedAt: result.operationStartedAt,
                        operationMode: result.syncOperationMode,
                        inverterInterval: result.inverterInterval,
                        meterInterval: result.meterInterval,
                        meterConst: result.meterConst,
                        moduleTempCoef: result.moduleTemperatureCoefficient
                    }

                    setPowerStationData(powerStation)
                    const sortedSkids = sortSkid(result.skidList)
                    setSkidData(sortedSkids)
                    setParamsData(powerStationParams)
                }
            });

        }
    }, []);

    useEffect(() => {
        if(powerStationId != null){
            mutationFindNumberOfEquipmentBySkid.mutate(powerStationId, {
                onSuccess:(data:any)=>{
                    setNumberOfEquipmentBySkid(data.data)
                }
            })
            getById(id,'/api/expected-data/find-all').then(
                response=>{

                    const expectedData = response.data.map((exp:ExpectedDataProps) => {
                        return {
                            ...exp,
                            date: parse(exp.date.toString(), 'yyyy-MM-dd', new Date(),{locale: ptBR})
                        }
                    })
                    setExpectedDataList(expectedData)
                }
            )
        }
    }, [powerStationId]);

    useEffect(() => {
        if(skidData.length > 0){
            let skids:Array<Skid> = [...skidData]
            const skidMap = new Map<number, number>();
            numberOfEquipmentBySkid.forEach(skid => {
                skidMap.set(skid.skidId, skid.numberOfEquipment);
            });

            const newSkidData = skids.map(skid => {
                if (skid.id != null && skidMap.has(skid.id)) {
                    skid.numberOfEquipment = skidMap.get(skid.id) ?? 0;
                }else{
                    skid.numberOfEquipment = 0
                }
                return skid;
            });
            const sortedSkids = sortSkid(newSkidData)
            setSkidData(sortedSkids)
        }
    }, [numberOfEquipmentBySkid]);

    const handleChange = (event:any, newValue:any) => {
        setValue(newValue);
    };
    const onSaveInfo = (infoData: PowerStationData) => {
        setPowerStationData(infoData);
    }

    const onSaveParams = (paramsData: any) => {
        const params = {
            ...paramsData,
            numberOfUG: skidData.length
        }
        setParamsData(params)
    }

    const onSaveExpectedData = (expectedData: Array<ExpectedDataProps>) => {
        setExpectedDataList(expectedData)
    }

    const onSaveSkid = (skidData: Array<Skid>) => {
        setSkidData(skidData)
    }

    const onSaveTeleObject = (teleObjectData: any) => {
        console.log('Dados do TeleObject recebidos no Pai:', teleObjectData);
        setTeleObjectData(teleObjectData);
    };

    useEffect(() => {
        console.log("teleObjectData: dentro do pai", teleObjectData)
    }, [teleObjectData]);

    const onDeleteSkid = (skidIds: Array<number>) => {
        setSkidsDeleted(skidIds)
    }

    const onDeleteExpectedData = (deletedIds: Array<number>) => {
        setExpectedDataDeleted(deletedIds)
    }
    const saveOrUpdate = () => {

        if(!hasEmptyStringAttributes(powerStationData) && skidData.length > 0 && paramsData.operationStartedAt != null) {
            if(!validateLatitude(powerStationData.lat.toString()) || !validateLongitude(powerStationData.long.toString())){
                enqueueSnackbar("Preencha os campos latitude e longitude com dados válidos.", {variant: 'error'})
                return
            }
            const powerStationDTO = {
                powerStation: {
                    name: powerStationData.name,
                    syncOperationMode: paramsData.operationMode,
                    shortName: powerStationData.shortName,
                    powerStationDetails: {
                        zipCode: powerStationData.zip,
                        latitude: powerStationData.lat,
                        longitude: powerStationData.long,
                        adminName: powerStationData.adminName,
                        adminEmail: powerStationData.adminEmail,
                        phone1: powerStationData.adminPhone,
                        cityEntity: {
                            id: powerStationData?.city?.id,
                            state: {
                                id: powerStationData.state.id
                            }
                        }
                    },
                    powerCapacityNominal: paramsData.powerCapacityNominal,
                    maxPower: paramsData.maxPower,
                    numberOfUG: skidData.length,
                    expectedGenerateEnergy: paramsData.expectedGenerateEnergy,
                    expectedYield: paramsData.expectedYield,
                    expectedPerformanceRatio: paramsData.expectedPerformanceRatio,
                    expectedIrradiance: paramsData.expectedIrradiance,
                    operationStartedAt: paramsData.operationStartedAt,
                    inverterInterval: paramsData.inverterInterval,
                    meterInterval: paramsData.meterInterval,
                    meterConst: paramsData.meterConst,
                    moduleTemperatureCoefficient: paramsData.moduleTempCoef,
                    skidList: skidData,
                    brokerList: [],
                    enabled: powerStationData.enabled
                }
            }
            if (powerStationId === null) {
                handleSave(powerStationDTO)
            } else {
                handleUpdate(powerStationDTO)
            }
        }else{
            enqueueSnackbar("Verifique os campos obrigatórios antes de tentar salvar.", {variant: 'error'})
        }
    }
    const handleSave = async(powerStationDTO:any) => {
        save(powerStationDTO, "api/power-station").then(
            response => {
                const newExpectedDataList:ExpectedDataProps[] = expectedDataList.map(expected => {
                    return {
                        ...expected,
                        powerStation:{
                            id: response.data.powerStation.id
                        }
                    }
                })
                save(newExpectedDataList, "api/expected-data").then(
                    response => {
                        console.log(response)
                        enqueueSnackbar("Usina salva com sucesso.", {variant: 'success'})
                        navigate(SystemRoutes.SETTINGS_POWER_PLANT)
                    }
                )
            }
        ).catch(
            error=> {
                console.log(error)
                enqueueSnackbar("Error ao salvar usina.", {variant: 'error'})
            }
        )
    }

    const handleUpdate = async(powerStationDTO:any) => {
        const newDTO = {
            powerStation: {
                ...powerStationDTO.powerStation,
                powerStationDetails:{
                    id: powerStationDetailsId,
                    ...powerStationDTO.powerStation.powerStationDetails
                },
                id: powerStationId,
                expectedDataList: expectedDataList.map(expected => {
                    return {
                        ...expected,
                        powerStation:{
                            id: powerStationId
                        }
                    }
                })
            }
        }

        try {
            if(skidsDeleted.length > 0) {
                await deleteAllById(skidsDeleted, "api/skid/delete-all")
            }
        }catch (error:any){
            const status = error.response.status
            if(status === 409){
                enqueueSnackbar("Existem dados vinculados as skids que voce tentou remover, por favor contactar o administrador. ", {variant: 'error'})
            }else{
                enqueueSnackbar("Error ao remover skids.", {variant: 'error'})
            }
            navigate(SystemRoutes.SETTINGS_POWER_PLANT)
            return;
        }

        try{
            if(expectedDataDeleted.length > 0){
                await deleteAllById(expectedDataDeleted,"/api/expected-data/delete-all")
            }
        }catch (error){
            enqueueSnackbar("Error ao remover dados esperados.", {variant: 'error'})
            navigate(SystemRoutes.SETTINGS_POWER_PLANT)
            return;
        }

        update(newDTO, "api/power-station").then(
            response => {
                const newExpectedDataList:ExpectedDataProps[] = expectedDataList.map(expected => {
                    return {
                        ...expected,
                        powerStation:{
                            id: response.data.powerStation.id
                        }
                    }
                })
                return update(newExpectedDataList, "api/expected-data").then(response => {
                    enqueueSnackbar("Usina atualizada com sucesso.", { variant: 'success' });
                    console.log(response);

                    return update(teleObjectData, "api/teleobject").then(response => {
                        console.log(response);
                        navigate(SystemRoutes.SETTINGS_POWER_PLANT);
                    });
                });
            }
        ).catch(
            error => {
                const status = error.response.status
                if(status === 409){
                    enqueueSnackbar("Existem dados vinculados a usina que voce tentou editar, por favor contactar o administrador. ", {variant: 'error'})
                }else{
                    enqueueSnackbar("Error ao remover skids.", {variant: 'error'})
                }
                navigate(SystemRoutes.SETTINGS_POWER_PLANT)
            }
        )
    }

    const [content, setContent] = useState<JSX.Element>();
    useEffect(() => {
        if(value === 'data'){
            setContent(<PowerStationFormInfoPage data={powerStationData} onSaveInfo={onSaveInfo}/>)
        }else if(value === 'params'){
            setContent(<PowerStationFormParamsPage onSaveParams={onSaveParams} data={paramsData}/>)
        }
        else if(value === 'expected'){
            setContent(<PowerStationFormExpectedPage onDelete={onDeleteExpectedData} initData={expectedDataList} deletedIds={expectedDataDeleted} onSaveParams={onSaveExpectedData} powerStationId={id ?? null}/>)
        }
        else if(value === 'skid'){
            setContent(<PowerStationFormSkidPage onDeleteSkid={onDeleteSkid} data={skidData} deletedIds={skidsDeleted} onSaveSkid={onSaveSkid}/>)
        }
        else if(value === 'teleObject'){
            setContent(<PowerStationFormEditTeleObjectPage powerStationId={id} powerStationName={powerStationData.name} onSaveTeleObject={onSaveTeleObject}/>)
        }
    }, [value, mutationFindById.data]);
    return (
        <>
            <HeaderMenu isOnSave={true} handleSave={saveOrUpdate} systemRoutes={SystemRoutes.SETTINGS_POWER_PLANT}/>
            <Box style={{paddingTop: 64}}>
                        <ACard styleProps={{
                            headerStyle: {padding: 0}
                        }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                            >
                                <Tab
                                    value="data"
                                    label="Dados"
                                    wrapped
                                />
                                <Tab value="expected" label="Esperados" />
                                <Tab value="params" label="Parâmetros" />
                                <Tab value="skid" label="Skids" />
                                <Tab value="teleObject" label="Tele Objetos" />
                            </Tabs>
                            {content != null && content}
                        </ACard>
            </Box>
        </>
    )
}

export function PowerStationHomePageForm() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <PowerStationFormPage/>
        </QueryClientProvider>
    )
}
