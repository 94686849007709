import { FunctionComponent, useEffect, useState } from "react";
import { RootState } from "../../../../Config/Store";
import { useAppDispatch, useAppSelector } from "../../../../Config/Hooks";
import {clear, getEntity, WeatherStation} from "./WeatherStationPlantReducer";
import {GaugeSeriesOption} from "./Gauge/Gauge";
import { Box, Grid } from "@mui/material";
import {useEquipmentWithoutSkidNavigation} from "../../../../Shared/Hooks/useEquipmentWithoutSkidNavigation";
import {ACard, Skeleton, ProgressCard, BarInterval} from "@atiautomacao/ati-ui-library"
import useInterval from "../../../../Shared/Hooks/useInterval";
import { Container } from "../../../../Shared/Components/AlarmTotalizers/AlarmTotalizers.theme";
import { truncateNumber } from "../../../../Utils/NumberUtil";



const WeatherStationPlant: FunctionComponent<{powerPlantId: any}> = ({powerPlantId}) => {

    const entities : WeatherStation = useAppSelector((state: RootState) => state.weatherStationPlant.entity);
    const { loading  } = useAppSelector((state: RootState) => state.weatherStationPlant);
    const dispatch = useAppDispatch();
    const navigateToEquipment = useEquipmentWithoutSkidNavigation();
    const [seriesGauge, setSeriesGauge] = useState<Array<{title: string, seriesGauge: GaugeSeriesOption}>>([]);

    const extractWeather = () => {
        // Rule of intervals at 100%
        const TICK = 3;
        const ONEINTERVAL = ((100 / TICK) / 100).toFixed(2);
        const TWOINTERVAL = (1 - parseFloat(ONEINTERVAL)).toFixed(2);

        // Definindo valores padrão para os componentes
        const defaultWeather: Array<{ title: string, seriesGauge: GaugeSeriesOption }> = [
            {
                title: 'POA',
                seriesGauge: {
                    value: 0,
                    unity: "W/m²",
                    intervalsTicks: [
                        [1, '#f0a12b'],
                    ],
                    max: 1000,
                    barInterval: {min: {value: 0, color:"#a4f657"}, mid: {value: 0, color:"#a4f657"}, max: {value: 0, color:"#a4f657"}}
                },
            },
            {
                title: 'GHI',
                seriesGauge: {
                    value: 0,
                    unity: "W/m²",
                    intervalsTicks: [
                        [1, '#f0a12b'],
                    ],
                    max: 1200,
                    barInterval: {min: {value: 0, color:"#a4f657"}, mid: {value: 0, color:"#a4f657"}, max: {value: 0, color:"#a4f657"}}
                },
            },
            {
                title: 'Temperatura do módulo (ºC)',
                seriesGauge: {
                    value: 0,
                    intervalsTicks: [
                        [ONEINTERVAL, '#a4f657'],
                        [TWOINTERVAL, '#f0a12b'],
                        [1, '#e02222'],
                    ],
                    unity: 'ºC',
                    max: 80,
                    barInterval: {min: {value: 26, color: "#FFD700"}, mid: {value: 53, color:"#a4f657"}, max: {value: 80, color: "#FF4500"}}
                },
            },
            {
                title: 'Temperatura do Ar (ºC)',
                seriesGauge: {
                    value: 0,
                    intervalsTicks: [
                        [ONEINTERVAL, '#a4f657'],
                        [TWOINTERVAL, '#f0a12b'],
                        [1, '#e02222'],
                    ],
                    unity: 'ºC',
                    max: 80,
                    barInterval: {min: {value: 26, color: "#a4f657"}, mid: {value: 53, color:"#FFD700"}, max: {value: 80, color: "#FF4500"}}
                },
            },
            {
                title: 'Velocidade do Vento (m/s)',
                seriesGauge: {
                    value: 0,
                    intervalsTicks: [
                        [ONEINTERVAL, '#a4f657'],
                        [TWOINTERVAL, '#f0a12b'],
                        [1, '#e02222'],
                    ],
                    unity: 'm/s',
                    max: 14,
                    barInterval: {min: {value: truncateNumber(Number(14/3),2), color: "#a4f657"}, mid: {value: 11, color: "#FFD700"}, max: {value: 14, color: "#FF4500"}}
                },
            },
            {
                title: 'Umidade do Ar (%)',
                seriesGauge: {
                    value: 0,
                    intervalsTicks: [
                        [1, '#f0a12b'],
                    ],
                    unity: '%',
                    max: 100,
                    barInterval: {min: {value: 0, color:"#a4f657"}, mid: {value: 0, color:"#a4f657"}, max: {value: 0, color:"#a4f657"}}
                },
            },


        ];

        let weather = [...defaultWeather];
        if(entities?.values?.length > 0){
            for (let entity of entities.values) {
                if (entity.name.includes("POA")) {
                    weather[0].seriesGauge.value = Math.trunc(entity.value);
                } else if (entity.name.includes("GHI")) {
                    weather[1].seriesGauge.value = Math.trunc(entity.value);
                } else if (entity.name.includes("Temperatura do Módulo")) {
                    weather[2].seriesGauge.value = Math.trunc(entity.value);
                } else if (entity.name.includes("Temperatura do Ar")) {
                    weather[3].seriesGauge.value = Math.trunc(entity.value);
                } else if (entity.name.includes("Velocidade do Vento")) {
                    weather[4].seriesGauge.value = Math.trunc(entity.value);
                } else if (entity.name.includes("Umidade do Ar")) {
                    weather[5].seriesGauge.value = Math.trunc(entity.value);
                }
            }
        }
        if(weather.length > 0) {
            setSeriesGauge(weather);
        } else {
            setSeriesGauge(defaultWeather);
        }
    }

    useEffect(() => {
        dispatch(clear())
        dispatch(getEntity(powerPlantId));
    }, [powerPlantId]);

    useInterval(() => {
        dispatch(clear())
        dispatch(getEntity(powerPlantId));
    }, 60000) // 5 minutes

    useEffect(() => {
        extractWeather();
    }, [entities]);

    const handleNodeClick = (): void => {
        if(entities){
            navigateToEquipment(entities.id);
        }
    }


    if (loading) {
        return (
            <Box style={{paddingBottom: 10}}>
                <ACard
                    title={"Estação Meteorológica"}
                >
                    <Container data-testid="loading-skeleton">
                    <Grid container spacing={1.5} justifyContent="center">
                                {Array.from({ length: 6 }).map((_, index) => (
                                    <Grid key={index.valueOf()} item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={12} sm={4} md={4} lg={12} xl={12}>
                                        <Skeleton key={index.valueOf()} animation="wave" height={100} variant="rectangular" width={220} />
                                    </Grid>
                                ))}
                            </Grid>
                    </Container>
                </ACard>
            </Box>
        );
    }

    return (
        <ACard

            onClick={handleNodeClick}
            cursorType={'pointer'}
            title="Estação Meteorológica"
        >
            {
                    <Box sx={{ cursor: 'pointer' }} onClick={handleNodeClick}>
                        <Grid container spacing={1.5} justifyContent="center">
                                        {/*<Gauge series={element.seriesGauge} title={element.title} />*/}
                                        {seriesGauge.map((row,index) => {
                                            return (
                                                <Grid key={index.valueOf()} item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={12} sm={4} md={4} lg={12} xl={12}>
                                                    <ProgressCard
                                                        value={row.seriesGauge.value}
                                                        maxValue={row.seriesGauge.max}
                                                        intervals={row.seriesGauge.barInterval}
                                                        unit={row.seriesGauge.unity}
                                                        label={row.title}
                                                        fontSize={16}
                                                        valueFontSize={15}
                                                        showProgressBar={true}
                                                        background="#F9F9F9"
                                                        textColor="#000000"
                                                        width={220}
                                                        boldLabel={true}
                                                        isPercentValue={false}
                                                    />
                                                </Grid>
                                            )
                                        })}
                        </Grid>
                    </Box>
            }
        </ACard>
    );

}

export default WeatherStationPlant;