import React, {useCallback, useEffect, useState} from "react";
import {Box, Button, Grid, Tooltip, useTheme} from "@mui/material";
import {ADataGrid, ADataGridColumn, ADataGridFilter, PagedSearchParams} from "@atiautomacao/ati-ui-library";
import {
    ExpectedDataSearchReactQuery
} from "../PowerStationReactQuery";
import {extractFiltersFromColumns} from "../../../../Utils/DataUitils";
import {ExpectedDataProps} from "../../../../Shared/Types/PowerStation";
import IconButton from "@mui/material/IconButton";
import {AddCircle, Delete, Edit} from "@mui/icons-material";
import {truncateNumber} from "../../../../Utils/NumberUtil";
import {format, isValid, parse} from "date-fns";
import {ParamsModal} from "./modal/ParamsModal";
import ConfirmDialog from "../../../../Shared/Components/ConfirmDialog";
import {LocalDateTimeFormatISO, validateDateFormatISO} from "../../../../Utils/DateFormatPatternUtils";
import {ptBR} from "date-fns/locale";

const initColumns = [
    {
        name: 'dateTime',
        label: 'data',
        align: 'center',
        visibility: true,
        minWidth: 50,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {name: 'dateTime', condition: 'starts-at', value: '', sort: 'asc'}

    },
    {
        name: 'energy',
        label: 'energia esperada(MWh)',
        align: 'left',
        visibility: true,
        minWidth: 100,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {name: 'energy', condition: 'equals', value: '', sort: ''}

    },
    {
        name: 'irradiance',
        label: 'irradiação esperada(kWh/m)',
        align: 'left',
        visibility: true,
        minWidth: 100,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {name: 'irradiance', condition: 'equals', value: '', sort: ''}

    },
    {
        name: 'prExpected',
        label: 'PR esperado(%)',
        align: 'left',
        visibility: true,
        minWidth: 100,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {name: 'performanceRatio', condition: 'equals', value: '', sort: ''}
    }
] as ADataGridColumn [];

export type ActionConfig = {
    actionName: string;
    disabled: boolean;
};

interface Props {
    onSaveParams?: any,
    onDelete?:any,
    initData: Array<ExpectedDataProps>,
    deletedIds: Array<number>
    powerStationId: number | null
}

export interface RowExpectedData {
    id: number | null;
    dateTime: string;
    energy: number;
    irradiance: number;
    prExpected: number;
    actionsConfig?: Array<ActionConfig>
    index?:number | null;
}

export const getDate = (date: string): Date => {
    const [day, month, year] = date.split('/');
    const dateString = `${year}-${month}-${day}`
    return parse(dateString, 'yyyy-MM-dd', new Date(),{locale: ptBR});
};

export const PowerStationFormExpectedPage = ({onSaveParams, onDelete, initData, deletedIds, powerStationId}: Props) => {
    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(initColumns), 0, 50));

    const theme = useTheme();

    const enable = powerStationId != null
    const { data, isSuccess } = ExpectedDataSearchReactQuery(pagedSearchParams, enable);
    const [totalOfRecords, setTotalOfRecords] = useState(0);
    const [rows, setRows] = useState<Array<RowExpectedData>>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [expectedData, setExpectedData] = useState<Array<ExpectedDataProps>>([])
    const [editData, setEditData] = useState<any>(null);
    const [idsDeleted, setIdsDeleted] = useState<Array<number>>(deletedIds);
    const [isClear, setIsClear] = useState(false)
    const [rowToDelete, setRowToDelete] = useState<any>()
    const [isEdit, setIsEdit] = useState(false)

    const formatISOData = (date: string) => {
        if(isValid(new Date(date))){
            return LocalDateTimeFormatISO(date)
        }
        const dateTime =  validateDateFormatISO(date);
        if(isValid(new Date(dateTime))){
            return LocalDateTimeFormatISO(dateTime)
        }
        return "";
    }

    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        let newFilter = dataGridFilters.filter(filter => filter.name !== 'performanceRatio' && filter.name !== 'dateTime')
        newFilter.push({
            condition: dataGridFilters[3].condition,
            name: dataGridFilters[3].name,
            sort: dataGridFilters[3].sort,
            value: dataGridFilters[3].value !== '' ? (Number(dataGridFilters[3].value)/100).toString() : ''
        })
        newFilter.push({
            condition: dataGridFilters[0].condition,
            name: dataGridFilters[0].name,
            sort: dataGridFilters[0].sort,
            value: dataGridFilters[0].value !== '' ? formatISOData(dataGridFilters[0].value) : ''
        })
        let newPageParams: PagedSearchParams = new PagedSearchParams(newFilter, page, pageSize);

        if(powerStationId != null){
            newPageParams.filters.push({name: 'powerStationId', condition: 'equals', value: powerStationId.toString(), sort: 'none'},)
        }
        setPagedSearchParams(
            newPageParams
        );
    }, []);

    useEffect(() => {
        if(isSuccess) {
            setIsClear(false)
            setTotalOfRecords(data?.totalOfRecords);
            if(data?.data){
                let expectedDataRows = data.data.map((powerStation: ExpectedDataProps) => {
                    const newDate = parse(powerStation.date.toString(), 'yyyy-MM-dd', new Date(),{locale: ptBR})
                    return {
                        id: powerStation.id,
                        energy: truncateNumber(powerStation.energy, 2),
                        dateTime: format(newDate, 'dd/MM/yyyy',{locale: ptBR}),
                        prExpected: truncateNumber(powerStation.performanceRatio, 2) * 100,
                        irradiance: truncateNumber(powerStation.irradiance, 2),
                        actionsConfig: [
                            {actionName: "edit", disabled: false},
                            {actionName: "delete", disabled: false},
                        ]
                    }
                });
                setRows(expectedDataRows);
            }
        }
    }, [data?.data]);

    useEffect(() => {
        if(initData){
            setIsClear(true)
            let index = -1;
            setRows(initData.map(expData => {
                if(expData.id == null){
                    index++;
                }
                return ({
                    id: expData.id ?? null,
                    energy: expData.energy,
                    dateTime: format(expData.date, 'dd/MM/yyyy',{locale: ptBR}),
                    prExpected: expData.performanceRatio * 100,
                    irradiance:expData.irradiance,
                    actionsConfig: [
                        {actionName: "edit", disabled: false},
                        {actionName: "delete", disabled: false},
                    ],
                    index: expData.id ? null : index
                })
            }))
        }
    }, []);

    useEffect(() => {
        onSaveParams(expectedData)
    }, [expectedData]);

    useEffect(() => {
        setExpectedData(rows.map(row => {
            return ({
                id: row.id,
                energy: row.energy,
                date: getDate(row.dateTime),
                irradiance: row.irradiance,
                performanceRatio: row.prExpected/100
            })
        }))
    }, [rows]);

    const handleCloseModal = () => {
        setEditData(null)
        setIsEdit(false)
        setOpenDialog(false)
    }


    const handleAddItem = (data:any) => {
        if(data.date != null){
            const index = rows.filter(row => row.id == null && row.index != null).length
            const newItem = [{
                id: data.id ? data.id : null,
                energy: data.expectedEnergy,
                irradiance: data.expectedIrradiance,
                prExpected: data.expectedPr,
                dateTime: format(data.date, 'dd/MM/yyyy'),
                actionsConfig: [
                    {actionName: "edit", disabled: false},
                    {actionName: "delete", disabled: false},
                ],
                index: data.id ? null : index
            }]
            if(data.id != null){
                const newRows = rows.filter(row => row.id !== data.id)
                setRows([...newRows, ...newItem])
            }else{
                setRows([...rows, ...newItem])
            }
        }
        setOpenDialog(false)
    }

    const handleEditItem = (data: any) => {
        console.log("data: ", data)
        if(data.date != null){
            if(data.id != null){
                const newRows = rows.filter(row => row.id !== data.id)
                const newItem = [{
                    id: data.id ? data.id : null,
                    energy: data.expectedEnergy,
                    irradiance: data.expectedIrradiance,
                    prExpected: data.expectedPr,
                    dateTime: format(data.date, 'dd/MM/yyyy', {locale: ptBR}),
                    actionsConfig: [
                        {actionName: "edit", disabled: false},
                        {actionName: "delete", disabled: false},
                    ],
                    index: null
                }]
                setRows([...newRows, ...newItem])
            }else{
                const newRows = rows.filter(row => row.index !== data.index)
                const newItem = [{
                    id: null,
                    energy: data.expectedEnergy,
                    irradiance: data.expectedIrradiance,
                    prExpected: data.expectedPr,
                    dateTime: format(data.date, 'dd/MM/yyyy', {locale: ptBR}),
                    actionsConfig: [
                        {actionName: "edit", disabled: false},
                        {actionName: "delete", disabled: false},
                    ],
                    index: data.index
                }]
                setRows([...newRows, ...newItem])
            }
        }
        setOpenDialog(false)
        setIsEdit(false)
        setEditData(null)
    }

    const handleOnEdit = (value:any) => {
        setEditData({
            id: value.id,
            energy: value.energy,
            irradiance: value.irradiance,
            prExpected: value.prExpected,
            dateTime: getDate(value.dateTime),
            index: value.index
        })
        setOpenDialog(true)
        setIsEdit(true)
    }

    useEffect(() => {
        onDelete(idsDeleted)
    }, [idsDeleted]);

    const handleDialogConfirm = async () => {
        if(rowToDelete.id != null){
            const newRows = rows.filter(row => row.id == null || row.id != rowToDelete.id)
            setIdsDeleted(prevState => [...prevState, rowToDelete.id])
            setRows(newRows)
        }else{
            const newRows = rows.filter(row => row.index == null || row.index != rowToDelete.index).map((row, index) => {
                    return ({
                        ...row,
                        index: row.id ? null : index
                    })
                }
            )
            setRows(newRows)
        }
        setRowToDelete(null)
        handleDialogToggle();
    }

    const handleDialogClose = () => {
        handleDialogToggle();
    }

    const handleDialogToggle = () => {
        setOpenDeleteDialog(!openDeleteDialog);
    }

    const handleOnDelete = (value:any) => {
        setOpenDeleteDialog(true);
        setRowToDelete(value)
    }

    return (
        <Box style={{paddingTop: 20}}>
            <Grid container spacing={2} alignItems="center" sx={{paddingBottom: 2}}>
                <Grid item>
                    <Button
                        size={"small"}
                        variant="contained"
                        onClick={() => setOpenDialog(true)}
                        endIcon={<AddCircle/>}>
                        Adicionar
                    </Button>
                </Grid>
            </Grid>
                <ADataGrid
                    hideSelection={true}
                    columns={initColumns}
                    rows={rows}
                    page={pagedSearchParams.page}
                    rowsPerPage={pagedSearchParams.size}
                    loading={false}
                    totalOfRecords={totalOfRecords}
                    onFiltersChange={handleOnFiltersChange}
                    onClearSelectedTempRows={isClear}
                    actions={
                        <>
                            <IconButton name="edit" size={"small"} aria-label="Edit" onClick={(value) => handleOnEdit(value)}>
                                <Tooltip title="Editar">
                                    <Edit/>
                                </Tooltip>
                            </IconButton>
                            <IconButton name="delete" size={"small"} aria-label="Delete" onClick={(value) => handleOnDelete(value)}>
                                <Tooltip title="Excluir">
                                    <Delete />
                                </Tooltip>
                            </IconButton>
                        </>
                    }
                />
            <ConfirmDialog
                title={'Deletar'}
                description={'Deseja deletar esse dado?'}
                open={openDeleteDialog}
                handleConfirm={handleDialogConfirm}
                handleClose={handleDialogClose}
            />
            <ParamsModal open={openDialog} data={editData} onCloseModal={handleCloseModal} onSave={isEdit ? handleEditItem : handleAddItem}/>
        </Box>
    )
}