import {Grid} from "@mui/material";
import {RootState} from "../../../Config/Store";
import {useAppSelector} from "../../../Config/Hooks";
import GenerationChart from "./GenerationChart/GenerationChart";
import PowerVSIrradianceChart from "./PowerVSIrradianceChart/PowerIrradianceChart";
import PowerPUForInvertersChart from './PowerPUForInvertersChart/PowerPUForInvertersChart';
import CapacityFactorChart from "./CapacityFactorVSPowerChart/CapacityFactorVSPower";
import GenerationPRChartPlant from "./GenerationPRChartPlant/GenerationPRChartPlant";
import ErrorBoundaryChart from "../../../Shared/Error/ErrorBoudaryChart";
import GenerationRateAndComparisonSkids from "./GenerationRateAndComparisonSkidsChart/GenerationRateAndComparisonSkids";
import PowerStationInformation from "./PowerStationInformation/PowerStationInformation";
import {QueryClient, QueryClientProvider} from "react-query";
import InstantData from "./InstantData/InstantData";

export default function DashboardPowerPlantChartsPage() {

    const {selectedPowerPlant} = useAppSelector((state: RootState) => state.dashboard);
    const queryClient = new QueryClient();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12} xl={7}>
                <QueryClientProvider client={queryClient}>
                    <PowerStationInformation powerStation={selectedPowerPlant} />
                </QueryClientProvider>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={5}>
                <ErrorBoundaryChart chartName={"Dados instantâneos"}>
                    <QueryClientProvider client={queryClient}>
                        <InstantData powerPlant={selectedPowerPlant}/>
                    </QueryClientProvider>
                </ErrorBoundaryChart>
            </Grid>

            <Grid item xs={12} md={6} xl={4}>
                <ErrorBoundaryChart chartName={"Potência PU"}>
                    <PowerPUForInvertersChart powerPlant={selectedPowerPlant} />
                </ErrorBoundaryChart>
            </Grid>

            <Grid item xs={12} md={6} xl={4}>
                <ErrorBoundaryChart chartName={"Potência x Irradiância"}>
                    <PowerVSIrradianceChart powerPlant={selectedPowerPlant} />
                </ErrorBoundaryChart>
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
                <ErrorBoundaryChart chartName={"Comparativo de Skids"}>
                    <GenerationRateAndComparisonSkids powerPlant={selectedPowerPlant.id}/>
                </ErrorBoundaryChart>
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
                <ErrorBoundaryChart chartName={"Energia Gerada"}>
                    <GenerationChart powerPlant={selectedPowerPlant} />
                </ErrorBoundaryChart>
            </Grid>
            {/*
            <Grid item xs={12} md={6} xl={4}>
                <ErrorBoundaryChart chartName={"Fator de Capacidade e Energia"}>
                    <CapacityFactorChart powerPlant={selectedPowerPlant.id} />
                </ErrorBoundaryChart>
            </Grid>
             */}
            <Grid item xs={12} md={6} xl={4}>
                <ErrorBoundaryChart chartName={"Performance Ratio (%)"}>
                    <GenerationPRChartPlant powerPlant={selectedPowerPlant.id}  />
                </ErrorBoundaryChart>
            </Grid>
        </Grid>
    );
}