import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { RootState } from '../../../../Config/Store';
import axios from 'axios';
import { serializeAxiosError } from "../../../../Config/Reducers/ReducerUtils";
import { LayoutTotalizerState } from "../../Skid/TotalizerPlantSkid/types/layoutTotalizerState";
import {LayoutTotalizer} from "../../Skid/TotalizerPlantSkid/types/layoutTotalizer";


const initialState: LayoutTotalizerState = {
    isLoading: false,
    errorMessage: null,
    totalizers: {
        generatedEnergy: 0,
        performanceRatio: 0,
        availability: 0,
        activePower: 0,
        capacityFactor: 0,
        irradiance: 0,
        yieldField: 0,
        dailyGeneratedEnergy: 0,
        dailyExpectedGeneratedEnergy: 0,
        monthlyGeneratedEnergy: 0,
        monthlyExpectedGeneratedEnergy: 0,
        yearlyGeneratedEnergy: 0,
        yearlyExpectedGeneratedEnergy: 0,
        totalGeneratedEnergy: 0,
        totalExpectedGeneratedEnergy: 0
    },
};



const apiUrl = 'api/power-station/generation/totalizers';


export const getEntity = createAsyncThunk<any, any, { rejectValue: any }>(
    'TotalizerPlant/fetch_entity',
    async ({ powerStationId }) => {
        return axios.get<LayoutTotalizer>(`${apiUrl}/${powerStationId}`);
    },
    { serializeError: serializeAxiosError }
);

// Slices
const TotalizerPlantReducer = createSlice({
    name: 'TotalizerPlantReducer',
    initialState,
    reducers: {
        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addMatcher(isFulfilled(getEntity), (state, action) => {
                return {
                    ...state,
                    isLoading: false,
                    totalizers: action.payload.data.data,
                }
            })
            .addMatcher(isPending(getEntity), state => {
                state.errorMessage = null;
                state.isLoading = true;
            })
            .addMatcher(isRejected(getEntity), state => {
                state.errorMessage = null;
                state.isLoading = false;
            })
    },
})

export const { reset } = TotalizerPlantReducer.actions;
export const selectTotalizerPlant = (state: RootState) => state.totalizerPlant;

// Reducer
export default TotalizerPlantReducer.reducer;