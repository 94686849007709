import React, {useCallback, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {QueryClient, QueryClientProvider} from "react-query";
import {
    Box,
    Button,
    Container,
    FormControlLabel,
    Grid,
    Modal,
    Radio,
    RadioGroup,
    Stack,
    Tooltip,
    Zoom
} from "@mui/material";
import AddCircle from "@mui/icons-material/AddCircle";
import {
    ACard,
    ADataGrid,
    ADataGridColumn,
    ADataGridFilter,
    FloatMenuButton,
    FloatMenuItem,
    PagedSearchParams, Skeleton
} from "@atiautomacao/ati-ui-library";
import {MoreVert} from "@mui/icons-material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExpand, faGear, faPrint} from "@fortawesome/free-solid-svg-icons";
import {useAppDispatch, useAppSelector} from "../../Config/Hooks";
import {RootState} from "../../Config/Store";
import {extractFiltersFromColumns} from "../../Utils/DataUitils";
import {getEntitiesOfGrid} from "./types/reducer/DashboardAnalysGridReducer";
import {DataItem,  UnityConverter} from "./types/DashboardNavigationAnalysisRowsOfGrid";


import {AnalysisEquipmentType} from "../../Shared/Types/AnalysisEquipmentType";
import {ChartConfigType} from "../../Shared/Types/chartConfigType";
import {ChartAxisType} from "../../Shared/Types/chartAxisType";
import {ChartSourceConfigType} from "../../Shared/Types/ChartSourceConfigType";
import {ChartSeriesType} from "../../Shared/Types/chartSeriesType";
import {ChartAnalysis} from "./ChartAnalysis";
import AnalysisHeaderMenu from "./AnalysisHeaderMenu";
import {
    AnalysisEntity,
    AnalysisReactQuery,
    ConfigurationByNameReactQuery,
    SaveAnalysisReactQuery
} from "./AnalysisHomeReactQuery";
import {ChartConfig} from "./ChartConfig";
import TextField from "@mui/material/TextField";
import {useSnackbar} from "notistack";
import {SystemRoutes} from "../../Utils/RouteUtils";
import {subDays} from "date-fns";
import {getEntitiesNavigationMenuAnalysis} from "./types/reducer/DashboardNavigationAnalysisReducer";
import {DashBoardNavigationAnalysisSelectedId} from "./types/reducer/DashboardNavigationAnalysisMenuState";
import {hasPermission} from "../../Shared/Auth/AuthenticationUtil";
import {AUTHORITIES} from "../../Config/Constants";


export interface ObjectOfModalRow {
    configType: number;
    selectedRow: Array<ModalRow>
}

export interface ModalRow {
    id: number;
    selected?: boolean;
    powerStationId: number;
    powerStationName: string;
    skidId: number | null;
    skidName: string | null;
    teleObjectId: number | null;
    equipmentId: number | null;
    equipmentName: string | null;
    type: string;
    measure: string;
    nameOfColumn: string;
    dbColumn: {
        id: number;
        name: string;
        displayName: string;
        unity: {
            id: number | null;
            name: string | null;
        }
        dbTable: {
            id: number;
            name: string;
        }
    } | null;
    chartSourceType: number;
    conversionFormula: string | null;
    unity: {
        id: number | null;
        name: string | null;
    };
    unityName: string;
    unityEquipmentId: number | null;
    unityConverters: UnityConverter[];
}

export interface SelectedIds {
    managerialData:  Array<ManagerialData | null>;
    telemeasureIds?: Array<number | null>;
    equipmentIds?: Array<number | null>;
}

export interface ManagerialData {
    powerStationId: number | null;
    skidId: number | null;
    dbColumId: number | null;
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85%',
    height: '80%',
    bgcolor: 'background.paper',
    border: '1px solid #666',
    
    // borderRadius: '25px'
}

let MAX_ROWS = 20;
const ANALYSIS_MAX_NUMBER = "analysisMaxNumber";

const AnalysisEdit = () =>{

    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    // find configuration value for analysisMaxNumber
    const configurationByName = ConfigurationByNameReactQuery(ANALYSIS_MAX_NUMBER);

    // Menu
    useEffect(() => {
        dispatch(getEntitiesNavigationMenuAnalysis({analysisId: params.analysisId}));
    }, []);


// MODAL
    const dispatch = useAppDispatch();
    const {selectedIds, isLoading } = useAppSelector(
        (state: RootState) => state.dashboardNavigationMenuAnalysis
    );


    const [selectedNavigationMenuByIds, setSelectedNavigationMenuByIds] = useState<DashBoardNavigationAnalysisSelectedId>(selectedIds);
    const { entities, totalOfRecords, loading } = useAppSelector(
        (state: RootState) => state.dashboardAnalysisGrid
    );

    const SizeSelectedElements = () => {
        let sizeElements = 0;
        if(selectedIds){
            sizeElements += selectedIds.selectedEquipmentId.length;
            sizeElements += selectedIds.selectedPowerStationId.length;
            sizeElements += selectedIds.selectedSkidId.length;
        }
        return [sizeElements*10*3,sizeElements*10*4];
    }

    const [sizePerPage, setSizePerPage] = useState<Array<number>>(SizeSelectedElements());

    useEffect(() => {
        setSizePerPage(SizeSelectedElements());
    }, [selectedNavigationMenuByIds?.selectedPowerStationId, selectedNavigationMenuByIds?.selectedSkidId, selectedNavigationMenuByIds?.selectedEquipmentId]);

    const initColumns = [
        {
            name: 'id',
            label: 'ID',
            align: 'left',
            visibility: false,
            minWidth: 10,
            breakpoints: {
                xs: true,
                sm: true,
                md: true,
                lg: true,
                xl: true
            },
        },
        {
            name: 'powerStationName',
            label: 'usina',
            align: 'left',
            minWidth: 10,
            visibility: true,
            breakpoints: {
                xs: true,
                sm: true,
                md: true,
                lg: true,
                xl: true
            },filter: {
                name: 'powerStationName',
                sort: 'none',
                value: '',
                condition: 'contains',
                placeholder: 'Filter'
            }
        },
        {
            name: 'skidName',
            label: 'skid',
            align: 'left',
            minWidth: 10,
            visibility: true,
            breakpoints: {
                xs: true,
                sm: true,
                md: true,
                lg: true,
                xl: true
            },filter: {
                name: 'skidName',
                sort: 'none',
                value: '',
                condition: 'contains',
                placeholder: 'Filter'
            }
        },
        {
            name: 'equipmentName',
            label: 'equipamento',
            align: 'left',
            minWidth: 10,
            visibility: true,
            breakpoints: {
                xs: true,
                sm: true,
                md: true,
                lg: true,
                xl: true
            },filter: {
                name: 'equipmentName',
                sort: 'none',
                value: '',
                condition: 'contains',
                placeholder: 'Filter'
            },
        },
        {
            name: 'type',
            label: 'tipo',
            align: 'left',
            minWidth: 10,
            visibility: true,
            breakpoints: {
                xs: true,
                sm: true,
                md: true,
                lg: true,
                xl: true
            },filter: {
                name: 'type',
                sort: 'none',
                value: '',
                condition: 'contains',
                placeholder: 'Filter'
            }
        },
        {
            name: 'measure',
            label: 'medida',
            align: 'left',
            minWidth: 10,
            visibility: true,
            breakpoints: {
                xs: true,
                sm: true,
                md: true,
                lg: true,
                xl: true
            },
            filter: {
                name: 'measure',
                sort: 'none',
                value: '',
                condition: 'contains',
                placeholder: 'Filter'
            }
        },
        {
            name: 'unityName',
            label: 'unidade',
            align: 'left',
            minWidth: 10,
            visibility: true,
            breakpoints: {
                xs: true,
                sm: true,
                md: true,
                lg: true,
                xl: true
            },
            filter: {name: 'unityName', condition: 'equals', value: '', sort: ''},
        }
    ] as ADataGridColumn[];
    const [editSelectedRows, setEditSelectedRows] = useState<SelectedIds>({telemeasureIds: [], managerialData: []})
    const [currentFilters, setCurrentFilters] = useState<any>({});
    const [columns, setColumns] = useState<ADataGridColumn[]>(initColumns)
    const [open, setOpen] = React.useState(false);
    const [rows, setRows] = useState<Array<ModalRow>>([]);
    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(columns), 0, 100));
    const [selectedRows, setSelectedRows] = useState<ModalRow[]>([]);
    const [isValidateSelectedByUnity, setIsValidateSelectedByUnity] = useState<boolean>(true);
    const [selectedOption, setSelectedOption] = useState<string>('individual');
    const [isEditChartConfig, setIsEditChartConfig] = useState(false)
    const [editKeyConfig, setEditKeyConfig] = useState(0)
    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        const filtersObject = dataGridFilters.reduce<{ [key: string]: string }>((acc, current) => {
            if (current.name !== 'id') { // Ignorar o filtro "TODOS"
                acc[current.name] = current.value;
            }
            return acc;
        }, {});

        setCurrentFilters(filtersObject);

        let newPageParams: PagedSearchParams = new PagedSearchParams(dataGridFilters, page, pageSize);
        setPagedSearchParams(
            newPageParams
        );
    }, [columns]);

    const structureRow = (entitiesValues: Array<DataItem>) => {
        let entity: Array<ModalRow> = [];
        entitiesValues.forEach((array) => {
            entity.push({
                teleObjectId: array.teleObjectId,
                selected: false,
                id: array.id,
                powerStationId: array.powerStationId,
                powerStationName: array.powerStationName,
                skidId: array.skidId,
                skidName: array.skidName,
                equipmentId: array.equipmentId,
                equipmentName: array.equipmentName,
                type: array.type,
                measure: array.measure,
                nameOfColumn: array.nameOfColumn,
                dbColumn: array.dbColumn,
                chartSourceType: array.chartSourceType,
                conversionFormula: array.conversionFormula,
                unity: array.unity,
                unityName: array.unityName,
                unityEquipmentId: array.unityEquipmentId,
                unityConverters: array.unityConverters
            });
        });
        setRows(entity);
    };

    const getSelectedIds = (allRows: ModalRow[]): SelectedIds => {
        let selectedIds: SelectedIds = {
            managerialData: [],
            telemeasureIds: []
        }
        allRows.forEach((row) => {
            if(row.dbColumn){
                selectedIds.managerialData.push({
                    powerStationId: row.powerStationId,
                    skidId: row.skidId,
                    dbColumId: row.dbColumn.id
                })
            }else if(row.teleObjectId){
                selectedIds.telemeasureIds?.push(row.teleObjectId)
            }
        })
        return selectedIds
    }
    const isFiltred = () => {
        return columns.some((column) => column.filter != null && column.filter?.value !== "")
    }
    const handleMaxSelected = (isMax: boolean) => {
        if(isMax) {
            enqueueSnackbar(
                <div>
                    <h2 style={{fontWeight: 'bold'}}>Vocẽ atingiu o limite de {MAX_ROWS} itens selecionados para essa tabela.</h2>
                </div>,
                {variant: 'error'}
            );
        }

    }
    const [allSelectedRows, setAllSelectedRows] = useState<ModalRow[]>([])
    const handleOnRowSelect = ((newSelectedRows: ModalRow[]) => {
        const updatedRows: ModalRow[] = newSelectedRows.map(row => {
            return {
                ...row
            };
        })
        if(selectedOption === "unificado"){
            handleLimitByUnity(updatedRows);
        }
        if(!isEditChartConfig){
            setSelectedRows(updatedRows);
            return
        }
        if(allSelectedRows && newSelectedRows && rows && rows.length === 0 && newSelectedRows.length === 0) {
            setSelectedRows(allSelectedRows)
            setEditSelectedRows(getSelectedIds(allSelectedRows))
        }else if(isFiltred() && allSelectedRows && rows && rows.length > 0 && updatedRows && updatedRows.length > 0){
            let selectedRowsWithouFilter = allSelectedRows.filter((row) => {
                if(row.teleObjectId){
                    return !rows.some((item) => item.teleObjectId === row.teleObjectId)
                }else if(row.dbColumn != null){
                    return !rows.some((item) => item.dbColumn?.id === row.dbColumn?.id)
                }
                return false
            })
            const newRowsSelected = [...selectedRowsWithouFilter, ...updatedRows]
            const mergedRowsSelected = newRowsSelected.filter((obj, index, self) => {
                if (obj.teleObjectId) {
                    return index === self.findIndex((t) => t.teleObjectId === obj.teleObjectId);
                }
                if (obj.dbColumn != null) {
                    return index === self.findIndex((t) => t.dbColumn && obj.dbColumn?.id === t.dbColumn.id);
                }
                return true;
            });
            setEditSelectedRows(getSelectedIds(mergedRowsSelected))
            setSelectedRows(mergedRowsSelected)
            setAllSelectedRows(mergedRowsSelected)
        }else if(!isFiltred() && updatedRows && updatedRows.length > 0){
            if(rows && rows.length === 0){
                setSelectedRows(allSelectedRows)
                setEditSelectedRows(getSelectedIds(allSelectedRows))
                return
            }
            setAllSelectedRows(updatedRows);
            setEditSelectedRows(getSelectedIds(updatedRows))
            setSelectedRows(updatedRows)
        }
    });

    const updateRows = (entitiesValues: Array<DataItem>,  selectedRows: SelectedIds) => {
        if(entitiesValues){
            let entity: Array<ModalRow> = [];
            entitiesValues.forEach((array) => {
                entity.push({
                    selected: setSelected(array, selectedRows),
                    ...array
                });
            });
            if(isEditChartConfig && !isFiltred()){
                const selectedEntity = entity.filter((row) => row.selected)
                setAllSelectedRows(selectedEntity);
                setEditSelectedRows(getSelectedIds(selectedEntity))
                setSelectedRows(selectedEntity);
            }
            setRows(entity);
        }
    }

    useEffect(() => {
        if (entities) {
            if(isEditChartConfig){
                updateRows(entities, editSelectedRows)
            }else{
                structureRow(entities);
            }
        } else {
            setRows([])
        }

    }, [entities]);

    useEffect(() => {
        setRows([])
    }, []);

    useEffect(() => {
        if(configurationByName.isSuccess && configurationByName.data.data.value) {
            MAX_ROWS = configurationByName.data.data.value;
        }
    }, [configurationByName.isSuccess]);

    useEffect(() => {
        setSelectedNavigationMenuByIds(selectedIds);
    }, [selectedIds]);

    // DATA MEASURE REDUCER
    useEffect(() => {
        if(selectedNavigationMenuByIds != null) {
            setRows([])
            dispatch(getEntitiesOfGrid({selectedIds: selectedNavigationMenuByIds, params: pagedSearchParams}))
        }
    }, [pagedSearchParams]);

    const entitiesRef = useRef(false);
    useEffect(() => {
        if (!entitiesRef.current) {
            entitiesRef.current = true;
        }
    }, [entities]);
    useEffect(() => {
        if (!entitiesRef.current) {
            entitiesRef.current = true;
        }
    }, [columns]);

    const setSelected = (data: DataItem, selectedRows: SelectedIds): boolean => {
        if(selectedRows != null){
            if(data.teleObjectId != null){
                return selectedRows.telemeasureIds?.some(item => item === data.teleObjectId) ?? false
            }else if(data.skidId != null){
                return selectedRows.managerialData?.some(item => item?.skidId === data.skidId && item.dbColumId === data.dbColumn.id) ?? false
            }else if(data.powerStationId != null){
                return selectedRows.managerialData?.some(item => item?.powerStationId === data.powerStationId && item.dbColumId === data.dbColumn.id) ?? false
            }
        }
        return false;
    }


    const handleLimitByUnity = (dataRowList:ModalRow[]) => {
        let unities: Array<number|null> = [];
        dataRowList.forEach(dataRow => {
            if( (unities.filter(unity => unity === dataRow.unity?.id).length < 1)) {
                unities.push(dataRow?.unity?.id);
            }
        })
        if(unities.length > 2) {
            setIsValidateSelectedByUnity(false);
            enqueueSnackbar("Selecione medidas de até 2 unidades distintas.", {variant: 'warning'})
            return true;
        } else {
            setIsValidateSelectedByUnity(true);
            return false;
        }
    }

    const handleSelectButtonClick = () => {
        const updatedSelectedData = {
            configType: selectedOption === 'individual' ? 0 : 1,
            selectedRow: selectedRows
        }
        let isValidated = true;

        if(updatedSelectedData.configType === 1){
            isValidated = !handleLimitByUnity(updatedSelectedData.selectedRow)
        }
        if(isValidated){
            const uniqueKey = 0
            handleAnalysisConfig(updatedSelectedData, uniqueKey);
            handleCloseModal();
        }

    };

    const handleSelectEditButtonClick = () => {
        const updatedSelectedData = {
            configType: selectedOption === 'individual' ? 0 : 1,
            selectedRow: selectedRows
        }
        handleEditAnalysisConfig(updatedSelectedData, editKeyConfig);
        handleCloseModal();
        setIsEditChartConfig(false)
        setEditKeyConfig(0);
    };

    const handleLabel = (data:ModalRow) => {
        return data.dbColumn?.dbTable?.name ? data.dbColumn?.dbTable?.name + " - " + data.powerStationName + " " + data?.skidName : data.teleObjectId + " " + data.powerStationName + " " + data?.skidName + " - " + data?.equipmentName;
    }

    const handleEditAnalysisConfig = (selectedRowList: ObjectOfModalRow, uniqueKey: number) => {
        let chartConfig: Array<ChartConfigType> = [];
        const nowStart = new Date()
        nowStart.setHours(0);
        nowStart.setMinutes(0);
        const nowStop = new Date()

        // SINGLE_AXIS ==> 0
        // MULTI_AXIS ==> 1
        if(selectedRowList != null && selectedRowList?.configType === 0){

            selectedRowList.selectedRow.forEach(data => {
                const randomNumber = Math.floor(Math.random() * 9007199254740991) + 1;
                let xAxisList: Array<ChartAxisType> = [];
                let yAxisList: Array<ChartAxisType> = [];
                let seriesList:Array<ChartSeriesType>=[];
                let chartSourceConfig:ChartSourceConfigType = {
                    id: null,
                    name: data.measure,
                    chartSourceType: data.chartSourceType, // DBCOLUMN,TELEOBJECT, API, TAG_VALUE
                    aggregatedDataConfig: data.dbColumn?.id && data?.dbColumn?.dbTable != null ? {
                        id: null,
                        dbColumn: data.dbColumn,
                        equipment: data?.equipmentId != null? {
                            id: data.equipmentId,
                            name: data.equipmentName,
                            powerStation: {
                                id: data.powerStationId,
                                name: data.powerStationName
                            }
                        } : null,
                        powerStation: data.powerStationId != null? {
                            id: data.powerStationId,
                            name: data.powerStationName
                        } : null,
                        skid: data.skidId != null? {
                            id: data.skidId,
                            name: data.skidName
                        } : null,
                    } : null,
                    teleObject: data.teleObjectId && data.equipmentId? {
                        id: data.teleObjectId,
                        teleObjectConfig: {
                            name: data.measure,
                            unity: {
                                id: data.unity?.id,
                                name: data.unity?.name,
                            }
                        },
                        equipment: {
                            id: data.equipmentId,
                            name: data.equipmentName,
                            powerStation: {
                                id: data.powerStationId,
                                name: data.powerStationName
                            },
                            skid:  data.skidId != null ? {
                                id: data.skidId,
                                name: data.skidName
                            } : null
                        }
                    } : null
                }
                let dataUnityValue = "avg";
                if(data.dbColumn?.name.includes("expected_performance_ratio") || data.dbColumn?.name.includes("performance_ratio")) {
                    dataUnityValue = "max"
                }
                yAxisList.push({
                    id: null,
                    tag: randomNumber,
                    name: data.unity.name !== null ? data.measure + " ["+ data.unity.name + "]" : data.measure,
                    label: data.dbColumn?.dbTable?.name ? data.dbColumn.dbTable.name + " - " + data.powerStationName + " " + data?.skidName : data.teleObjectId + " " + data.powerStationName + " " + data?.skidName + " - " + data?.equipmentName,
                    type: "value",
                    line: true,
                    lineColor: "",
                    axisType: 1, // 0 = X_AXIS, 1 = Y_AXIS
                    dataUnity: dataUnityValue,
                    position: 2, // TOP,BOTTOM,LEFT,RIGHT
                    alignTicks: true,
                    index: 0,
                    chartSourceConfig: chartSourceConfig,
                    chartConfig: null,
                    data: null,
                    min: null,
                    max: null
                })
                xAxisList.push({
                    id: null,
                    tag: randomNumber,
                    name: 'Dia',
                    label: "Dia",
                    type: "category",
                    line: true,
                    lineColor: "#051976",
                    axisType: 0, // 0 = X_AXIS, 1 = Y_AXIS
                    dataUnity: "",
                    position: 1, // TOP,BOTTOM,LEFT,RIGHT
                    alignTicks: true,
                    index: 0,
                    chartSourceConfig: chartSourceConfig,
                    chartConfig: null,
                    data: null,
                    min: null,
                    max: null
                })
                seriesList.push(
                    {
                        id: null,
                        tag: randomNumber,
                        name: data.measure,
                        label: data.dbColumn?.dbTable?.name ? data.dbColumn.dbTable.name + " - " + data.powerStationName + " " + data?.skidName : data.teleObjectId + " " + data.powerStationName + " " + data?.skidName + " - " + data?.equipmentName,
                        type: "bar",
                        yAxisIndex: 0
                    }
                )
                const newChartConfig = {
                    id: null,
                    name: setNameOfCharts(data),
                    description: "",
                    startDateTime: nowStart,
                    endDateTime: nowStop,
                    minute: null,
                    hour: null,
                    grouping: "day",
                    configType: 0,
                    periodType: 0,
                    analysis: null,
                    yAxisList: yAxisList,
                    xAxisList: xAxisList,
                    seriesList:seriesList
                }
                chartConfig.push(handleGroupingByDefault(newChartConfig));
            })
        } else if(selectedRowList != null && selectedRowList?.configType === 1){

            let yAxisList: Array<ChartAxisType> = [];
            let xAxisList: Array<ChartAxisType> = [];
            let seriesList: Array<ChartSeriesType> = [];
            let isSameMeasure = selectedRowList.selectedRow.every((data, _, array) => data.measure === array[0].measure);
            selectedRowList.selectedRow.forEach((data) => {
                const randomNumber = Math.floor(Math.random() * 9007199254740991) + 1;

                let chartSourceConfig:ChartSourceConfigType = {
                    id: null,
                    name: data.measure,
                    chartSourceType: data.chartSourceType, // DBCOLUMN,TELEOBJECT, API, TAG_VALUE
                    aggregatedDataConfig: {
                        id: null,
                        dbColumn: data?.dbColumn?.dbTable != null ? {
                            id: data.dbColumn?.id,
                            name: data.dbColumn?.name,
                            displayName: data.dbColumn?.displayName,
                            unity: {
                                id: data.dbColumn?.unity?.id,
                                name: data.dbColumn?.unity.name,
                            },
                            dbTable: {
                                id: data.dbColumn?.dbTable.id,
                                name: data.dbColumn?.dbTable.name,
                            }
                        } : null,
                        equipment: data?.equipmentId != null && data?.equipmentName != null ? {
                            id: data.equipmentId,
                            name: data.equipmentName,
                            powerStation: {
                                id: data.powerStationId,
                                name: data.powerStationName
                            }
                        } : null,
                        powerStation: data.powerStationId != null && data.powerStationName != null ? {
                            id: data.powerStationId,
                            name: data.powerStationName
                        } : null,
                        skid: data.skidId != null && data.skidName != null ? {
                            id: data.skidId,
                            name: data.skidName
                        } : null,
                    },
                    teleObject: data.teleObjectId && data.equipmentId && data.measure? {
                        id: data.teleObjectId,
                        teleObjectConfig: {
                            name: data.measure,
                            unity: {
                                id: data.unity?.id,
                                name: data.unity?.name,
                            }
                        },
                        equipment: {
                            id: data.equipmentId,
                            name: data.equipmentName,
                            powerStation: {
                                id: data.powerStationId,
                                name: data.powerStationName
                            },
                            skid:  data.skidId != null && data.skidName != null ? {
                                id: data.skidId,
                                name: data.skidName
                            } : null
                        }
                    } : null
                }
                let dataUnityValue = "avg";
                if(data.dbColumn?.name.includes("expected_performance_ratio") || data.dbColumn?.name.includes("performance_ratio")) {
                    dataUnityValue = "max"
                }
                yAxisList.push({
                    id: null,
                    tag: randomNumber,
                    name: data.measure,
                    label: handleLabel(data),
                    type: "value",
                    line: true,
                    lineColor: "#051976",
                    axisType: 1, // 0 = X_AXIS, 1 = Y_AXIS
                    dataUnity: dataUnityValue,
                    position: 2, // TOP,BOTTOM,LEFT,RIGHT
                    alignTicks: true,
                    index: 0,
                    chartSourceConfig: chartSourceConfig,
                    chartConfig: null,
                    data: null,
                    min: null,
                    max: null
                })

                xAxisList.push({
                    id: null,
                    tag: randomNumber,
                    name: "Dia",
                    label: "Dia",
                    type: "category",
                    line: true,
                    lineColor: "",
                    axisType: 0, // 0 = X_AXIS, 1 = Y_AXIS
                    dataUnity: "",
                    position: 1, // TOP,BOTTOM,LEFT,RIGHT
                    alignTicks: true,
                    index: 0,
                    chartSourceConfig: chartSourceConfig,
                    chartConfig: null,
                    data: null,
                    min: null,
                    max: null
                })

                seriesList.push(
                    {
                        id: null,
                        tag: randomNumber,
                        name: data.measure,
                        label: handleLabel(data),
                        type: "bar",
                        yAxisIndex: 0
                    }
                )
            })
            let chartName = isSameMeasure ? `Análise Unificada de ${selectedRowList.selectedRow[0]?.measure}` : "Análise Unificada";
            const newChartConfig = {
                id: null,
                name: chartName,
                description: "",
                startDateTime:nowStart,
                endDateTime: nowStop,
                periodType: 0,
                minute: null,
                hour: null,
                grouping: "day",
                configType: 1,
                analysis: null,
                yAxisList: yAxisList,
                xAxisList: xAxisList,
                seriesList:seriesList
            };
            chartConfig.push(handleGroupingByDefault(newChartConfig));
        }

        let updatedChartConfigList: ChartConfigType[] | null = null;
        if (chartConfigList && chartConfigList.length > 0) {
            const index = chartConfigList.findIndex(item => generateUniqueKey(item) === uniqueKey)
            updatedChartConfigList = [...chartConfigList];
            updatedChartConfigList.splice(index,1,...chartConfig)
        }
        setChartConfigList(updatedChartConfigList ?? chartConfig);
    }

    const chartConfigToRow = (chartConfig: ChartConfigType) => {
        const rowsConfig : ModalRow[] = []
        chartConfig.yAxisList.forEach((axis) => {
            const dbColumnExists = axis.chartSourceConfig.aggregatedDataConfig?.dbColumn != null
            rowsConfig.push({
                id: 0,
                selected: true,
                powerStationId: axis.chartSourceConfig?.aggregatedDataConfig?.powerStation?.id ?? axis.chartSourceConfig.teleObject?.equipment?.powerStation?.id ?? 0,
                powerStationName: axis.chartSourceConfig.aggregatedDataConfig?.powerStation?.name  ?? axis.chartSourceConfig.teleObject?.equipment.powerStation?.name ?? "",
                skidId:  axis.chartSourceConfig.aggregatedDataConfig?.skid?.id ?? axis.chartSourceConfig.teleObject?.equipment?.skid?.id ?? null,
                skidName: (axis.chartSourceConfig.aggregatedDataConfig?.skid ? axis.chartSourceConfig.aggregatedDataConfig.skid.name : axis.chartSourceConfig.teleObject?.equipment?.skid?.name) ?? null,
                teleObjectId: axis.chartSourceConfig.teleObject?.id ?? null,
                equipmentId: axis.chartSourceConfig.teleObject?.equipment?.id ?? null,
                equipmentName: axis.chartSourceConfig.teleObject?.equipment?.name ?? null,
                type: dbColumnExists ? "Gerencial" : "Telemedida",
                measure: axis.chartSourceConfig.teleObject?.teleObjectConfig?.name ?? "",
                nameOfColumn: "",
                dbColumn: axis.chartSourceConfig.aggregatedDataConfig?.dbColumn ?? null,
                chartSourceType: dbColumnExists ? 0 : 1,
                conversionFormula: null,
                unity: axis.chartSourceConfig.teleObject?.teleObjectConfig?.unity ?? axis.chartSourceConfig.aggregatedDataConfig?.dbColumn?.unity ?? {id: null, name: null},
                unityName: axis.chartSourceConfig.teleObject?.teleObjectConfig?.unity?.name ?? "",
                unityEquipmentId: axis.chartSourceConfig.teleObject?.teleObjectConfig?.unity?.id ?? null,
                unityConverters: [],
            })
        })
        return rowsConfig
    }

    const handleOpenModal = () => {
        setPagedSearchParams(new PagedSearchParams(extractFiltersFromColumns(columns), 0, sizePerPage[sizePerPage.length-1]))
        setOpen(true);
    };

    useEffect(() => {
        if(isEditChartConfig){
            const keys = Object.keys(currentFilters) as (keyof typeof currentFilters)[];
            const newColumns = initColumns.map(item => {
                const currentFilter = keys.filter(key => item.filter?.name === key)[0]
                return {
                    ...item,
                    filter: {
                        ...item.filter,
                        value: currentFilters[currentFilter],
                    }
                }
            }) as ADataGridColumn[];
            setColumns(newColumns)
        }
    }, [isEditChartConfig]);

    const onEdit = (uniqueKey:number, chartConfigEdit: ChartConfigType,selectRows: SelectedIds, filters:any) => {
        setCurrentFilters(filters)
        setIsEditChartConfig(true)
        setEditKeyConfig(uniqueKey)
        setAllSelectedRows(chartConfigToRow(chartConfigEdit))

        const newSelectedNavigationMenuByIds:DashBoardNavigationAnalysisSelectedId = {
            selectedEquipmentId: [],
            selectedSkidId: [],
            selectedPowerStationId: [],
            selectedGerencialPowerStationId: [],
            selectedGerencialSkidId: [],
            selectedGerencialEquipmentId: [],
            selectedMedidaPowerStationId: [],
            selectedMedidaSkidId: [],
            selectedMedidaEquipmentId: []

        }
        if(chartConfigEdit){
            selectRows.managerialData?.forEach(item => {
                if(item?.powerStationId != null && !newSelectedNavigationMenuByIds.selectedPowerStationId?.find(element => element === item.powerStationId) &&
                    !selectedNavigationMenuByIds?.selectedPowerStationId?.find(element => element === item.powerStationId)){
                    if(selectedNavigationMenuByIds != null) {
                        newSelectedNavigationMenuByIds.selectedPowerStationId.push(...selectedNavigationMenuByIds.selectedPowerStationId);
                    }
                    newSelectedNavigationMenuByIds.selectedPowerStationId.push(item.powerStationId);
                }
                if(item?.skidId != null && !newSelectedNavigationMenuByIds.selectedSkidId?.find(element => element === item.skidId) &&
                    !selectedNavigationMenuByIds?.selectedSkidId?.find(element => element === item.skidId)){
                    if(selectedNavigationMenuByIds != null) {
                        newSelectedNavigationMenuByIds.selectedSkidId.push(...selectedNavigationMenuByIds.selectedSkidId);
                    }
                    newSelectedNavigationMenuByIds.selectedSkidId.push(item?.skidId);
                }
            })
            selectRows.equipmentIds?.forEach(item => {
                if(item != null &&
                    !newSelectedNavigationMenuByIds.selectedEquipmentId?.find(element => element === item) &&
                    !selectedNavigationMenuByIds?.selectedEquipmentId?.find(element => element === item)){
                    if(selectedNavigationMenuByIds != null) {
                        newSelectedNavigationMenuByIds.selectedEquipmentId.push(...selectedNavigationMenuByIds.selectedEquipmentId);
                    }
                    newSelectedNavigationMenuByIds.selectedEquipmentId.push(item);
                }
            })
        }
        selectedNavigationMenuByIds?.selectedEquipmentId?.forEach(equipmentId => {
            if(!newSelectedNavigationMenuByIds.selectedEquipmentId?.find(element => element === equipmentId)) {
                newSelectedNavigationMenuByIds.selectedEquipmentId.push(equipmentId);
            }
        });
        selectedNavigationMenuByIds?.selectedSkidId?.forEach(skidId => {
            if(!newSelectedNavigationMenuByIds.selectedSkidId?.find(element => element === skidId)) {
                newSelectedNavigationMenuByIds.selectedSkidId.push(skidId);
            }
        });
        selectedNavigationMenuByIds?.selectedPowerStationId?.forEach(powerStationId => {
            if(!newSelectedNavigationMenuByIds.selectedPowerStationId?.find(element => element === powerStationId)) {
                newSelectedNavigationMenuByIds.selectedPowerStationId.push(powerStationId);
            }
        });
        if(newSelectedNavigationMenuByIds?.selectedEquipmentId.length > 0 || newSelectedNavigationMenuByIds?.selectedSkidId.length > 0 || newSelectedNavigationMenuByIds?.selectedPowerStationId.length > 0){
            setSelectedNavigationMenuByIds(newSelectedNavigationMenuByIds);
        }
        // UPDATE PER PAGE -> DATAGRID
        setSizePerPage(SizeSelectedElements());
        setEditSelectedRows(selectRows)
        updateRows(entities, selectRows)
        handleOpenModal()
    }

    const handleCloseModal = () => {
        setOpen(false);
        setIsEditChartConfig(false)
        setEditKeyConfig(0)
        structureRow(entities)
        setColumns(initColumns)
    };

    const navigate = useNavigate();

// CHART CONFIG
    const {data, isSuccess} = AnalysisReactQuery(params.analysisId);
    const mutation = SaveAnalysisReactQuery();
    const [nameAnalysisChart, setNameAnalysisChart] = React.useState<string>("");
    const [dataAnalysis, setDataAnalysis] = useState<AnalysisEntity | null>(null);
    const [chartConfigList, setChartConfigList] = useState<ChartConfigType[] | null>(null);

    useEffect(() => {
        if (isSuccess) {
            setDataAnalysis(data?.data)
            setNameAnalysisChart(data?.data.name)
            setChartConfigList(data?.data.chartConfigList)
        } else {
            setDataAnalysis(null)
        }

    }, [data]);

    const handleNewAnalysisConfig = () => {
        let newAnalysisEquipmentList: AnalysisEquipmentType[] = [];
        if (selectedNavigationMenuByIds?.selectedEquipmentId && selectedNavigationMenuByIds.selectedEquipmentId.length > 0) {
            selectedNavigationMenuByIds.selectedEquipmentId.forEach(equipmentId => {
                newAnalysisEquipmentList.push({
                    equipment: {
                        id: equipmentId,
                    },
                    powerStation: null,
                    skid: null,
                    analysisType: null,
                    telemedidaEquipment: !!selectedNavigationMenuByIds.selectedMedidaEquipmentId?.find(eqp => eqp === equipmentId),
                    gerencialEquipment: !!selectedNavigationMenuByIds.selectedGerencialEquipmentId?.find(eqp => eqp === equipmentId),
                    gerencialPowerStation: false,
                    telemedidaPowerStation: false,
                    gerencialSkid: false,
                    telemedidaSkid: false
                });
            });


        }
        if (selectedNavigationMenuByIds?.selectedSkidId && selectedNavigationMenuByIds?.selectedSkidId.length > 0) {

            selectedNavigationMenuByIds.selectedSkidId.forEach(skidId => {
                newAnalysisEquipmentList.push({
                    equipment: null,
                    powerStation: null,
                    skid: {
                        id: skidId
                    },
                    analysisType: null,
                    telemedidaEquipment: false,
                    gerencialEquipment: false,
                    gerencialPowerStation: false,
                    telemedidaPowerStation: false,
                    gerencialSkid: !!selectedNavigationMenuByIds.selectedGerencialSkidId?.find(skd => skd === skidId),
                    telemedidaSkid: !!selectedNavigationMenuByIds.selectedMedidaSkidId?.find(skd => skd === skidId)
                });
            });
        }
        if (selectedNavigationMenuByIds?.selectedPowerStationId && selectedNavigationMenuByIds?.selectedPowerStationId.length > 0) {

            selectedNavigationMenuByIds.selectedPowerStationId.forEach(powerStationId => {
                newAnalysisEquipmentList.push({
                    equipment: null,
                    powerStation: {
                        id: powerStationId,
                    },
                    skid: null,
                    analysisType: null,
                    telemedidaEquipment: false,
                    gerencialEquipment: false,
                    gerencialPowerStation: !!selectedNavigationMenuByIds.selectedGerencialPowerStationId?.find(ps => ps === powerStationId),
                    telemedidaPowerStation: !!selectedNavigationMenuByIds.selectedMedidaPowerStationId?.find(ps => ps === powerStationId),
                    gerencialSkid: false,
                    telemedidaSkid: false
                });
            });
        }

        return newAnalysisEquipmentList;

    }

    const joinWithDash = (parts: (string | null)[]) => parts.filter(Boolean).join(' - ');

    const setNameOfCharts = (data: ModalRow) => {
        const { powerStationName, skidName, equipmentName } = data;
        return joinWithDash([powerStationName, skidName, equipmentName]);
    }

    const handleGroupingByDefault = (data:ChartConfigType) => {

        if(data.yAxisList
            .filter(yAxis => yAxis.chartSourceConfig.teleObject?.id != null)
            .length == data.yAxisList.length){ // telemeasurements only
            data.grouping = "none";
            data.displayDataPoints = 2;
            data.xAxisList.map(xAxis => {
                xAxis.name = "Datas";
                xAxis.label = "Datas";
                return xAxis;
            })
            data.seriesList.map(series => {
                series.type = "line";
                return series;
            })
            return data;
        }else if(data.xAxisList
            .filter(xAxis => xAxis.chartSourceConfig?.aggregatedDataConfig?.dbColumn?.id != null)
            .length > 0){
            data.startDateTime = subDays(new Date(data.endDateTime), 6);
            return data;
        }else {
            return data;
        }
    }

    const handleAnalysisConfig = (selectedRowList: ObjectOfModalRow, uniqueKey: number) => {
        let chartConfig: Array<ChartConfigType> = [];
        const nowStart = new Date()
        nowStart.setHours(0);
        nowStart.setMinutes(0);
        const nowStop = new Date()

        // SINGLE_AXIS ==> 0
        // MULTI_AXIS ==> 1
        if(selectedRowList != null && selectedRowList?.configType === 0){

            selectedRowList.selectedRow.forEach(data => {
                const randomNumber = Math.floor(Math.random() * 9007199254740991) + 1;
                let xAxisList: Array<ChartAxisType> = [];
                let yAxisList: Array<ChartAxisType> = [];
                let seriesList:Array<ChartSeriesType>=[];
                let chartSourceConfig:ChartSourceConfigType = {
                    id: null,
                    name: data.measure,
                    chartSourceType: data.chartSourceType, // DBCOLUMN,TELEOBJECT, API, TAG_VALUE
                    aggregatedDataConfig: data.dbColumn?.id && data?.dbColumn?.dbTable != null ? {
                        id: null,
                        dbColumn: data?.dbColumn,
                        equipment: data?.equipmentId != null ? {
                            id: data.equipmentId,
                            name: data.equipmentName,
                            powerStation: {
                                id: data.powerStationId,
                                name: data.powerStationName
                            }
                        } : null,
                        powerStation: data.powerStationId != null? {
                            id: data.powerStationId,
                            name: data.powerStationName
                        } : null,
                        skid: data.skidId != null? {
                            id: data.skidId,
                            name: data.skidName
                        } : null,
                    } : null,
                    teleObject: data.teleObjectId && data.equipmentId ? {
                        id: data.teleObjectId,
                        teleObjectConfig: {
                            name: data.measure,
                            unity: {
                                id: data.unity?.id,
                                name: data.unity?.name,
                            }
                        },
                        equipment: {
                            id: data.equipmentId,
                            name: data.equipmentName,
                            powerStation: {
                                id: data.powerStationId,
                                name: data.powerStationName
                            },
                            skid:  data.skidId != null ? {
                                id: data.skidId,
                                name: data.skidName
                            } : null
                        }
                    } : null
                }
                let dataUnityValue = "avg";
                if(data.dbColumn?.name.includes("expected_performance_ratio") || data.dbColumn?.name.includes("performance_ratio")) {
                    dataUnityValue = "max"
                }
                yAxisList.push({
                    id: null,
                    tag: randomNumber,
                    name: data.unity.name !== null ? data.measure + " ["+ data.unity.name + "]" : data.measure,
                    label: handleLabel(data),
                    type: "value",
                    line: true,
                    lineColor: "",
                    axisType: 1, // 0 = X_AXIS, 1 = Y_AXIS
                    dataUnity: dataUnityValue,
                    position: 2, // TOP,BOTTOM,LEFT,RIGHT
                    alignTicks: true,
                    index: 0,
                    chartSourceConfig: chartSourceConfig,
                    chartConfig: null,
                    data: null,
                    min: null,
                    max: null
                })
                xAxisList.push({
                    id: null,
                    tag: randomNumber,
                    name: 'Dia',
                    label: "Dia",
                    type: "category",
                    line: true,
                    lineColor: "#051976",
                    axisType: 0, // 0 = X_AXIS, 1 = Y_AXIS
                    dataUnity: "",
                    position: 1, // TOP,BOTTOM,LEFT,RIGHT
                    alignTicks: true,
                    index: 0,
                    chartSourceConfig: chartSourceConfig,
                    chartConfig: null,
                    data: null,
                    min: null,
                    max: null
                })
                seriesList.push(
                    {
                        id: null,
                        tag: randomNumber,
                        name: data.measure,
                        label: handleLabel(data),
                        type: "bar",
                        yAxisIndex: 0
                    }
                )
                const newChartConfig = {
                    id: null,
                    name: setNameOfCharts(data),
                    description: "",
                    startDateTime: nowStart,
                    endDateTime: nowStop,
                    minute: null,
                    hour: null,
                    grouping: "day",
                    configType: 0,
                    periodType: 0,
                    analysis: null,
                    yAxisList: yAxisList,
                    xAxisList: xAxisList,
                    seriesList:seriesList,
                    powerStationFilterColumn: currentFilters.powerStationName,
                    skidFilterColumn: currentFilters.skidName,
                    equipmentFilterColumn: currentFilters.equipmentName,
                    typeFilterColumn: currentFilters.type,
                    measurementFilterColumn: currentFilters.measure
                }
                chartConfig.push(handleGroupingByDefault(newChartConfig));
            })
        } else if(selectedRowList != null && selectedRowList?.configType === 1){

            let yAxisList: Array<ChartAxisType> = [];
            let xAxisList: Array<ChartAxisType> = [];
            let seriesList: Array<ChartSeriesType> = [];
            let isSameMeasure = selectedRowList.selectedRow.every((data, _, array) => data.measure === array[0].measure);
            // SORT BY UNITY
            let selectedRowSort = selectedRowList.selectedRow;
            selectedRowSort.sort((a:ModalRow, b:ModalRow) =>{
                if(a.unity?.id != null && b.unity?.id != null && a.unity?.id < b.unity?.id) return -1;
                if(a.unity?.id != null && b.unity?.id != null && a.unity?.id > b.unity?.id) return 1;
                return 0;
            });

            const unityByIdDistinc = Array.from(new Set(selectedRowSort.map(item => item.unity?.id)));
            selectedRowSort.forEach((data, index) => {
                const randomNumber = Math.floor(Math.random() * 9007199254740991) + 1;

                let chartSourceConfig:ChartSourceConfigType = {
                    id: null,
                    name: data.measure,
                    chartSourceType: data.chartSourceType, // DBCOLUMN,TELEOBJECT, API, TAG_VALUE
                    aggregatedDataConfig: {
                        id: null,
                        dbColumn: data?.dbColumn,
                        equipment: data?.equipmentId != null && data?.equipmentName != null ? {
                            id: data.equipmentId,
                            name: data.equipmentName,
                            powerStation: {
                                id: data.powerStationId,
                                name: data.powerStationName
                            }
                        } : null,
                        powerStation: data.powerStationId != null && data.powerStationName != null ? {
                            id: data.powerStationId,
                            name: data.powerStationName
                        } : null,
                        skid: data.skidId != null && data.skidName != null ? {
                            id: data.skidId,
                            name: data.skidName
                        } : null,
                    },
                    teleObject: data.teleObjectId && data.equipmentId && data.measure? {
                        id: data.teleObjectId,
                        teleObjectConfig: {
                            name: data.measure,
                            unity: {
                                id: data.unity?.id,
                                name: data.unity?.name,
                            }
                        },
                        equipment: {
                            id: data.equipmentId,
                            name: data.equipmentName,
                            powerStation: {
                                id: data.powerStationId,
                                name: data.powerStationName
                            },
                            skid:  data.skidId != null && data.skidName != null ? {
                                id: data.skidId,
                                name: data.skidName
                            } : null
                        }
                    } : null
                }

                // CHART TWO Y-AXIS
                let yAxisIndex = 0
                let position = 2;
                if(unityByIdDistinc.length === 2 && data.unity?.id === unityByIdDistinc[1]) {
                        yAxisIndex = 1;
                        position = 3;
                }
                let dataUnityValue = "avg";
                if(data.dbColumn?.name.includes("expected_performance_ratio") || data.dbColumn?.name.includes("performance_ratio")) {
                    dataUnityValue = "max"
                }
                yAxisList.push({
                    id: null,
                    tag: randomNumber,
                    name: data.measure,
                    label: handleLabel(data),
                    type: "value",
                    line: true,
                    lineColor: "#051976",
                    axisType: 1, // 0 = X_AXIS, 1 = Y_AXIS
                    dataUnity: dataUnityValue,
                    position: position, // TOP,BOTTOM,LEFT,RIGHT
                    alignTicks: true,
                    index: index,
                    chartSourceConfig: chartSourceConfig,
                    chartConfig: null,
                    data: null,
                    min: null,
                    max: null
                })

                xAxisList.push({
                    id: null,
                    tag: randomNumber,
                    name: "Dia",
                    label: "Dia",
                    type: "category",
                    line: true,
                    lineColor: "",
                    axisType: 0, // 0 = X_AXIS, 1 = Y_AXIS
                    dataUnity: "",
                    position: 1, // TOP,BOTTOM,LEFT,RIGHT
                    alignTicks: true,
                    index: 0,
                    chartSourceConfig: chartSourceConfig,
                    chartConfig: null,
                    data: null,
                    min: null,
                    max: null
                })

                seriesList.push(
                    {
                        id: null,
                        tag: randomNumber,
                        name: data.measure,
                        label: handleLabel(data),
                        type: "bar",
                        yAxisIndex: yAxisIndex
                    }
                )
            })
            let chartName = isSameMeasure ? `Análise Unificada de ${selectedRowList.selectedRow[0].measure}` : "Análise Unificada";
            const newChartConfig = {
                id: null,
                name: chartName,
                description: "",
                startDateTime:nowStart,
                endDateTime: nowStop,
                periodType: 0,
                minute: null,
                hour: null,
                grouping: "day",
                configType: 1,
                analysis: null,
                yAxisList: yAxisList,
                xAxisList: xAxisList,
                seriesList:seriesList,
                powerStationFilterColumn: currentFilters.powerStationName,
                skidFilterColumn: currentFilters.skidName,
                equipmentFilterColumn: currentFilters.equipmentName,
                typeFilterColumn: currentFilters.type,
                measurementFilterColumn: currentFilters.measure
            };
            chartConfig.push(handleGroupingByDefault(newChartConfig));
        }

        let updatedChartConfigList: ChartConfigType[] | null = null;
        if (chartConfigList && chartConfigList.length > 0) {
            if(isEditChartConfig){
                const index = chartConfigList.findIndex(item => generateUniqueKey(item) === uniqueKey)
                updatedChartConfigList = [...chartConfigList];
                updatedChartConfigList.splice(index,1,...chartConfig)
            }else{
                updatedChartConfigList = [...chartConfigList, ...chartConfig];
            }
        }
        setChartConfigList(updatedChartConfigList ?? chartConfig);
    }

    const handlePreview = (preview: ChartConfigType, indexPreview : number) => {
        if(chartConfigList != null){
            const newChatConfigList = chartConfigList.map((chartConfig)=> {
                if((chartConfig.id != null && chartConfig.id === preview.id) || (indexPreview === generateUniqueKey(chartConfig))){
                    return preview
                }
                return chartConfig
            })

            setChartConfigList(newChatConfigList)
            const analysisEquipmentList = handleNewAnalysisConfig();

            if (analysisEquipmentList.length > 0){
                setDataAnalysis({
                    id: dataAnalysis ? dataAnalysis.id : null,
                    name: nameAnalysisChart,
                    analysisEquipmentsList: analysisEquipmentList,
                    chartConfigList: chartConfigList
                })
            }else {
                setDataAnalysis({
                    id: dataAnalysis ? dataAnalysis.id : null,
                    name: nameAnalysisChart,
                    analysisEquipmentsList: dataAnalysis?.analysisEquipmentsList ?? analysisEquipmentList,
                    chartConfigList: newChatConfigList
                })
            }
        } else {
            setChartConfigList([preview])
        }
    }
    const convertStringToNumber = (str: string) => {
        let sum = 0;
        for (let i = 0; i < str.length; i++) {
            sum += str.charCodeAt(i);
        }
        return sum;
    };

    const generateUniqueKey = (chartConfig: ChartConfigType) => {
        const keyParts = [chartConfig.name]; //The name is always the start of key

        if(chartConfig.id !== null) {
            return chartConfig.id
        }
        chartConfig.yAxisList.forEach(yAxis => {
            const chartSourceConfig = yAxis.chartSourceConfig;
            if (chartSourceConfig) {
                if (chartSourceConfig.chartSourceType === 0) {

                    // Lógica para gráficos gerenciais
                    const powerStation = chartSourceConfig?.aggregatedDataConfig?.powerStation?.name;
                    if (powerStation != null) {
                        keyParts.push(powerStation);
                    }
                    if (chartSourceConfig?.aggregatedDataConfig?.skid?.name) {
                        keyParts.push(chartSourceConfig?.aggregatedDataConfig?.skid.name);
                    }
                    keyParts.push(yAxis.name);
                } else if (chartSourceConfig.chartSourceType === 1) {
                    // Lógica para teleObjetos
                    if (chartSourceConfig.teleObject?.id) {
                        keyParts.push(String(chartSourceConfig.teleObject.id));
                    }
                }
            }
        });


        const joinWithDash = (parts: string[]) => parts.filter(Boolean).join(' - ');


        return convertStringToNumber(joinWithDash(keyParts));

    };


    const handleDeleteChartConfig = (uniqueKey: number) => {

        if (chartConfigList != null) {
            const newChartConfig = chartConfigList?.filter(config => generateUniqueKey(config) !== uniqueKey);
            setChartConfigList(newChartConfig)
        }
    }

    const validateAggregation = (nameChartConfig: string, yAxis: ChartAxisType) => {

        if((yAxis.chartSourceConfig.aggregatedDataConfig?.dbColumn?.name.includes("performance_ratio")
            || yAxis.chartSourceConfig.aggregatedDataConfig?.dbColumn?.name.includes("temperature")
            || yAxis.chartSourceConfig.aggregatedDataConfig?.dbColumn?.name.includes("capacity_factor")
            || yAxis.chartSourceConfig.aggregatedDataConfig?.dbColumn?.name.includes("availability")) && yAxis.dataUnity === "sum"
            || ((yAxis.chartSourceConfig.aggregatedDataConfig?.dbColumn?.name.includes("expected_performance_ratio")
            || yAxis.chartSourceConfig.aggregatedDataConfig?.dbColumn?.name.includes("performance_ratio")) && yAxis.dataUnity === "avg")) {
            enqueueSnackbar(
                <div>
                    <h2 style={{fontWeight: 'bold', fontSize: 18}}> A análise não pode ser salva.</h2>
                <p style={{flexDirection: "column", fontSize: 18}}> Ajuste o tipo de agregação da medida {yAxis.chartSourceConfig.aggregatedDataConfig.dbColumn.displayName} no gráfico {nameChartConfig}.</p>
                </div>,
                {variant: 'error'});
            return false;
        }
        return true;
    }

    const handleSave = () => {
        const analysisEquipmentList = handleNewAnalysisConfig();

        if (!dataAnalysis && chartConfigList && analysisEquipmentList && analysisEquipmentList.length > 0 && nameAnalysisChart.length > 1) {
            let newChartConfigAxis: ChartConfigType[] = []
            let isValidate = true;
            chartConfigList.forEach(config => {
                config.yAxisList?.forEach(element => {
                    if(!validateAggregation(config.name,element)) {
                        isValidate = false;
                    }
                })
                const axisList = [...config.yAxisList, ...config.xAxisList]
                const newChartConfig: ChartConfigType = {
                    ...config,
                    powerStationFilterColumn: config.powerStationFilterColumn,
                    skidFilterColumn: config.skidFilterColumn,
                    equipmentFilterColumn: config.equipmentFilterColumn,
                    typeFilterColumn: config.typeFilterColumn,
                    measurementFilterColumn: config.measurementFilterColumn,
                    yAxisList: axisList,
                    xAxisList: []

                }
                newChartConfigAxis.push(newChartConfig)
            });
            if(isValidate) {
                const newAnalyses: AnalysisEntity = {
                    id: null,
                    name: nameAnalysisChart,
                    analysisEquipmentsList: analysisEquipmentList,
                    chartConfigList: newChartConfigAxis
                }
                // @ts-ignore
                mutation.mutate(newAnalyses)
            }

        } else if (dataAnalysis != null && chartConfigList != null && nameAnalysisChart.length > 1) {
            let newChartConfigAxis: ChartConfigType[] = []
            let isValidate = true;
            chartConfigList.forEach(config => {
                config.yAxisList?.forEach(element => {
                    if(!validateAggregation(config.name,element)) {
                        isValidate = false;
                    }
                })
                const axisList = [...config.yAxisList, ...config.xAxisList]
                const newChartConfig: ChartConfigType = {
                    ...config,
                    powerStationFilterColumn: config.powerStationFilterColumn,
                    skidFilterColumn: config.skidFilterColumn,
                    equipmentFilterColumn: config.equipmentFilterColumn,
                    typeFilterColumn: config.typeFilterColumn,
                    measurementFilterColumn: config.measurementFilterColumn,
                    yAxisList: axisList,
                    xAxisList: []

                }
                newChartConfigAxis.push(newChartConfig)
            });
            if(isValidate) {
                const newAnalyses: AnalysisEntity = {
                    ...dataAnalysis,
                    name: nameAnalysisChart,
                    analysisEquipmentsList: analysisEquipmentList,
                    chartConfigList: newChartConfigAxis
                }
                // @ts-ignore
                mutation.mutate(newAnalyses);
            }

        } else {
            enqueueSnackbar("A análise não pode ser salva. Preencha todas as informações necessárias.", {variant: 'warning'})
        }

    }

    const handleSelectedOption = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(event.target.value)
    }

    useEffect(() => {
        if(mutation.isSuccess){
            navigate(SystemRoutes.ANALYSIS);
            enqueueSnackbar("Análise salva com sucesso!", {variant: 'success'})
        }else if(mutation.isError){
            enqueueSnackbar("Erro ao salvar análise!", {variant: 'error'})
        }
    }, [mutation.status]);

    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);
    const account = useAppSelector(state => state.authentication.account);
    const isAuthorizedToDelete = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.DELETE_ANALYSIS
    ]);
    const isAuthorizedToSave = hasPermission(account?.authoritySet, [
        AUTHORITIES.SYSADMIN, AUTHORITIES.SAVE_ANALYSIS
    ]);
    return (
        <>
            <AnalysisHeaderMenu analysisId={params.analysisId} isConfig={true} onAnalysisPressSave={handleSave} disableSave={!isAuthorizedToSave} disableDelete={!isAuthorizedToDelete}/>

            <Grid container spacing={2} style={{paddingTop: 64}} key={`analise-${openSubMenu}`}>
                <Grid item xs={12} xl={4}>
                    <ACard
                        title={"Configurações"}
                    >
                        <Stack spacing={2}>
                            <Box textAlign={"end"}>
                                {(!selectedNavigationMenuByIds?.selectedPowerStationId || selectedNavigationMenuByIds?.selectedPowerStationId.length < 1)
                                && (!selectedNavigationMenuByIds?.selectedSkidId || selectedNavigationMenuByIds?.selectedSkidId.length < 1)
                                && (!selectedNavigationMenuByIds?.selectedEquipmentId || selectedNavigationMenuByIds?.selectedEquipmentId.length < 1) ? (
                                    <Tooltip TransitionComponent={Zoom}
                                             title="Selecione um ou mais itens no menu para habilitar modal de dados">
                                <span>
                                    <Button
                                        variant="contained"
                                        color={"primary"}
                                        endIcon={<AddCircle/>}
                                        onClick={handleOpenModal}
                                        disabled
                                    >
                                        Gráfico
                                    </Button>
                                </span>
                                    </Tooltip>
                                ) : (
                                    <Button
                                        variant="contained"
                                        color={"primary"}
                                        endIcon={<AddCircle/>}
                                        onClick={handleOpenModal}
                                    >
                                        Gráfico
                                    </Button>
                                )}
                            </Box>
                            {
                                <Container
                                    style={{
                                        display: 'flex',
                                        flexDirection: "column",
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                    <Grid container spacing={1}>
                                        <Grid item spacing={1} xs={12}>

                                            <Box justifyContent={"center"} sx={{padding: 1}}>
                                                {
                                                    nameAnalysisChart.length > 0 ?
                                                        <TextField
                                                            id="chart-name"
                                                            fullWidth
                                                            variant="standard"
                                                            placeholder={"Defina um nome para essa análise"}
                                                            value={nameAnalysisChart}
                                                            onChange={(value) => setNameAnalysisChart(value.target.value)}
                                                        />
                                                        :
                                                        <TextField
                                                            error
                                                            id="chart-name"
                                                            fullWidth
                                                            variant="standard"
                                                            placeholder={"Defina um nome para essa análise"}
                                                            value={nameAnalysisChart}
                                                            onChange={(value) => setNameAnalysisChart(value.target.value)}
                                                            helperText="Campo obrigatório."
                                                        />
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <br/>
                                    <>
                                        {
                                            chartConfigList != null && chartConfigList.length > 0 &&
                                            chartConfigList.map((chartConfig) => {
                                                const key = generateUniqueKey(chartConfig);
                                                return (
                                                    <Grid container key={key}>
                                                        <ChartConfig
                                                            key={key}
                                                            options={{
                                                                index: key,
                                                                chartConfig: chartConfig,
                                                                filters: {
                                                                    powerStationName: chartConfig.powerStationFilterColumn,
                                                                    skidName: chartConfig.skidFilterColumn,
                                                                    equipmentName: chartConfig.equipmentFilterColumn,
                                                                    type: chartConfig.typeFilterColumn,
                                                                    measure: chartConfig.measurementFilterColumn
                                                                }
                                                            }}
                                                            onDeleteChartConfig={handleDeleteChartConfig}
                                                            onPreview={handlePreview}
                                                            onEdit={onEdit}
                                                        />
                                                    </Grid>

                                                )
                                            })

                                        }
                                    </>
                                </Container>

                            }

                        </Stack>
                    </ACard>
                    <Modal
                        open={open}
                        onClose={handleCloseModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}
                        >
                            <ACard
                                title="Agrupamento de equipamentos"
                                headerActions={
                                    <FloatMenuButton
                                        icon={<MoreVert/>}
                                        tooltip={"Floating Menu"}
                                    >
                                        <FloatMenuItem
                                            icon={<FontAwesomeIcon icon={faGear} fontSize={20}/>}
                                            text="Settings"
                                            disable={true}
                                            link={"/"}
                                        />
                                        <FloatMenuItem
                                            icon={<FontAwesomeIcon icon={faExpand} fontSize={20}/>}
                                            text="Expand"
                                            disable={true}
                                        />
                                        <FloatMenuItem
                                            icon={<FontAwesomeIcon icon={faPrint} fontSize={20}/>}
                                            text="Print"
                                            disable={true}
                                        />
                                    </FloatMenuButton>
                                }
                                footerActions={
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            marginTop: 2,
                                            marginRight: 1,
                                        }}
                                    >
                                        <RadioGroup
                                            aria-labelledby="chart-type"
                                            row
                                            name="chart-type"
                                            onChange={handleSelectedOption}
                                            value={selectedOption}
                                        >
                                            <FormControlLabel value="individual" control={<Radio/>} label="Individual"/>
                                            <FormControlLabel value="unificado" control={<Radio/>} label="Unificado"/>
                                        </RadioGroup>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            endIcon={<AddCircle/>}
                                            style={{marginLeft: 2}}
                                            disabled={selectedRows.length === 0 || (selectedOption === "unificado" && !isValidateSelectedByUnity)}
                                            onClick={isEditChartConfig ? handleSelectEditButtonClick : handleSelectButtonClick}
                                        >
                                            Selecionar
                                        </Button>
                                    </Box>
                                }
                            >
                                <ADataGrid
                                    hideFilters={false}
                                    hideSelection={false}
                                    showFilterOptions={false}
                                    size={"small"}
                                    columns={columns}
                                    rows={rows}
                                    page={pagedSearchParams.page}
                                    loading={false}
                                    maxRowSelected={MAX_ROWS}
                                    onMaxRowSelected={handleMaxSelected}
                                    totalOfRecords={totalOfRecords || 0}
                                    rowsPerPage={pagedSearchParams.size}
                                    rowsPerPageOptionsDefault={sizePerPage}
                                    onFiltersChange={handleOnFiltersChange}
                                    onRowSelect={handleOnRowSelect}
                                    actionColumnWidth={100}
                                />
                               
                            </ACard>
                        </Box>
                    </Modal>
                </Grid>

                <Grid item xs={12} xl={8}>
                    <Grid container spacing={2}>
                        {chartConfigList != null && entities != null ? (
                            <ChartAnalysis chartConfig={chartConfigList} />
                        ) : (
                            isLoading && loading && (
                                <Skeleton animation="wave" height={400} variant="rounded" width="100%" />
                            )
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default function AnalysisEditPage() {
    const queryClient = new QueryClient();
    return (
        <QueryClientProvider client={queryClient}>
            <AnalysisEdit/>
        </QueryClientProvider>
    )

}