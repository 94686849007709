import { FunctionComponent, useEffect } from "react";

import {ACard, ProgressCard, Skeleton} from "@atiautomacao/ati-ui-library";
import {Box, Grid} from "@mui/material";
import {getEntity, reset} from "./TotalizerPlantReducer";
import { RootState } from "../../../../Config/Store";
import { useAppDispatch, useAppSelector } from "../../../../Config/Hooks";
import {Container} from "../../../../Shared/Components/AlarmTotalizers/AlarmTotalizers.theme";
import useInterval from "../../../../Shared/Hooks/useInterval";

const TotalizerPlant: FunctionComponent<{powerStationId: any}> = ({powerStationId}) => {

    const totalizers = useAppSelector((state: RootState) => state.totalizerPlant.totalizers);
    const isLoading = useAppSelector((state : RootState) => state.totalizerPlant.isLoading);
    const dispatch = useAppDispatch();
    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);

    useEffect(() => {
        dispatch(reset())
        dispatch(getEntity({powerStationId: powerStationId}));
    }, [powerStationId]);

    useInterval(() => {
        dispatch(getEntity({powerStationId: powerStationId}));
    }, 60000); //5 minutes

    const formatNumber = (value: number, decimals: number, unit: string): string => {
        if(value == null){
            return "N/A";
        }
        if(value < 0){
            return "Parâmetro Inválido"
        }
        return value.toFixed(decimals) + " " + unit;
    };


    const formatPercentage = (value: number): string => {
        if(value == null){
            return "N/A"
        }
        return (value * 100).toFixed(2) + "%";
    };

    const formattedPerformanceRatio = formatPercentage(totalizers?.performanceRatio);
    const formattedAvailability = formatPercentage(totalizers?.availability);
    const formattedActivePower = formatNumber(totalizers?.activePower, 2, "kW");
    const formattedIrradiance = formatNumber((totalizers?.irradiance / 1000), 2, "kWh/m²");
    const formattedCapacityFactor = formatPercentage(totalizers?.capacityFactor);
    const formattedYield = formatNumber((totalizers?.yieldField), 2, "kWh/kWp");


    if (isLoading) {
        return (
            <Box style={{paddingBottom: 10}}>
                <ACard
                    title={"Performance"}
                >
                    <Container data-testid="loading-skeleton">
                            <Grid container spacing={2} columnSpacing={-2} columns={{sm: 12, md: 12, lg: 12,xl: 12}}>
                                {Array.from({ length: 6 }).map((_, index) => (
                                    <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={6} sm={4} md={4} lg={2} xl={2} key={index.valueOf()}>
                                        <Skeleton key={index.valueOf()} animation="wave" height={100} variant="rectangular" width={220} />
                                    </Grid>
                                ))}
                            </Grid>
                    </Container>
                </ACard>
            </Box>
        );
    }

    return (
        <Box style={{paddingBottom: 10}}>
            <ACard
                title={"Performance"}
                // headerActions={
                //     <FloatMenuButton
                //         icon={<MoreVertIcon/>}
                //         tooltip={"Floating Menu"}
                //     >
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faGear} fontSize={20}/>}
                //             text="Settings"
                //             disable={true}
                //             link={"/"}
                //         />
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faExpand} fontSize={20}/>}
                //             text="Expand"
                //             disable={true}
                //         />
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faPrint} fontSize={20}/>}
                //             text="Print"
                //             disable={true}
                //         />
                //     </FloatMenuButton>
                // }
            >
                <Grid container spacing={2} columnSpacing={-2} columns={{sm: 12, md: 12, lg: 12,xl: 12}}>
                    <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={6} sm={4} md={4} lg={openSubMenu ? 4 : 2} xl={2}>
                        <ProgressCard
                            value={formattedIrradiance || "-"}
                            fontSize={16}
                            valueFontSize={15}
                            showProgressBar={false}
                            label="Irradiação"
                            background="#F9F9F9"
                            textColor="#000000"
                            width={240}
                            boldLabel={true}
                        />
                    </Grid>
                    <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={6} sm={4} md={4} lg={openSubMenu ? 4 : 2} xl={2}>
                        <ProgressCard
                            value={formattedActivePower || "-"}
                            fontSize={16}
                            valueFontSize={15}
                            showProgressBar={false}
                            label="Potência"
                            background="#F9F9F9"
                            textColor="#000000"
                            width={240}
                            boldLabel={true}
                        />
                    </Grid>
                    <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={6} sm={4} md={4} lg={openSubMenu ? 4 : 2} xl={2}>
                        <ProgressCard
                            value={formattedCapacityFactor || "-"}
                            fontSize={16}
                            valueFontSize={15}
                            showProgressBar={false}
                            label="Fator de Capacidade"
                            background="#F9F9F9"
                            textColor="#000000"
                            width={240}
                            boldLabel={true}
                        />
                    </Grid>
                    <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={6} sm={4} md={4} lg={openSubMenu ? 4 : 2} xl={2}>
                        <ProgressCard
                            value={formattedPerformanceRatio || "-"}
                            fontSize={16}
                            valueFontSize={15}
                            showProgressBar={false}
                            label="Performance Ratio"
                            background="#F9F9F9"
                            textColor="#000000"
                            width={240}
                            boldLabel={true}
                        />
                    </Grid>
                    <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={6} sm={4} md={4} lg={openSubMenu ? 4 : 2} xl={2}>
                        <ProgressCard
                            value={formattedAvailability || "-"}
                            fontSize={16}
                            valueFontSize={15}
                            showProgressBar={false}
                            label="Disponibilidade"
                            background="#F9F9F9"
                            textColor="#000000"
                            width={240}
                            boldLabel={true}
                        />
                    </Grid>

                    <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={6} sm={4} md={4} lg={openSubMenu ? 4 : 2} xl={2}>
                        <ProgressCard
                            value={formattedYield || "-"}
                            fontSize={16}
                            valueFontSize={15}
                            showProgressBar={false}
                            label="Produtividade"
                            background="#F9F9F9"
                            textColor="#000000"
                            width={240}
                            boldLabel={true}
                        />
                    </Grid>
                </Grid>
            </ACard>
        </Box>
    )

}

export default TotalizerPlant;