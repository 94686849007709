import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejected} from '@reduxjs/toolkit';
import { RootState } from '../../../../Config/Store';
import axios from 'axios';
import { serializeAxiosError } from "../../../../Config/Reducers/ReducerUtils";
import { ChartState} from "../../../../Config/Types";

export interface WeatherData {
    id: number;
    name: string;
    value: number;
}

export class WeatherStation {

    id: number;
    values: Array<WeatherData>;

    constructor( id: number, values: Array<WeatherData>) {
        this.id = id;
        this.values = values;
    }
}

const initialState:  ChartState= {
    loading: false,
    errorMessage: null,

    entities: new Array<WeatherStation>(),
    entity: WeatherStation,
    updating: false,
    updateSuccess: false
};

const apiUrl = 'api/equipment/meteorological-station/power-station';


export const getEntities = createAsyncThunk<any, any>(
    'weather Plant/fetch_entity_list',
    async (id) => {
        return axios.get<WeatherStation>(`${apiUrl}?powerStationId=${id}`);
    },
    { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk<any, any, { rejectValue: any }>(
    'weatherPlant/fetch_entity',
    async (id) => {
        return axios.get<WeatherStation>(`${apiUrl}?powerStationId=${id}`);
    },
    { serializeError: serializeAxiosError }
);

// Slices
const WeatherStationPlantReducer = createSlice({
    name: 'WeatherStationPlantReducer',
    initialState,
    reducers: {
        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
        clear: (state) => {
            state.entity = {
                id: 0,
                values: null
            };
            state.entities = []
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(getEntity.fulfilled, (state, action) => {
                state.loading = false;
                state.entity = action.payload.data.data;
            })
            .addMatcher(isFulfilled(getEntities, getEntity), (state, action) => {
                return {
                    ...state,
                    loading: false,
                    entities: action.payload.data.data,
                }
            })
            .addMatcher(isPending(getEntities, getEntity), state => {
                state.errorMessage = null;
                state.loading = true;
            })
            .addMatcher(isRejected(getEntity), state => {
                state.entity = {
                    id: 0,
                    values: null
                };
                state.loading = false;
            })
    },
})

export const { reset, clear } = WeatherStationPlantReducer.actions;
export const selectWeatherStationPlant = (state: RootState) => state.weatherStationPlant;

// Reducer
export default WeatherStationPlantReducer.reducer;